<script setup>
import { ref, onMounted } from 'vue'
import mapboxgl from 'mapbox-gl'
import { useMapStore } from '@/stores'
import { isLngLatValid } from '@/utils'
import { defaultView, mapboxToken, mapStyles } from '@/configs/map'

import MapBaselayers from './components/map-baselayers.vue'
import MapLocation from './components/map-location/map-location.vue'
import MapSettings from './components/map-settings.vue'
import MapLegend from './components/map-legend.vue'

import 'mapbox-gl/dist/mapbox-gl.css'

const props = defineProps({
  module: {
    type: String,
    default: 'data-map'
  },
  center: {
    type: Array,
    default: () => defaultView.center
  },
  zoom: {
    type: Number,
    default: 13
  },
  controls: {
    type: Boolean,
    default: true
  },
  buffer: {
    type: Boolean,
    default: false
  },
  baselayers: {
    type: Boolean,
    default: true
  },
  location: {
    type: Boolean,
    default: true
  },
  settings: {
    type: Boolean,
    default: false
  },
  legend: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['init'])

const mapStore = useMapStore()

const mapgl = ref(null)
const initMap = () => {
  try {
    const { module, center, zoom, controls, buffer } = props
    const style = mapStyles[mapStore.getActiveBaselayerName(module)]

    mapboxgl.accessToken = mapboxToken

    const mapCenter = isLngLatValid(center) ? center : defaultView.center

    mapgl.value = new mapboxgl.Map({
      container: module,
      preserveDrawingBuffer: buffer,
      refreshExpiredTiles: false,
      center: mapCenter,
      zoom,
      style
    })

    if (controls) {
      mapgl.value.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false
        })
      )
    }

    mapgl.value.on('load', () => {
      emits('init', mapgl.value)
    })
  } catch (error) {
    console.error('An unexpected error occurred in map init: ', error)
  }
}

onMounted(() => {
  initMap()
})
</script>

<template>
  <div class="s-map">
    <div :id="module" />

    <template v-if="mapgl">
      <map-baselayers v-if="baselayers" :mapgl="mapgl" :module="module" />
      <map-location v-if="location" :mapgl="mapgl" />
      <map-settings v-if="settings" />
      <map-legend v-if="legend" />
    </template>
  </div>
</template>

<style lang="scss">
.s-map {
  width: 100%;
  height: 100%;

  .mapboxgl-map {
    height: 100%;
    width: 100%;
  }

  .mapboxgl-canvas {
    outline: none;
  }

  .mapboxgl-ctrl-group {
    border-radius: 8px;

    .mapboxgl-ctrl-zoom-in {
      border: 1px solid var(--main-bg) !important;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    .mapboxgl-ctrl-zoom-out {
      border: 1px solid var(--main-bg) !important;
      border-top: none !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .mapboxgl-ctrl-top-right {
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    .mapboxgl-ctrl {
      margin: 0;
    }

    .mapboxgl-ctrl-group {
      background-color: var(--bg);
      box-shadow: none;

      button {
        width: 40px;
        height: 40px;
        border: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;

        &:hover {
          background-color: var(--bg--hover-dense) !important;
        }

        &:active {
          background-color: var(--bg--active-dense) !important;
        }

        .mapboxgl-ctrl-icon {
          background-image: none !important;
        }
      }
    }
  }
}

.dark-theme {
  .s-map .mapboxgl-ctrl-group {
    .mapboxgl-ctrl-zoom-in {
      background-image: url('@/assets/icons/plus_dark.svg');
    }

    .mapboxgl-ctrl-zoom-out {
      background-image: url('@/assets/icons/minus_dark.svg');
    }
  }
}

.light-theme {
  .s-map .mapboxgl-ctrl-group {
    .mapboxgl-ctrl-zoom-in {
      background-image: url('@/assets/icons/plus_light.svg');
    }

    .mapboxgl-ctrl-zoom-out {
      background-image: url('@/assets/icons/minus_light.svg');
    }
  }
}
</style>
