<script setup>
import { computed } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { useExcavationStore } from '@/stores'
import { hasDataPermissions } from '@/utils'
import SampleItem from './components/sample-item.vue'

const props = defineProps({
  excavation: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['edit-handler', 'add-handler'])

const excavationStore = useExcavationStore()

const disabled = computed(() => {
  const geologist = props.excavation?.geologist

  return !hasDataPermissions(geologist)
})

const samplesList = computed(() => {
  const clone = cloneDeep(excavationStore.samplesList)

  return (
    clone?.sort((a, b) => {
      if (a.selection_from_d > b.selection_from_d) return 1
      if (a.selection_from_d < b.selection_from_d) return -1
      return 0
    }) || []
  )
})

const clickHandler = (sample) => {
  if (disabled.value) return
  emits('edit-handler', { model: 'samples', value: sample })
}
</script>

<template>
  <div class="samples-list">
    <div v-if="!disabled" class="samples-list__btn-wrapper">
      <s-button type="success-plain" icon="fa-plus" @click="emits('add-handler', 'samples')">
        Добавить пробу
      </s-button>
    </div>
    <div class="samples-list-content">
      <sample-item
        v-for="(sample, i) in samplesList"
        :key="sample.id"
        :no="i + 1"
        :sample="sample"
        @click-handler="clickHandler"
      />
    </div>
  </div>
</template>

<style lang="scss">
.samples-list {
  display: grid;
  grid-gap: 1rem;

  &__btn-wrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--card-bg);

    & .s-button {
      width: 100%;
    }
  }

  &-content {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
