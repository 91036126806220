import { defineStore } from 'pinia'

export const useReconStore = defineStore('recon', {
  state: () => {
    return {
      active: null,
      updateReconCommonImages: false,
      updateReconJournalImages: false,
      imageLoading: [],
      reconCommonImages: [],
      reconJournalImages: []
    }
  },
  actions: {
    setField(field, value) {
      if (field.includes('.')) {
        const subField = field.split('.')

        this[subField[0]][subField[1]] = value
      } else {
        this[field] = value
      }
    },
    reconAddItem(field, value) {
      this[field].push(value)
    },
    reconUpdateItem(field, value) {
      const index = this[field]?.findIndex((e) => e.id === value.id)

      if (index > -1) {
        this[field][index] = value
      }
    }
  }
})
