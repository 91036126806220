export const frozenLargeScaleSteps = {
  main: [
    {
      id: 'filler',
      title: 'Укажите информацию о заполнителе',
      type: 'group-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Заполнитель',
        description:
          'В крупнообломочных грунтах необходимо указывать вид и процентное содержание заполнителя. При наличии в крупнообломочных грунтах песчаного заполнителя более 40 % или глинистого заполнителя более 30 % общей массы воздушно-сухого грунта в наименование крупнообломочного грунта включают наименование вида заполнителя и указывают характеристики его состояния (влажность, плотность, показатель текучести). Вид заполнителя устанавливают после удаления из крупнообломочного грунта частиц крупнее 2 мм.',
        source:
          'ГОСТ 25100. Грунты. Классификация. НИИОСП им. Н.М. Герсеванова» — АО «НИЦ «Строительство», 2020'
      },
      groups: [
        {
          id: 'filler_type',
          value: 'filler_type',
          title: 'Вид грунта',
          type: 'select',
          service: 'fillers_types'
        },
        {
          id: 'filler_amount',
          value: 'filler_amount',
          required: 'filler_type',
          title: 'Количество',
          type: 'select',
          service: 'fillers_amounts'
        }
      ],
      commentField: 'filler_comments',
      commentTitle: 'Комментарий к заполнителю'
    },
    {
      id: 'state_pfrost',
      value: 'state_pfrost',
      title: 'Укажите состояние грунта',
      clearable: true,
      type: 'state-pfrost',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к состоянию / консистенции'
    },
    {
      id: 'color',
      value: 'color',
      title: 'Укажите цвет грунта',
      type: 'color-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Цвет грунта',
        description:
          'Цвет грунта описывают в естественном состоянии, при полном дневном освещении. Допускается описание цвета при искусственном освещении с использованием ламп дневного света или при любом освещении с применением сравнительного образца или эталона, цветовых шкал. Для объективного описания цветов рекомендуется пользоваться цветовыми шкалами (палетками), например выполненными по системе Манселла, или другими системами, специализированными для определения цвета грунтов (например, цветовая таблица грунтов S133N). Цвет скального грунта описывают во влажном (смоченном) состоянии. Если необходимо, цве­товые различия могут быть уточнены использованием таких терминов, как «пестрый», «пятнистый», «испещренный», «крапчатый», «полосчатый».',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'text_pfrost',
      value: 'text_pfrost',
      title: 'Укажите криогенную текстуру грунта',
      type: 'text-pfrost',
      service: 'texts_pfrost',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к криогенной текстуре',
      wikiTipData: {
        color: 'placeholder',
        title: 'Криогенная текстура',
        description:
          'Совокупность признаков сложения мерзлого грунта, обусловленная ориентировкой, относительным расположением и распределением включений текстурообразующего льда и минеральных агрегатов',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 3.4.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      },
      additionalTrigger: true,
      createTrigger: true
    }
  ],
  additional: [
    {
      id: 'debris',
      type: 'group-select',
      groups: [
        {
          title: 'Укажите степень окатанности обломков',
          id: 'debris_round',
          value: 'debris_round',
          type: 'select',
          service: 'debris_rounds'
        },
        {
          title: 'Укажите состав обломков',
          id: 'debris_composition',
          value: 'debris_composition',
          type: 'select',
          search: true,
          service: 'soil_types',
          filterValue: '20000;29999'
        }
      ],
      commentField: 'debris_comments',
      commentTitle: 'Комментарий к составу обломков'
    },
    {
      id: 'strength',
      value: 'strength',
      title: 'Укажите прочность крупных обломков',
      type: 'select',
      service: 'strengths',
      commentField: 'strength_comments',
      commentTitle: 'Комментарий к прочности обломков'
    },
    {
      id: 'inclusions',
      value: 'inclusions',
      title: 'Включения',
      type: 'inclusions'
    },
    {
      id: 'interlayers',
      value: 'interlayers',
      title: 'Прослои',
      type: 'interlayers',
      frozen: true
    },
    {
      id: 'additional',
      value: 'additional',
      title: 'Укажите дополнительную информацию',
      type: 'additional',
      createTrigger: true
    }
  ]
}
