import { useMapStore, usePrintStore } from '@/stores'
import {
  getGeojson,
  flyToFeatures,
  getDataLayerConfig,
  getDataPointNameByType
} from '@/utils'

export class ExcavationsController {
  constructor(mapgl) {
    this.mapgl = mapgl
    this.printStore = usePrintStore()
    this.mapStore = useMapStore()

    this.layerId = 'dashboard-excavations'
  }

  showDataPoints() {
    const { dashboard } = this.printStore
    if (!dashboard) return

    const activeMapTheme = this.mapStore.getActiveBaselayerName('dashboard-print-map')

    const { excavationsList } = dashboard

    if (!excavationsList) return
    const data = getGeojson(
      excavationsList.map(data => ({
        ...data,
        x: data.lat || data.lat_plan,
        y: data.lon || data.lon_plan,
        type: getDataPointNameByType(data.type)
      }))
    )

    this.mapgl.addSource(this.layerId, {
      type: 'geojson',
      data
    })

    this.mapgl.addLayer({
      id: `${this.layerId}-icon`,
      source: this.layerId,
      ...getDataLayerConfig(activeMapTheme, 'icon')
    })
    this.mapgl.addLayer({
      id: `${this.layerId}-label`,
      source: this.layerId,
      ...getDataLayerConfig(activeMapTheme, 'label')
    })

    flyToFeatures(this.mapgl, data)
  }
}
