<script setup>
import { watch, ref, onMounted } from 'vue'

const props = defineProps({
  autocompleteTags: {
    type: Array,
    required: false,
    default: () => []
  }
})

const emits = defineEmits(['click'])

const autocompleteTagsRef = ref(null)
const tagsWrapperRef = ref(null)

watch(
  () => props.autocompleteTags,
  () => {
    checkScroll()
  }
)

const checkScroll = () => {
  const element = tagsWrapperRef.value
  const autocompleteTags = autocompleteTagsRef.value

  if (element.scrollWidth > element.clientWidth) {
    autocompleteTags.classList.add('has-scroll')
  } else {
    autocompleteTags.classList.remove('has-scroll')
  }

  if (
    element.scrollWidth - element.scrollLeft === element.clientWidth &&
    element.scrollWidth > element.clientWidth
  ) {
    autocompleteTags.classList.remove('has-scroll')
  }
}

onMounted(() => {
  const tagsWrapper = tagsWrapperRef.value
  tagsWrapper.addEventListener('scroll', checkScroll)
})
</script>

<template>
  <div ref="autocompleteTagsRef" class="autocomplete-tags has-scroll">
    <div ref="tagsWrapperRef" class="autocomplete-tags__wrapper">
      <transition-group name="autocomplete-tags">
        <s-tag
          v-for="tag in autocompleteTags"
          :key="tag"
          class="autocomplete-tags__item"
          :value="tag"
          @click="emits('click', tag)"
        />
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss">
.has-scroll {
  position: relative;
  transition: 0.4s;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    background: linear-gradient(270deg, #262c35 0%, rgba(38, 44, 53, 0) 100%);
    width: 16px;
    height: 100%;
    transition: 0.4s;
  }
}
.autocomplete-tags {
  position: relative;
  min-height: 55px;

  &__wrapper {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    transition: all 0.3s;
    margin: 8px 8px 0 0;
    cursor: pointer;
  }

  &-leave-to {
    opacity: 0;
    transform: translateY(-16px);
  }

  &-enter {
    opacity: 0;
    transform: translateY(-16px);
  }
}
</style>
