<script setup>
import { useMainStore } from '@/stores'

import DesktopBar from './components/desktop-bar.vue'
import SoilboxInfoModal from '@/components/soilbox-info-modal.vue'

const mainStore = useMainStore()
</script>

<template>
  <div class="app" v-loading="mainStore.loading">
    <desktop-bar />

    <router-view />
    <soilbox-info-modal />
  </div>
</template>

<style lang="scss">
.app {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
