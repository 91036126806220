<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores'
import { excavationStatusesColors, excavationStatuses } from '@/configs/info'
import { capitalizeFirstLetter, hasDataPermissions } from '@/utils'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  type: {
    type: Number,
    validator: (value) => [101, 201].includes(value),
    default: 101
  }
})

const emits = defineEmits(['change'])

const serivicesStore = useServicesStore()

const disabled = computed(() => {
  const geologist = props.item?.geologist

  return !hasDataPermissions(geologist)
})

const statuses = computed(() => {
  if (props.type === 101) {
    return (
      serivicesStore?.excav?.statuses?.map((e) => {
        e.title = capitalizeFirstLetter(e.title)
        return e
      }) || null
    )
  }

  return (
    serivicesStore?.recon?.statuses?.map((e) => {
      e.title = capitalizeFirstLetter(e.title)
      return e
    }) || null
  )
})

const statusName = computed(() => {
  return excavationStatuses[props.item.status]
})

const change = async (e) => {
  emits('change', e, 'status')
}
</script>

<template>
  <s-button v-if="disabled" disabled>
    {{ statusName }}
  </s-button>
  <s-select
    :class="excavationStatusesColors[item.status]"
    v-else-if="statuses"
    :value="item.status"
    class="status-switcher"
    :disabled="disabled"
    :options="statuses"
    @change="change"
  >
  </s-select>
</template>

<style lang="scss">
.status-switcher {
  width: 124px !important;

  &.primary {
    & .vs__selected {
      color: var(--primary);
    }
  }

  &.orange {
    & .vs__selected {
      color: var(--warning);
    }
  }

  &.success {
    & .vs__selected {
      color: var(--success);
    }
  }
}
</style>
