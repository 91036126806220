<script setup>
import { computed } from 'vue'

const props = defineProps({
  width: {
    type: Number,
    default: 40
  },
  stretched: {
    type: Boolean,
    default: false
  },
  center: {
    type: Boolean,
    default: false
  }
})

const styleSettings = computed(() => {
  const { stretched, center, width } = props

  if (stretched) {
    return {
      flex: 1,
      justifyContent: center ? 'center' : 'flex-start'
    }
  }

  return {
    width: `${width}px`,
    justifyContent: center ? 'center' : 'flex-start'
  }
})
</script>

<template>
  <div class="d-item-cell" :style="styleSettings">
    <slot />
  </div>
</template>

<style lang="scss">
.d-item-cell {
  display: flex;
  flex-shrink: 0;
}
</style>
