<script setup>
import SAttachments from '@/components/s-attachments/s-attachments.vue'

defineProps({
  images: {
    type: Array,
    required: true
  },
  files: {
    type: Array,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  activeId: {
    type: [Number, String],
    default: null
  },
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: 'Фотографии'
  }
})

const emits = defineEmits(['change'])

const additionalText = 'Рекомендуется загружать фотографии в соотношении 4:3'

const changeActiveCollapse = (id) => {
  emits('change', id)
}
</script>

<template>
  <s-collapse-item @change="changeActiveCollapse" :active="activeId" :id="id" :title="title">
    <template v-slot:header-stats>
      <s-text> {{ images.length }}</s-text>
    </template>
    <div v-loading="loading">
      <s-attachments
        v-if="!loading"
        no-title
        label="Загрузка фото"
        :source="files"
        :images="images"
        multiple
        :additionalText="additionalText"
      />
    </div>
  </s-collapse-item>
</template>
