import { defineStore } from 'pinia'

export const useServicesStore = defineStore('services', {
  state: () => {
    return {
      // OBJECT items
      object_users: null,
      object_bore_machines: null,
      object_bore_masters: null,

      // FULL items
      users: null,
      bore_machines: null,
      bore_masters: null,
      clients: null,
      contractors: null,
      companies: null,
      positions: null,

      // NEW DICTS
      excav: null,
      object: null,
      sample: null,
      soil: null,
      wiki: null,
      recon: null,
      point: null,
      processes: null
    }
  },
  actions: {
    setService([field, value]) {
      this[field] = value
    }
  }
})
