import axios from 'axios'
import { errorParser, parseFieldTypes } from '@/utils'
import { BASE_URL } from '@/configs/urls'
import { useAuthStore } from '@/stores'

export function useRequests() {
  const authStore = useAuthStore()
  const getUrn = () => {
    return authStore.isAdmin ? 'api/admin' : 'api'
  }

  const makeRequest = (method, url, data = null) => {
    return new Promise((resolve, reject) => {
      const urn = getUrn()
      if (data) parseFieldTypes(data)

      axios[method](`${BASE_URL}/${urn}/${url}`, data)
        .then((response) => resolve(response.data))
        .catch((error) => {
          errorParser(reject, error)
          throw error
        })
    })
  }

  const getRequest = (url) => makeRequest('get', url)
  const postRequest = (url, data) => makeRequest('post', url, data)
  const putRequest = (url, data) => makeRequest('put', url, data)
  const patchRequest = (url, data) => makeRequest('patch', url, data)
  const deleteRequest = (url) => makeRequest('delete', url)

  return {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest
  }
}
