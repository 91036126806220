import { onMounted } from 'vue'
import { useMq } from 'vue3-mq'
import router from '@/router'

export function useOnlyMobileHandler () {
  const mq = useMq()

  onMounted(() => {
    if (mq.current === 'lg') {
      router.push('/app/data/objects/list')
    }
  })
}