<script setup>
import { ref } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isFrozenDisabled: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['change-state'])

const initItem = ref(props.item)
</script>

<template>
  <div :class="['soil-state-item', { active: initItem.value }]">
    <s-checkbox
      :disabled="isFrozenDisabled"
      :checked="initItem.value"
      :label="initItem.title"
      @change="emits('change-state', initItem.id)"
    />
  </div>
</template>

<style lang="scss">
.soil-state-item {
  display: grid;
  align-items: center;
  padding: 10px 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  cursor: pointer;

  @include phones {
    padding: 10px 0.5rem;
  }

  &.active {
    border: 1px solid var(--primary);
    background-color: var(--primary--active);
  }
}
</style>
