<script setup>
import { computed, ref } from 'vue'
import { orderBy } from 'natural-orderby'
import { useEditorStore, useObjectsStore } from '@/stores'

const props = defineProps({
  filterText: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['delete'])

const editorStore = useEditorStore()
const objectsStore = useObjectsStore()

const editorPoints = computed(() => {
  return objectsStore.excavationsList.map((point) => {
    point.lat_plan = Number(point.lat_plan)
    point.lon_plan = Number(point.lon_plan)

    return point
  })
})

const recentPoints = computed(() => {
  return editorStore.recentChangesPoints.map((point) => {
    point.lat_plan = Number(point.lat_plan)
    point.lon_plan = Number(point.lon_plan)
    return point
  })
})

const editorList = computed(() => {
  return [
    {
      id: 'recent',
      list: filteredRecentList.value
    },
    {
      id: 'editorPoints',
      list: filteredEditorList.value
    }
  ]
})

const dropdownActions = ref([
  {
    id: 1,
    icon: 'trash-can',
    type: 'error',
    label: 'Удалить скважину'
  }
])
const dropdownClickHandle = (dropItemId, point, listID) => {
  switch (dropItemId) {
    case 1: {
      emits('delete', point, listID)
    }
  }
}

const filteredRecentList = computed(() => {
  const filteredList = recentPoints.value?.filter((item) => {
    return String(item.title)?.toUpperCase().includes(props.filterText?.toUpperCase())
  })

  return orderBy(filteredList, [(v) => v['title']], ['asc'])
})

const filteredEditorList = computed(() => {
  const filteredList = editorPoints.value?.filter((item) => {
    return String(item.title)?.toUpperCase().includes(props.filterText?.toUpperCase())
  })

  return orderBy(filteredList, [(v) => v['title']], ['asc'])
})

const getTitle = (point) => {
  if (point.point_id) {
    return point.title
  }

  if (point.oldTitle) {
    return point.oldTitle
  }

  return point.title
}
</script>

<template>
  <div class="map-editor-list">
    <div :class="list.id" v-for="(list, index) in editorList" :key="index">
      <s-text v-if="list.id === 'recent' && list.list.length">Несохраненные данные </s-text>
      <div v-for="point in list.list" :key="point.point_id">
        <div class="map-editor-list__item" v-if="point.title">
          <div class="map-editor-list__item-info">
            <div class="map-editor-list__item-title">
              <s-text semibold>{{ getTitle(point, list) }}</s-text>
              <div
                class="map-editor-list__item-rename"
                v-if="point.oldTitle && point.oldTitle !== point.title"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 
                    224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                  />
                </svg>
                <s-text semibold>{{ point.title }}</s-text>
              </div>
            </div>
            <s-text small>{{ point.lat_plan.toFixed(7) }}, {{ point.lon_plan.toFixed(7) }}</s-text>
          </div>
          <div class="map-editor-list__item-actions">
            <s-text small class="map-editor-list__item-depth">{{ point.h_plan }}м</s-text>
            <s-dropdown list :max-width="256">
              <s-icon name="fa-ellipsis-vertical" />
              <template #dropdown>
                <s-dropdown-item
                  v-for="item in dropdownActions"
                  :id="item.id"
                  :key="item.id"
                  :type="item.type"
                  :icon="item.icon"
                  :label="item.label"
                  :divided="item.divided"
                  :disabled="item.disabled"
                  :close-by-click="item.closeByClick"
                  @click="dropdownClickHandle(item.id, point, list.id)"
                />
              </template>
            </s-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.map-editor-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  & .editorPoints {
    padding-top: 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;

    &:empty {
      display: none;
    }
    & div:empty {
      display: none;
    }

    &:last-child {
      border-top: 1px solid var(--bg);
    }
  }

  & .recent {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;

    &:empty {
      display: none;
    }
    & div:empty {
      display: none;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: auto 1fr;

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      & .s-dropdown__trigger {
        padding: 8px;
        cursor: pointer;
      }
    }

    &-rename {
      display: flex;
      align-items: center;
      gap: 12px;

      & svg {
        width: 16px;
        height: 16px;
        color: var(--caption);
        fill: var(--caption);
      }
    }

    &-title {
      display: flex;
      gap: 12px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-depth {
      display: inline;
      border-radius: 8px;
      padding: 5px 16px;
      background: var(--bg);
    }
  }
}
</style>
