export const getItemWidth = (item) => {
  let width

  switch (item.width) {
    case 'half':
      width = 1
      break
    default:
      width = 2
  }

  const value = `span ${width}`

  return `grid-column: ${value};`
}
