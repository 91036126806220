<script setup>
import { useMq } from 'vue3-mq'
import { useRouter } from 'vue-router'
import { useSyncStore, useAuthStore } from '@/stores'

const syncStore = useSyncStore()
const authStore = useAuthStore()
const router = useRouter()
const mq = useMq()

const closeModal = () => {
  syncStore.setField('isShowUnsyncedModal', false)
}

const logOut = () => {
  authStore.authLogout()
  router.push('/login')
  closeModal()
}

const alertText = 'Настоятельно рекомендуется в первую очередь синхронизировать данные'
</script>

<template>
  <s-modal
    :marginY="mq.current === 'lg' ? 48 : 0"
    no-close-button
    title="Внимание!"
    :fullscreen="mq.current !== 'lg'"
    :show="syncStore.isShowUnsyncedModal"
    :close-by-overlay="false"
  >
    <div class="unsynced-modal">
      <s-title type="small">На устройстве имеются несинхронизированные данные</s-title>
      <s-text>
        При выходе из системы с несинхронизированными данными есть вероятность потери данных.
      </s-text>
      <s-alert :title="alertText" icon="circle-exclamation" type="warning" />
    </div>
    <template #footer>
      <s-div
        :gap="1"
        stretch
        :style="
          mq.current === 'lg'
            ? {
                display: 'flex'
              }
            : 'flex-direction: column'
        "
      >
        <s-button stretch @click="closeModal">Остаться в системе</s-button>
        <s-button stretch type="error" @click="logOut"> Все равно выйти </s-button>
      </s-div>
    </template>
  </s-modal>
</template>

<style lang="scss" scoped>
.unsynced-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
