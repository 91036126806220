<script setup>
import { computed, ref } from 'vue'

import { useServicesStore } from '@/stores'
import SoilSelectComp from './soil-select-comp.vue'
import SoilSearchSelectComp from './soil-search-select-comp.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const servicesStore = useServicesStore()

const initSource = ref(props.source)

const soilTypes = computed(() => {
  return servicesStore?.soil?.soil_types
})

const soilType = computed(() => {
  return soilTypes.value?.find((e) => e.id === props.source?.soil_type)
})

const groups = computed(() => {
  return props.field.groups?.filter((g) => {
    if (g.service) {
      return getServices(g.service, g.filterValue)?.length
    }
    if (!g.required) return true
    if (!initSource.value[g.required] && initSource.value[g.required] !== 0) {
      return false
    }
    return true
  })
})

const hasChanges = computed(() => {
  return props.field.groups.some((g) => {
    return !!props.source[g.id]
  })
})

const getServices = (serviceName, filterValue) => {
  let soilId = soilType.value?.id
  let soilClass = soilType.value?.soil_class
  let soilGroup = soilType.value?.soil_group
  let soilTypeLocal = soilType.value?.soil_type

  let service
  let fillerWaterSaturation

  if (serviceName === 'filler_saturations') {
    const fillerType = servicesStore?.soil?.fillers_types?.find(
      (e) => e.id === initSource.value.filler_type
    )
    const apiName = fillerType?.api_dict
    fillerWaterSaturation = fillerType?.filler_water_saturation

    service = servicesStore?.soil?.[apiName]

    soilId = fillerType?.id
    soilClass = fillerType?.soil_class
    soilGroup = fillerType?.soil_group
    soilTypeLocal = fillerType?.soil_type
  } else {
    service = servicesStore?.soil?.[serviceName]
  }

  return (
    service?.filter((s) => {
      if (filterValue) {
        if (filterValue?.includes(';')) {
          const [from, to] = filterValue.split(';')

          return s.id >= Number(from) && s.id <= Number(to)
        } else {
          return filterValue?.includes(s.id)
        }
      }
      if (fillerWaterSaturation) {
        return s.filler_water_saturation === fillerWaterSaturation
      }

      const suits = s.soil_type
        ? s.soil_class === soilClass && s.soil_group === soilGroup && s.soil_type === soilTypeLocal
        : s.soil_group
          ? s.soil_class === soilClass && s.soil_group === soilGroup
          : s.soil_class
            ? s.soil_class === soilClass
            : false

      if (s.soil_id?.includes(';')) {
        const [from, to] = s.soil_id.split(';')

        const between = soilId >= Number(from) && soilId <= Number(to)

        return (between || suits) && s.id
      } else {
        return (s.soil_id?.includes(String(soilId)) || suits) && s.id
      }
    }) || []
  )
}
</script>

<template>
  <div class="create-guide-group-select-comp">
    <div v-for="group in groups" :key="group.id" class="create-guide-group-select-comp__item">
      <soil-select-comp
        v-if="!group.search"
        :title="group.title"
        :source="source"
        :soil-interval="soilInterval"
        :field="group"
        @trigger-changed="emits('trigger-changed', hasChanges)"
      />
      <soil-search-select-comp
        v-else
        :title="group.title"
        :source="source"
        :soil-interval="soilInterval"
        :field="group"
        @trigger-changed="emits('trigger-changed', hasChanges)"
      />
    </div>
    <s-input
      v-if="field.commentField"
      v-model="initSource[field.commentField]"
      type="textarea"
      :label="field.commentTitle || 'Комментарий'"
    />
  </div>
</template>

<style lang="scss">
.create-guide-group-select-comp {
  display: grid;
  grid-gap: 2rem;

  &__item {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
