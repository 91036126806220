<script setup>
import { computed, onMounted } from 'vue'
import { parseGeoProcessesList } from '@/utils'
import { useServicesStore } from '@/stores'
import geoGroup from './geo-group.vue'
import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

defineProps({
  title: {
    type: String,
    default: '-'
  },
  recon: {
    type: Object,
    default: () => null
  },
  activeTypes: {
    type: Array,
    default: () => []
  },
  wikiTipData: {
    type: Object,
    default: null
  }
})

const emits = defineEmits(['change-geo-proc'])

const servicesStore = useServicesStore()

const geoTypes = computed(() => {
  const list = servicesStore?.processes?.types

  return list
})

const parsedList = computed(() => {
  return parseGeoProcessesList(geoTypes.value)
})

const emitChange = (id) => {
  emits('change-geo-proc', id)
}

onMounted(() => {
  parsedList.value.forEach((element) => {
    if (!Array.isArray(element)) return
    element.items.forEach((el) => {
      if (el.items) {
        el.items.forEach((item) => {
          item.label = item.title_short
        })
      } else {
        el.label = el.title_short
      }
    })
  })
})

const services = computed(() => {
  return {
    ...servicesStore?.excav,
    ...servicesStore
  }
})

const inputRendererConfig = [
  {
    id: 1,
    title: 'processes_comments',
    label: 'Комментарий к ИГ процессам',
    type: 'textarea'
  }
]
</script>

<template>
  <div class="geo-processes">
    <div class="geo-processes__wiki-tip">
      <s-title type="small">{{ title }}</s-title>
      <wiki-tip v-if="wikiTipData" :data="wikiTipData">
        <s-icon name="fa-circle-question" :color="`var(--${wikiTipData.color})`" />
      </wiki-tip>
    </div>
    <div class="geo-processes__groups">
      <div class="geo-processes__subgroups" v-for="(item, index) in parsedList" :key="item.id">
        <s-text>{{ item.title }}</s-text>
        <div v-if="!item.items[index].items">
          <geo-group :activeTypes="activeTypes" @change="emitChange" :group="item" />
        </div>
        <template v-else>
          <div class="geo-processes__subgroups" v-for="el in item.items" :key="el.id">
            <s-text>{{ el.title }}</s-text>
            <geo-group :activeTypes="activeTypes" @change="emitChange" :group="el" />
          </div>
        </template>
      </div>
      <div class="geo-processes__subgroups">
        <s-input-renderer
          v-for="field in inputRendererConfig"
          :key="field.id"
          :field="field"
          :source="recon"
          :services="services"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.geo-processes {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__wiki-tip {
    display: flex;
    justify-content: space-between;
  }

  &__groups {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__subgroups {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
