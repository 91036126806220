<script setup>
import { computed } from 'vue'
import { useMainStore } from '@/stores'

import SimpleCard from '@/components/simple-card.vue'

const mainStore = useMainStore()

const theme = computed({
  get() {
    return mainStore.theme === 'dark-theme'
  },
  set(switchValue) {
    const value = switchValue ? 'dark-theme' : 'light-theme'

    mainStore.setTheme(value)
  }
})
const themeLabel = computed(() => {
  return mainStore.theme === 'dark-theme' ? 'Темная тема' : 'Светлая тема'
})
</script>

<template>
  <simple-card title="Настройки" icon="gear">
    <div class="profile-settings">
      <ul class="profile-settings__list">
        <li class="profile-settings__item">
          <s-switch v-model="theme" :label="themeLabel" />
        </li>
      </ul>
    </div>
  </simple-card>
</template>

<style lang="scss"></style>
