export const inclusionsTemplate = {
  d0: null,
  d1: null,
  composition: null,
  amount: null,
  type: null,
  comments: '',
  date: null,
  date_front: null
}
