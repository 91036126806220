export const clayFields = {
  basic: [
    {
      id: 'water_saturation',
      value: 'water_saturation',
      title: 'Укажите консистенцию грунта',
      type: 'soil-water-comp',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к консистенции'
    },
    {
      id: 'structure_texture',
      title: 'Укажите информацию о структуре',
      type: 'group',
      groups: [
        {
          id: 'struct_thaw_weight',
          title: 'struct_thaw_weight',
          label: 'Зерна',
          type: 'select',
          service: 'structs_thaw_weight'
        },
        {
          id: 'struct_thaw_grains',
          title: 'struct_thaw_grains',
          label: 'Содержание частиц',
          type: 'select',
          service: 'structs_thaw_grains'
        },
        {
          id: 'struct_thaw_joint',
          title: 'struct_thaw_joint',
          label: 'Отдельность',
          type: 'select',
          service: 'structs_thaw_joint'
        },
        {
          id: 'struct_thaw_porosity',
          title: 'struct_thaw_porosity',
          label: 'Пористость',
          type: 'select',
          service: 'structs_thaw_porosity'
        },
        {
          id: 'text_thaw',
          title: 'text_thaw',
          label: 'Текстура',
          type: 'select',
          service: 'texts_thaw'
        }
      ],
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к структуре / текстуре'
    }
  ],
  additional: true,
  inclusions: true,
  interlayers: true
}
