import BasicInformation from '../components/steps/basic-information.vue'
import ContractorsInfo from '../components/steps/contractors-info.vue'
import EmployeesInfo from '../components/steps/employees-info.vue'
import DeleteObject from '../components/steps/delete-object.vue'

export const objectTemplate = {
  archived: false,
  title: null,
  title_short: null,
  num: null,
  status: 1,
  date_start: null,
  date_end: null,
  client: null,
  contractor: null,
  comments: '',
  geologists: [],
  bore_masters: [],
  bore_machines: []
}

export const basicInformationFields = [
  {
    id: 1,
    title: 'title',
    label: 'Название *',
    required: true,
    tag: 'input',
    tagType: 'textarea',
    type: 'textarea',
    placeholder: 'Например, Инженерно-геологические изыскания под автодорогу, Новый Уренгой',
    wikiTipData: {
      title: 'Название проекта',
      description:
        'Полное название проекта, которое используется при выгрузке отчетной документации'
    }
  },
  {
    id: 2,
    title: 'title_short',
    label: 'Короткое название *',
    required: true,
    tag: 'input',
    tagType: 'text',
    type: 'string',
    placeholder: 'Новый Уренгой',
    maxlength: 30,
    wikiTipData: {
      title: 'Короткое название проекта',
      description: 'Короткое рабочее название проекта, которое используется внутри системы Soilbox'
    }
  },
  {
    id: 3,
    title: 'num',
    label: 'Шифр *',
    required: true,
    tag: 'input',
    tagType: 'text',
    placeholder: 'ИГИ-001',
    maxlength: 25,
    type: 'string'
  },
  {
    id: 4,
    title: 'status',
    label: 'Статус',
    required: true,
    tag: 'select',
    service: 'statuses',
    type: 'select'
  },
  {
    id: 6,
    title: 'archived',
    label: 'Архивный',
    tag: 'switch',
    type: 'switch'
  },
  {
    id: 5,
    title: 'comments',
    label: 'Комментарий',
    tag: 'input',
    tagType: 'textarea',
    type: 'textarea'
  }
]

export const objectContractorsFields = [
  {
    title: 'Информация о контрагентах',
    items: [
      {
        id: 6,
        title: 'client',
        label: 'Заказчик',
        type: 'select',
        required: true,
        service: 'clients',
        clearable: true
      },
      {
        id: 7,
        title: 'contractor',
        label: 'Исполнитель',
        type: 'select',
        required: true,
        service: 'contractors',
        clearable: true
      }
    ]
  },
  {
    title: 'Сроки выполнения полевых работ',
    items: [
      {
        id: 8,
        title: 'date_start',
        label: 'Начало',
        type: 'date',
        required: true,
        placeholder: 'Не выбрано',
        dateOptions: {
          min: null,
          max: null,
          condition: {
            field: 'date_end',
            value: 'less'
          }
        }
      },
      {
        id: 9,
        title: 'date_end',
        label: 'Окончание',
        type: 'date',
        required: true,
        placeholder: 'Не выбрано',
        dateOptions: {
          min: null,
          max: null,
          condition: {
            field: 'date_start',
            value: 'more'
          }
        }
      }
    ]
  }
]

export const objectFieldsEmployees = [
  {
    id: 10,
    title: 'geologists',
    label: 'Пользователи',
    type: 'select',
    multiple: true,
    service: 'users',
    placeholder: 'Выберите пользователя'
  },
  {
    id: 11,
    title: 'bore_machines',
    label: 'Буровые установки',
    type: 'select',
    multiple: true,
    service: 'bore_machines',
    placeholder: 'Выберите буровую установку'
  },
  {
    id: 12,
    title: 'bore_masters',
    label: 'Буровые мастера',
    type: 'select',
    multiple: true,
    service: 'bore_masters',
    placeholder: 'Выберите бурового мастера'
  }
]

export const ObjectDeleteFields = [
  {
    title: 'Вы хотите удалить данный проект?',
    items: [
      {
        id: 13,
        tagType: 'alert',
        alertTitle: `При удалении проекта вы потеряете все данные, находящиеся в этом проекте (скважины и все внутренние данные).
Восстановить эти данные будет невозможно.`,
        alertType: 'warning',
        alertIcon: 'exclamation-circle'
      }
    ]
  }
]

export const ObjectEditorSteps = [
  {
    step: 1,
    title: 'Основная информация',
    component: BasicInformation,
    fields: basicInformationFields,
    warning: false
  },
  {
    step: 2,
    title: 'Контрагенты и сроки',
    component: ContractorsInfo,
    fields: objectContractorsFields,
    warning: false
  },
  {
    step: 3,
    title: 'Сотрудники и оборудование',
    component: EmployeesInfo,
    fields: objectFieldsEmployees,
    warning: false
  },
  {
    step: 4,
    title: 'Удаление данных',
    component: DeleteObject,
    fields: ObjectDeleteFields,
    editorOnly: true,
    warning: false
  }
]
