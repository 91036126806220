<script setup>
import { ref } from 'vue'
import SoilSelectComp from './soil-select-comp.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const initSource = ref(props.source)

const wikiTip = {
  color: 'placeholder',
  title: 'Шлиры',
  description:
    'Включения льда в мерзлом грунте, представленные прожилками, прослойками, линзами разной ориентировки, образующие криогенные текстуры. Максимальная толщина шлиров условно принимается равной 0.3-0.5 м; при большей толщине образования следует называть ледяными пластами или линзами, которые уже не входят в понятие криогенной текстуры',
  source:
    'Рекомендации по методике изучения подземных льдов и криогенного строения многолетнемерзлых грунтов, 1986'
}
</script>

<template>
  <div class="create-guide-text-pfrost">
    <div class="create-guide-text-pfrost__item">
      <soil-select-comp
        :source="source"
        :soil-interval="soilInterval"
        :field="field"
        no-comment
        @trigger-changed="emits('trigger-changed', $event)"
      />
    </div>
    <div v-if="initSource[field.id] && !field.rock" class="create-guide-text-pfrost__item">
      <div>
        <s-title type="small"> Укажите информацию о шлирах </s-title>
        <wiki-tip :data="wikiTip">
          <s-icon name="fa-circle-question" :color="`var(--${wikiTip.color})`" />
        </wiki-tip>
      </div>
      <s-text> Ширина шлиров, мм </s-text>
      <div class="create-guide-text-pfrost__item-row">
        <s-number-input v-model="initSource.ice_schliere_width_from" placeholder="От" />
        <s-text>-</s-text>
        <s-number-input v-model="initSource.ice_schliere_width_to" placeholder="До" />
      </div>
      <s-text> Расстояние между шлирами, мм </s-text>
      <div class="create-guide-text-pfrost__item-row">
        <s-number-input v-model="initSource.ice_schliere_interval_from" placeholder="От" />
        <s-text>-</s-text>
        <s-number-input v-model="initSource.ice_schliere_interval_to" placeholder="До" />
      </div>
    </div>
    <s-input
      v-if="field.commentField"
      v-model="initSource[field.commentField]"
      type="textarea"
      :label="field.commentTitle || 'Комментарий'"
    />
  </div>
</template>
<style lang="scss">
.create-guide-text-pfrost {
  display: grid;
  grid-gap: 2rem;

  &__item {
    display: grid;
    grid-gap: 0.5rem;

    &-row {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
    }
  }
}
</style>
