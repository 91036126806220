import { getDataFromLS, saveDataToLS } from '@/utils'

export const saveToRecentList = (name, data) => {
  const alreadyExistObjArr = getDataFromLS(name)
  if (!alreadyExistObjArr) {
    saveDataToLS(name, [data])
    return
  }

  const sameIdIdx = findIndex(name, data)
  if (sameIdIdx !== -1) {
    alreadyExistObjArr.splice(sameIdIdx, 1)
    alreadyExistObjArr.unshift(data)
    saveDataToLS(name, alreadyExistObjArr)
    return
  }

  const MAX_ITEMS_COUNT = 10
  const arrLength = checkObjIdArrLength(name)
  if (arrLength === MAX_ITEMS_COUNT) {
    alreadyExistObjArr.splice(MAX_ITEMS_COUNT - 1)
    saveDataToLS(name, alreadyExistObjArr)
  }

  alreadyExistObjArr.unshift(data)
  saveDataToLS(name, alreadyExistObjArr)
}

const findIndex = (name, data) => getDataFromLS(name).indexOf(data)

const checkObjIdArrLength = (name) => getDataFromLS(name).length
