import joinProject from './actions/join-project'
import deleteRecon from './actions/delete-recon'

const contextMenu = {
  items: {
    joinProject,
    deleteRecon
  }
}

export default contextMenu
