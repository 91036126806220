const lineColor = '#3C98E7'
const labelColor = '#3C98E7'

export const linesDistanceConfig = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': lineColor,
    'line-width': 3,
    'line-dasharray': [1, 2]
  }
}

export const linesDistanceLabelsConfig = {
  type: 'symbol',
  layout: {
    'text-justify': 'left',
    'text-field': ['format', ['concat', ['get', 'distance'], ' м']],
    'text-size': 12,
    'text-offset': [0, 1],
    'symbol-placement': 'line-center',
    'text-allow-overlap': true
  },
  paint: {
    'text-color': labelColor,
    'text-halo-color': '#000000',
    'text-halo-width': 2
  }
}
