<script setup>
import { computed, ref, onMounted } from 'vue'
import { toggleBodyTheme, getDataFromLS } from '@/utils'
import { usePrintStore } from '@/stores'

import DpControls from './components/dp-controls.vue'
import DpHeader from './components/dp-header.vue'
import DpMap from './components/dashboard-print-map/dashboard-print-map.vue'
import DpObjectData from './components/object-data.vue'
import DpExcavations from './components/excavations/excavations-list.vue'

const printStore = usePrintStore()

const loaded = ref(false)

const loading = computed({
  get() {
    return printStore.loading
  },
  set(value) {
    printStore.setField('loading', value)
  }
})

onMounted(() => {
  loading.value = true

  document.body.style.overflow = 'auto'

  setDataFromLS()
  setTimeout(() => {
    toggleBodyTheme('light-theme')
  }, 100)

  loading.value = false
})

const setDataFromLS = () => {
  const data = getDataFromLS('dashboardPrintData')

  printStore.setField('dashboard', data)

  loaded.value = true
}
</script>

<template>
  <div v-loading="loading" class="dashboard-print">
    <div class="dashboard-print__controls"><dp-controls /></div>
    <div v-if="loaded" id="print-page" class="dashboard-print__content">
      <div class="dashboard-print__header">
        <dp-header />
      </div>
      <div class="dashboard-print__map">
        <dp-map />
      </div>
      <div class="dashboard-print__object-data">
        <dp-object-data />
      </div>
      <div
        v-if="printStore.showExcavations && printStore.filteredProductions"
        id="dp-excavations"
        class="dashboard-print__excavations"
      >
        <dp-excavations />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.dashboard-print {
  width: 800px;
  margin: 0 auto;
  padding: 24px;

  &__controls {
    margin-bottom: 24px;
  }

  &__content {
    background-color: var(--card-bg);
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__object-data {
    margin-top: 32px;
  }

  &__excavations {
    margin-top: 48px;
  }
}
</style>
