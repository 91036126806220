<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores'
import router from '@/router/index.js'

const authStore = useAuthStore()

const userData = ref({
  username: '',
  password: ''
})

const alertTitle = ref('')
const incorrectData = ref(false)
const loading = ref(false)

const login = async () => {
  if (!userData.value.username || !userData.value.password) {
    alertTitle.value = 'Неверный логин или пароль. Логин и пароль чувствительны к регистру'
    incorrectData.value = true
    return
  }

  try {
    incorrectData.value = false
    loading.value = true

    await authStore.authRequest(userData.value)

    await router.push('/app')
  } catch (e) {
    if (e.response && e.response.status === 400) {
      alertTitle.value = 'Неверный логин или пароль. Логин и пароль чувствительны к регистру'
    } else {
      alertTitle.value = 'Ошибка при запросе на сервер'
    }

    incorrectData.value = true
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="auth-form">
    <s-title class="auth-form-title">Вход в систему</s-title>
    <form @submit.prevent @enter="login" class="auth-form-form">
      <div class="auth-form-input-group">
        <s-input v-model="userData.username" icon-name="user" placeholder="Имя пользователя" />
        <s-input
          v-model="userData.password"
          icon-name="lock"
          type="password"
          placeholder="Пароль"
        />
        <s-alert
          v-if="incorrectData"
          :title="alertTitle"
          full-width
          type="error"
          icon="fa-circle-exclamation"
          center-text
          text-small
        />
      </div>
      <s-button button-attribute-type="submit" @click="login" type="primary" :loading="loading">
        {{ loading ? 'Выполняется вход...' : 'Войти' }}
      </s-button>
    </form>
    <s-text type="secondary" center small>
      Если у вас отсутствует учетная запись, обратитесь к администратору системы
    </s-text>
  </div>
</template>

<style lang="scss">
.auth-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;

  &-title {
    @include tablets {
      text-align: center;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-input-group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & .s-input {
      font-size: 14px;
    }
  }
}
</style>
