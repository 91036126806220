<script setup>
import { computed } from 'vue'
import { useObjectsStore } from '@/stores'

const props = defineProps({
  dataType: {
    type: String,
    default: 'excavations'
  }
})

const selectedData = computed(() => {
  const { excavationsToExport, reconsToExport } = useObjectsStore()

  if (props.dataType === 'excavations') {
    return excavationsToExport?.filter((e) => e.checked)
  }

  return reconsToExport?.filter((e) => e.checked)
})

const meters = computed(() => {
  return {
    fact: selectedData.value.reduce((prev, el) => (prev += el.h), 0)
  }
})

const typeText = computed(() => {
  return props.dataType === 'excavations' ? 'скв.' : 'ТН.'
})
</script>

<template>
  <div class="e-excavations-stats">
    <s-alert v-if="selectedData.length" type="primary" icon="info-circle">
      <s-text>
        <div>
          Будет экспортировано
          <strong>{{ selectedData.length }} {{ typeText }}</strong>
          <span v-if="dataType === 'excavations'">
            общей глубиной <strong>{{ meters.fact.toFixed(2) }} п.м.</strong></span
          >
        </div>
      </s-text>
    </s-alert>

    <s-alert
      title="В проекте отсутствуют данные с заполненной информацией"
      full-width
      type="warning"
      icon="fa-circle-exclamation"
      v-else
    />
  </div>
</template>

<style lang="scss">
.e-excavations-stats {
  margin-top: 16px;
}
</style>
