import $rDate from 'ritm-date'

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const formatStringArray = (stringArray) => {
  if (!stringArray.length) return ''
  if (stringArray.length === 1) return stringArray[0]
  if (stringArray.length === 2) return `${stringArray[0]} и ${stringArray[1]}`
  if (stringArray.length === 3) return `${stringArray[0]}, ${stringArray[1]} и ${stringArray[2]}`

  if (stringArray.length > 3) {
    const withOutLast = stringArray.slice(0, -1)
    const lastElement = stringArray[stringArray.length - 1]

    return `${withOutLast.join(', ')} и ${lastElement}`
  }
}

export const getFormattedFromToDate = (dateFrom, dateTo, type) => {
  if (type === 101) {
    dateFrom = dateFrom ? $rDate(dateFrom).format('DD.MM.YY') : '-'
    dateTo = dateTo ? $rDate(dateTo).format('DD.MM.YY') : '-'

    return `${dateFrom} / ${dateTo}`
  }
  dateFrom = dateFrom ? $rDate(dateFrom).format('DD.MM.YY') : '-'

  return dateFrom
}

export const separatedString = (stringArray) => {
  return stringArray.join(', ')
}

export const getObjectDatesLabel = (date_start, date_end, isObjectItem) => {
  if (!date_start && !date_end) {
    if (isObjectItem) {
      return '- / -'
    }
    return 'Сроки не указаны'
  }

  let startLabel
  let endLabel

  let noDateValue

  if (isObjectItem) {
    noDateValue = '-'
  } else {
    noDateValue = 'Не указано'
  }

  startLabel = date_start ? $rDate(date_start).format('DD.MM.YY') : noDateValue
  endLabel = date_end ? $rDate(date_end).format('DD.MM.YY') : noDateValue

  return `${startLabel} - ${endLabel}`
}
