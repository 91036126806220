<script setup>
import { computed, ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { useServicesStore } from '@/stores'
import SoilSelectItem from './common/soil-select-item.vue'
import { additionalGroups } from '@/utils'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const servicesStore = useServicesStore()

const initSource = ref(props.source)
const clone = ref(cloneDeep(props.source))

const soilTypes = computed(() => {
  return servicesStore?.soil?.soil_types
})

const soilType = computed(() => {
  return soilTypes.value?.find((e) => e.id === props.source?.soil_type)
})

const soilEnd = computed(() => {
  if (soilType.value?.end2) return 2
  else if (soilType.value?.end3) return 3
  else return 1
})

const services = computed(() => {
  const soilId = soilType.value?.id
  const soilClass = soilType.value?.soil_class
  const soilGroup = soilType.value?.soil_group
  let soilTypeLocal = soilType.value?.soil_type
  const groups = additionalGroups()

  const allServices = servicesStore.soil || {}

  const services = groups.map((g) => {
    const items = g.items.map((f) => {
      const service =
        allServices[f.service]?.filter((s) => {
          if (!s.id) return false

          if (!s.soil_type && !s.soil_group && !s.soil_class && !s.soil_id) {
            return true
          }

          const suits = s.soil_type
            ? s.soil_class === soilClass &&
              s.soil_group === soilGroup &&
              s.soil_type === soilTypeLocal
            : s.soil_group
              ? s.soil_class === soilClass && s.soil_group === soilGroup
              : s.soil_class
                ? s.soil_class === soilClass
                : false

          if (s.soil_id?.includes(';')) {
            const [from, to] = s.soil_id.split(';')

            const between = soilId >= Number(from) && soilId <= Number(to)

            return between || suits
          } else {
            return s.soil_id?.includes(String(soilId)) || suits
          }
        }) || []

      return {
        id: f.id,
        service
      }
    })

    return {
      id: g.id,
      title: g.title,
      items: items.filter((s) => s.service?.length)
    }
  })

  return services?.filter((s) => s.items?.length)
})

const getAdditionalItem = (item) => {
  const field = {
    id: item?.service[0]?.id,
    title: item?.service[0]?.title,
    end: item?.service[0]?.end1,
    title_short:
      item?.service[0]?.title_short || item?.service[0]?.title + (item?.service[0]?.end1 || ''),
    description: item?.service[0]?.description,
    specification: item?.service[0]?.specification,
    field: item.id,
    sources: item?.service[0]?.sources
  }

  return field
}

const isActive = (item) => {
  return !!initSource.value[item.id]
}
const setItem = (item) => {
  const hasChanges = clone.value[item.id] !== props.source[item.id]

  initSource.value[item.id] = !initSource.value[item.id] ? item?.service?.[1]?.id || 1 : null

  emits('trigger-changed', !hasChanges)
}
</script>

<template>
  <div class="create-guide-additional-comp">
    <div v-for="group in services" :key="group.id" class="create-guide-additional-comp__item">
      <s-text v-if="group.title">
        {{ group.title }}
      </s-text>
      <div class="create-guide-additional-comp__block">
        <soil-select-item
          v-for="item in group.items"
          :key="item.id"
          :item="getAdditionalItem(item)"
          :end="soilEnd"
          :active="isActive(item)"
          :has-cancel="isActive(item)"
          @handler="setItem(item)"
        />
      </div>
    </div>
    <s-input v-model="initSource.comments" type="textarea" label="Общий комментарий к слою" />
  </div>
</template>

<style lang="scss">
.create-guide-additional-comp {
  display: grid;
  grid-gap: 2rem;

  &__item {
    display: grid;
    grid-gap: 1rem;
  }

  &__block {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include phones {
  .create-guide-additional-comp {
    &__block {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
