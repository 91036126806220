import {
  baseSteps,
  claySteps,
  frozenSteps,
  frozenLargeScaleSteps,
  frozenRockSteps,
  frozenSandSteps,
  iceSteps,
  largeScaleSteps,
  prsSteps,
  rockSteps,
  sandSteps,
  technoSteps
} from '../configs'

const stepModels = {
  claySteps,
  frozenSteps,
  iceSteps,
  technoSteps,
  largeScaleSteps,
  prsSteps,
  frozenPrsSteps: frozenSteps,
  frozenLargeScaleSteps,
  frozenRockSteps,
  frozenSandSteps,
  rockSteps,
  sandSteps,
  technoClaySteps: claySteps,
  technoLargeScaleSteps: largeScaleSteps,
  technoSandSteps: sandSteps,
  technoRockSteps: rockSteps
}

export const getSteps = (type, showAdditional, typeBak) => {
  const model = stepModels?.[`${type}Steps`]
  let mainPart

  if (type === 'rock' || type === 'frozenRock' || type === 'technoRock') {
    const soilGroup = typeBak?.soil_group
    const soilClass = typeBak?.soil_class
    const soilType = typeBak?.soil_type

    mainPart = model?.main?.filter((e) => {
      if (e.id === 'cement') {
        if (soilGroup === 7 && soilClass === 2 && soilType === 1) {
          return true
        } else {
          return false
        }
      }
      return true
    })
  } else {
    mainPart = model?.main || []
  }

  const additionalPart = model?.additional || []

  if (showAdditional) return [...baseSteps, ...mainPart, ...additionalPart]
  else return [...baseSteps, ...mainPart]
}

export const checkDepth = (depth, prevDepth) => {
  return !!depth && depth !== prevDepth && depth > prevDepth
}
