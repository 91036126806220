import { getFromService } from './index'
import { getClayDescription } from './clay'
import { getLargeScaleDescription } from './large-scale'
import { getSandDescription } from './sand'
import { getRockDescription } from './rock'

export const getTechnoDescription = (parent, data, soilType) => {
  let soilDescription = ''

  if (parent?.soilSource) {
    const items = attrs.map((e) => {
      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredItems = items.filter((e) => e)

    if (filteredItems?.length) {
      filteredItems.forEach((e) => {
        soilDescription = `${e}, ${soilDescription}`
      })
    }
  }

  switch (soilType) {
    case 'clay':
      soilDescription += getClayDescription(parent, data)
      break
    case 'largeScale':
      soilDescription += getLargeScaleDescription(parent, data)
      break
    case 'sand':
      soilDescription += getSandDescription(parent, data)
      break
    case 'rock':
      soilDescription += getRockDescription(parent, data)
      break
    default:
      soilDescription += parent.soilName
      break
  }

  return `Техногенный грунт, ${soilDescription.toLowerCase()}`
}

const attrs = [
  { id: 'alteration_techno_type', service: 'alterations_techno_types', titleEnd: 'end1' }
]
