<template>
  <div class="d-item-cell" :style="styleSettings">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 40
    },
    stretched: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styleSettings() {
      const { stretched, center, width } = this

      if (stretched) {
        return {
          flex: 1,
          justifyContent: center ? 'center' : 'flex-start'
        }
      }

      return {
        width: `${width}px`,
        justifyContent: center ? 'center' : 'flex-start'
      }
    }
  }
}
</script>

<style lang="scss">
.d-item-cell {
  display: flex;
  flex-shrink: 0;
}
</style>
