import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useOverlaysStore = defineStore('overlays', () => {
  const overlays = ref(null)
  const setOverlays = (val) => {
    overlays.value = val
  }

  const zones = ref(null)
  const setZones = (val) => {
    zones.value = val
  }

  const legend = ref(false)
  const toggleLegend = (value) => {
    legend.value = value
  }

  return {
    overlays,
    setOverlays,
    legend,
    toggleLegend,
    zones,
    setZones
  }
})
