<script setup>
import { ref, computed } from 'vue'
import { useMq } from 'vue3-mq'

import MapLocationEditor from '@/modules/app/data/maps/map-location-editor.vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  newItem: {
    type: Boolean,
    default: false
  },
  center: {
    type: Array,
    default: () => null
  },
  item: {
    type: Object,
    default: () => null
  },
  template: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['toggle', 'save'])

const mq = useMq()

const initCenter = ref(props.center)

const title = computed(() => {
  return props.newItem ? 'Укажите планируемое местоположение' : 'Укажите фактическое местоположение'
})

const setCenter = (value) => {
  initCenter.value = value
}

const toggleModal = () => {
  emits('toggle')
}

const save = () => {
  emits('save', initCenter.value)
  toggleModal()
}
</script>

<template>
  <s-modal
    title="Редактирование координат"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    @close="toggleModal"
  >
    <div class="s-map-location">
      <s-title type="small">
        {{ title }}
      </s-title>

      <map-location-editor
        :center="initCenter"
        :source="item"
        :template="template"
        :only-plan="newItem"
        @update-location="setCenter"
      />
    </div>
    <template #footer>
      <s-button type="primary" @click="save"> Сохранить координаты </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.s-map-location {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: auto;
}
</style>
