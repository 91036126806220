export const basicFields = {
  desktopFields: [
    {
      id: 1,
      fields: [
        {
          id: 1,
          title: 'geologist',
          label: 'Геолог',
          type: 'select',
          select: 'users'
        },
        {
          id: 2,
          title: 'coordinates-fact',
          label: 'Коорд-ты (факт.)',
          type: 'coordinates-fact'
        },
        {
          id: 3,
          title: 'date_end',
          label: 'Дата',
          type: 'date'
        },
        {
          id: 4,
          title: 'coordinates-plan',
          label: 'Коорд-ты (план.)',
          type: 'coordinates-plan'
        }
      ]
    },
    {
      id: 2,
      fields: [
        {
          id: 5,
          title: 'site',
          label: 'Участок работ',
          type: 'string'
        },
        {
          id: 6,
          title: 'comments',
          label: 'Комментарий',
          type: 'string',
          stretch: true
        }
      ]
    }
  ]
}

export const secondaryFields = {
  desktopFields: [
    {
      id: 1,
      fields: [
        {
          id: 1,
          title: 'location',
          label: 'Местоположение',
          type: 'string'
        },
        {
          id: 2,
          title: 'geomorph_id',
          commentField: 'geomorph_comments',
          label: 'Геоморф-ие условия',
          type: 'select',
          select: 'geomorphies'
        },
        {
          id: 3,
          title: 'processes',
          commentField: 'processes_comments',
          label: 'Инж-гео процессы',
          type: 'select',
          select: 'types'
        }
      ]
    },
    {
      id: 2,
      fields: [
        {
          id: 5,
          title: 'exposures',
          label: 'Обнажения',
          type: 'string'
        },
        {
          id: 6,
          title: 'waterfronts',
          label: 'Водопроявления',
          type: 'string'
        },
        {
          id: 7,
          title: 'geobotany',
          label: 'Геоботанические индикаторы',
          type: 'string'
        },
        {
          id: 8,
          title: 'polls',
          label: 'Опрос местного населения',
          type: 'string'
        },
        {
          id: 9,
          title: 'inspections',
          label: 'Обследование объектов',
          type: 'string'
        }
      ]
    }
  ]
}

export const galleryFields = [
  {
    id: 'images',
    title: 'Фотографии общего вида',
    storeField: 'reconCommonImages',
    images: 'images',
    files: 'files',
    clone: 'cloneCommonImages',
    wikiTipData: {
      color: 'placeholder',
      title: 'Фото общего вида',
      description: 'Фотографии общего вида точек наблюдений на местности'
    }
  },
  {
    id: 'journal',
    title: 'Фотографии журнала',
    storeField: 'reconJournalImages',
    images: 'journalImages',
    files: 'journalFiles',
    clone: 'cloneJournalImages',
    wikiTipData: {
      color: 'placeholder',
      title: 'Фото журналов',
      description: 'Фотографии или сканированные копии нестандартных бумажных журналов'
    }
  }
]
