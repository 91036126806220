import { getFromService } from './index'

export const getSandDescription = (parent, data) => {
  let soilDescription =
    parent?.soilName +
    (parent?.comment ? `, ${parent?.comment}` : '') +
    (parent?.color ? `, ${parent?.color}` : '') +
    (parent?.colorComment ? `, ${parent?.colorComment?.toLowerCase()}` : '') +
    (parent?.waterSaturation ? `, ${parent?.waterSaturation}` : '') +
    (parent?.waterSaturationComment
      ? `, ${parent?.waterSaturationComment?.toLowerCase()}`
      : '')

  if (parent?.soilSource) {
    const items1 = attrs1.map(e => {
      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredItems1 = items1.filter(e => e)

    if (filteredItems1?.length) {
      filteredItems1.forEach(e => {
        soilDescription += `, ${e}`
      })
    }
  }

  return soilDescription
}

const attrs1 = [
  { id: 'text_thaw', service: 'texts_thaw' },
  { id: 'struct_text_comments' },
  { id: 'homogen', service: 'homogen' },
  { id: 'composition_chem', service: 'compositions_chem' },
  { id: 'debris_round', service: 'debris_rounds' },
  { id: 'composition_grains', service: 'compositions_grains' },
  { id: 'composition_comments' },
  { id: 'density', service: 'densities' },
  { id: 'density_comments' }
]
