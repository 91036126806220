<script setup>
import { computed } from 'vue'

const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  size: {
    type: Number,
    default: 64
  }
})

const styleSettings = computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`
  }
})

const textStyle = computed(() => {
  return {
    'font-size': `${props.size / 2.5}px`,
    'text-transform': 'uppercase',
    'line-height': 1
  }
})

const userLabel = computed(() => {
  const { first_name, last_name, username } = props.user

  if (first_name && last_name) {
    return `${last_name[0]}${first_name[0]}`
  } else if (first_name) {
    return first_name[0]
  } else if (username) {
    return username[0]
  }

  return ''
})
</script>

<template>
  <div class="user-avatar" :style="styleSettings">
    <s-title>
      <span :style="textStyle">{{ userLabel }}</span>
    </s-title>
  </div>
</template>

<style lang="scss">
.user-avatar {
  background-color: var(--bg);
  border: 1px solid var(--main-bg);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
</style>
