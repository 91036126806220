<script setup>
import { useMainStore } from '@/stores'

const mainStore = useMainStore()

const emits = defineEmits(['close-install-tip'])
const closeInstallTIp = () => {
  emits('close-install-tip')
}
</script>

<template>
  <div class="install-tip">
    <div class="install-tip__content">
      <s-title type="main">Установка приложения</s-title>
      <s-text class="install-tip__text">
        Приложение Soilbox может быть установлено на домашний экран вашего устройства для более
        комфортного использования.
      </s-text>
      <div v-if="mainStore.deviceOS === 'iOS'" class="install-tip__list">
        <ol>
          <li>
            <div class="install-tip__step">
              Откройте меню браузера
              <img
                class="install-tip__icon"
                src="@/assets/images/pwa-install-tip/ios-icon-browser.svg"
                alt=""
              />
            </div>
          </li>
          <li>Нажмите кнопку <span>"На экран Домой”</span></li>
        </ol>
        <div class="install-tip__img-wrapper">
          <img src="@/assets/images/pwa-install-tip/ios-install-tip.png" alt="" />
        </div>
      </div>
      <div v-else class="install-tip__list">
        <ol>
          <li>
            <div class="install-tip__step">
              Откройте меню браузера
              <img
                class="install-tip__icon"
                src="@/assets/images/pwa-install-tip/ellipsis.svg"
                alt=""
              />
            </div>
          </li>
          <li>
            Нажмите кнопку
            <span>"Установить приложение”</span>
            или
            <span>“Добавить на домашний экран”</span>
          </li>
        </ol>
        <div class="install-tip__img-wrapper">
          <img src="@/assets/images/pwa-install-tip/android-install-tip.png" alt="" />
        </div>
      </div>
    </div>
    <div class="install-tip__footer">
      <s-button stretch @click="closeInstallTIp"> Понятно</s-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.install-tip {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: var(--card-bg);
  z-index: 101;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  opacity: 1;

  & ol {
    padding-left: 4px;
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
  }

  &__footer {
    padding-top: 32px;
  }

  &__text {
    margin-top: 24px;
  }

  &__list {
    padding-left: 16px;
    margin-top: 16px;

    & span {
      color: var(--primary);
    }

    & li {
      list-style-type: decimal;
      color: var(--text);
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  &__img-wrapper {
    margin: 24px auto 0;
    max-width: 211px;

    & img {
      width: 100%;
    }
  }

  &__step {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-left: 4px;
  }
}
</style>
