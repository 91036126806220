<script setup>
import { ref } from 'vue'
import { useRulerStore } from '@/stores'
import { saveDataToLS } from '@/utils'

defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['closeModal'])

const rulerStore = useRulerStore()

const checkboxValue = ref(false)

const cancel = () => {
  rulerStore.setIsShowRulerModal(false)
}

const closeModal = () => {
  if (checkboxValue.value) {
    saveDataToLS('dont-show', true)
  }

  rulerStore.toggleRuler()
  emits('closeModal')
}
</script>

<template>
  <s-modal
    noCloseButton
    positionCenter
    :maxWidth="418"
    title="Закрытие инструмента"
    :show="isVisible"
  >
    <div class="ruler-confirm">
      <s-text>Вы уверены, что хотите завершить работу с инструментом Линейка?</s-text>
      <s-checkbox v-model="checkboxValue" label="Больше не показывать данное окно" />
    </div>

    <template #footer>
      <div class="ruler-confirm__footer">
        <s-button type="default" stretch @click="cancel"> Отменить</s-button>
        <s-button type="primary" stretch @click="closeModal"> Закрыть</s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.ruler-confirm {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
</style>
