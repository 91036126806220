export const innerCircleConfig = {
  type: 'circle',
  paint: {
    'circle-radius': 3,
    'circle-color': '#3C98E7',
    'circle-stroke-width': 1.5,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-opacity': 1,
    'circle-pitch-alignment': 'map'
  }
}
export const outerCircleConfig = {
  type: 'circle',
  paint: {
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 14, 18, 24, 24],
    'circle-color': '#3C98E7',
    'circle-opacity': 0.15,
    'circle-pitch-alignment': 'map'
  }
}
