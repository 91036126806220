import { getFromService } from './index'

export const getFrozenLargeScaleDescription = (parent, data) => {
  const isTechno = !!data.alteration_techno

  let soilDescription =
    parent?.soilName +
    (parent?.stateName ? `, ${parent?.stateName}` : '') +
    (parent?.comment ? `, ${parent?.comment?.toLowerCase()}` : '') +
    (parent?.color ? `, ${parent?.color}` : '') +
    (parent?.colorComment ? `, ${parent?.colorComment?.toLowerCase()}` : '')

  if (parent?.soilSource) {
    if (isTechno) {
      const technoItems = technoAttrs.map((e) => {
        return getFromService(parent?.soilSource, data, e, parent?.soilServices)
      })

      const filteredTechnoItems = technoItems.filter((e) => e)

      if (filteredTechnoItems?.length) {
        filteredTechnoItems.forEach((e) => {
          soilDescription = `${e}, ${soilDescription}`
        })
      }

      soilDescription = `Техногенный грунт, ${soilDescription.toLowerCase()}`
    }

    const items1 = attrs1.map((e) => {
      if (e.id === 'water_saturation') {
        e.service =
          parent?.soilServices?.fillers_types?.find((e) => e.id === data.filler_type)?.api_dict ||
          'water_saturations_other'
      }

      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredItems1 = items1.filter((e) => e)

    if (filteredItems1?.length) {
      filteredItems1.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  if (parent?.soilSource) {
    const textItems = textAttrs.map((e) => {
      const value = getFromService(parent?.soilSource, data, e, parent?.soilServices)
      return value && e.prefix ? `${e.prefix} ${value}` : value
    })

    const filteredTextItems = textItems.filter((e) => e)

    if (filteredTextItems?.length) {
      filteredTextItems.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  if (data) {
    if (data?.ice_schliere_width_from && data?.ice_schliere_width_to) {
      soilDescription += `, ширина шлиров от ${
        data?.ice_schliere_width_from || '-'
      } до ${data?.ice_schliere_width_to || '-'} мм`
    } else if (data?.ice_schliere_width_from) {
      soilDescription += `, ширина шлиров от ${data?.ice_schliere_width_from || '-'} мм`
    } else if (data?.ice_schliere_width_to) {
      soilDescription += `, ширина шлиров до ${data?.ice_schliere_width_to || '-'} мм`
    }

    if (data?.ice_schliere_interval_from && data?.ice_schliere_interval_to) {
      soilDescription += `, расстояние между шлирами от ${
        data?.ice_schliere_interval_from || '-'
      } до ${data?.ice_schliere_interval_to || '-'} мм`
    } else if (data?.ice_schliere_interval_from) {
      soilDescription += `, расстояние между шлирами от ${
        data?.ice_schliere_interval_from || '-'
      } мм`
    } else if (data?.ice_schliere_interval_to) {
      soilDescription += `, расстояние между шлирами до ${data?.ice_schliere_interval_to || '-'} мм`
    }

    if (data?.struct_text_comments) {
      soilDescription += `, ${data?.struct_text_comments?.toLowerCase()}`
    }
  }

  if (parent?.soilSource) {
    const moreAttrs = [
      {
        id: 'ice_content',
        service: iceContentServiceName(data)
      },
      {
        id: 'ice_content_comments'
      },
      { id: 'state_pfrost', service: 'states_pfrost' },
      { id: 'water_saturation', service: pFrostServiceName(data) },
      { id: 'water_saturation_comments' }
    ]

    const moreAttrsItems = moreAttrs.map((e) => {
      const info = getFromService(parent?.soilSource, data, e, parent?.soilServices)

      if (e.id === 'water_saturation') {
        return info ? `при оттаивании ${info}` : ''
      } else {
        return info
      }
    })

    const filteredMoreAttrsItems = moreAttrsItems.filter((e) => e)

    if (filteredMoreAttrsItems?.length) {
      filteredMoreAttrsItems.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  let hasDebris = false

  if (parent?.soilSource) {
    const items2 = attrs2.map((e) => {
      const serviceInfo = getFromService(parent?.soilSource, data, e, parent?.soilServices)

      const value = e.subInfo && serviceInfo ? e.subInfo + ' ' + serviceInfo : serviceInfo

      if (e.id !== 'debris_subinfo') {
        if (value && debrisAttrs.includes(e.id)) {
          hasDebris = true
        }

        return {
          ...e,
          value
        }
      } else {
        return e
      }
    })

    const filteredItems2 = items2.filter((e) => {
      if (e.id === 'debris_subinfo') {
        return hasDebris
      } else {
        return e.value
      }
    })

    if (filteredItems2?.length) {
      filteredItems2.forEach((e) => {
        const comma = !e.noComma ? ',' : ''

        soilDescription += `${comma} ${e.value}`
      })
    }
  }

  return soilDescription
}

const technoAttrs = [
  { id: 'alteration_techno_type', service: 'alterations_techno_types', titleEnd: 'end1' }
]

const textAttrs = [{ id: 'text_pfrost', service: 'texts_pfrost', prefix: 'криотекстура' }]

const iceContentServiceName = (data) => {
  if (data?.soil_type >= 11201 && data?.soil_type <= 11205) {
    return 'ice_contents_sand'
  } else if (data?.soil_type >= 12101 && data?.soil_type <= 12301) {
    return 'ice_contents_clay'
  } else if (data?.soil_type >= 20000 && data?.soil_type < 30000) {
    return 'ice_contents_rock'
  } else {
    return ''
  }
}

const pFrostServiceName = (data) => {
  if (data?.soil_type >= 11101 && data?.soil_type <= 11205) {
    return 'water_saturations_sand'
  } else if (data?.soil_type >= 12102 && data?.soil_type <= 12103) {
    return 'water_saturations_clay'
  } else if (data?.soil_type === 12101) {
    return 'water_saturations_sandy_clay'
  } else {
    return 'water_saturations_other'
  }
}

const attrs1 = [
  { id: 'filler_type', service: 'fillers_types' },
  { id: 'filler_amount', service: 'fillers_amounts' },
  { id: 'filler_comments' }
]

const attrs2 = [
  { id: 'debris_subinfo', value: 'преобладают' },
  {
    id: 'debris_round',
    service: 'debris_rounds',
    noComma: true,
    titleEnd: 'end3'
  },
  { id: 'debris_subinfo', value: 'обломки', noComma: true },
  {
    id: 'debris_composition',
    service: 'soil_types',
    filterValue: '20000;29999',
    noComma: true,
    titleKey: 'title_gen'
  },
  { id: 'debris_comments' },
  { id: 'strength', service: 'strengths', titleEnd: 'end3' },
  { id: 'strength_comments' }
]

const debrisAttrs = ['debris_round', 'debris_composition', 'strength']
