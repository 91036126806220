<script setup>
import DataMapEditor from '@/modules/app/data/maps/data-map-editor/data-map-editor.vue'
import DataItemIcon from '@/components/s-data-item/s-data-item-icon.vue'
import { computed } from 'vue'

const props = defineProps({
  center: {
    type: Array,
    default: () => null
  },
  source: {
    type: Object,
    default: () => null
  },
  onlyPlan: {
    type: Boolean,
    default: false
  },
  template: {
    type: Object,
    default: () => null
  }
})

defineEmits(['update-location'])

const type = computed(() => {
  return props?.source?.type || props.template?.type
})
</script>

<template>
  <div class="map-location-editor">
    <s-text> Перемещайте карту для редактирования местоположения </s-text>
    <div class="map-location-editor__map">
      <data-map-editor
        :center="center"
        :source="source"
        @center-handler="$emit('update-location', $event)"
      />

      <div class="map-location-editor__map-icon">
        <data-item-icon :type="type" size="small" color="caption" />
      </div>
    </div>
    <div class="map-location-editor__symbols">
      <s-text>Условные обозначения</s-text>
      <div class="map-location-editor__symbols-list">
        <div v-if="!onlyPlan" class="map-location-editor__symbols-item">
          <data-item-icon :type="type" size="small" color="caption" />
          <s-text>
            Фактическое,
            <span class="map-location-editor__symbols-format">WGS-84</span>
          </s-text>
        </div>
        <div class="map-location-editor__symbols-item">
          <data-item-icon :type="type" size="small" color="caption" :transparent="!onlyPlan" />
          <s-text>
            Планируемое,
            <span class="map-location-editor__symbols-format">WGS-84</span>
          </s-text>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.map-location-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  &__map {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-bg);
    overflow: hidden;
    min-height: 260px;
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__symbols {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-list {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1.5rem;
      justify-content: start;
    }

    &-item {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      grid-gap: 0.5rem;
    }

    &-format {
      white-space: nowrap;
    }
  }
}
</style>
