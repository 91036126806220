import { BASE_URL } from '@/configs/urls'
import { useAuthStore, useSyncStore } from '@/stores'

const fileSize = {
  large: 1024,
  medium: 512,
  small: 128
}

export const generateFile = (type = 'medium') => {
  const sizeInBytes = fileSize[type] * 1024
  const text = 'a'.repeat(sizeInBytes)
  const blob = new Blob([text], { type: 'text/plain' })

  return new File([blob], 'test-file.txt', { type: 'text/plain' })
}

export const getMeasureInternetSpeed = async (fileSizeType, minSpeed = 30) => {
  const authStore = useAuthStore()
  const syncStore = useSyncStore()

  const file = generateFile(fileSizeType)
  const formData = new FormData()

  const startTime = new Date().getTime()

  formData.append('file', file)

  try {
    const response = await fetch(`${BASE_URL}/api/test/upload/`, {
      body: formData,
      method: 'POST',
      headers: {
        Authorization: `Token ${authStore?.userToken}`
      }
    }).then((response) => response.json())

    const endTime = new Date().getTime()
    const durationInSeconds = (endTime - startTime) / 1000

    const fileSizeInKB = file.size / 1024
    const speedInKBps = (fileSizeInKB / durationInSeconds).toFixed(2)

    syncStore.setField('internetSpeed', speedInKBps)

    if (response) {
      if (speedInKBps < minSpeed) {
        throw new Error(`Недостаточная скорость интернета: ${speedInKBps} KB/s`)
      }
    }
  } catch (e) {
    throw new Error(`Недостаточная скорость интернета`)
  }
}
