import { getWaterService, getFromService, getSoilSource } from './index'

export const getInterlayerTitle = (item) => {
  const { d0, d1 } = item

  if ((d0 || d0 === 0) && d1) {
    return `от ${d0} до ${d1} м`
  } else if (d0) {
    return `с ${d0} м`
  } else if (d1) {
    return `до ${d1} м`
  } else {
    return ''
  }
}

export const getInterlayerText = (item, services, source) => {
  const frozen = source.state === 2
  const { type, composition, water_saturation, comments } = item
  let typeName = ''
  let compositionName = ''
  let waterName = ''

  if (type) {
    typeName = services?.interlayers_types?.find((e) => e.id === type)?.title
  }

  if (composition) {
    const currentItem = services?.soil_types?.find((e) => e.id === composition) || null
    compositionName = currentItem?.title_gen || ''
  }

  if (!frozen) {
    if (water_saturation) {
      const serviceName = getWaterService(composition)
      const currentService = services?.[serviceName]

      if (currentService) {
        const currentItem = currentService?.find((w) => w.id === water_saturation) || null
        if (!currentItem) return ''
        const { title } = currentItem
        if (title === 'маловлажн' || title === 'влажн' || title === 'водонасыщенн') {
          waterName = `${title}ого`
        } else if (title === 'водонасыщенный') {
          waterName = 'водонасыщенного'
        } else {
          waterName = title
        }
      }
    }
  } else {
    const soilSource = getSoilSource({
      soil_type: item.composition,
      state: 2
    })

    if (soilSource) {
      const attrs = [
        { id: 'state_pfrost', service: 'states_pfrost' },
        {
          id: 'water_saturation',
          service: pFrostServiceName({ soil_type: item.composition })
        }
      ]

      const attrsItem = attrs.map((e) => {
        e.titleKey = 'title_short'

        const info = getFromService(soilSource, item, e, services)
        if (e.id === 'water_saturation') {
          return info ? `при оттаивании ${info}` : ''
        } else {
          return info
        }
      })

      const filteredAttrsItems = attrsItem.filter((e) => !!e)

      if (filteredAttrsItems?.length) {
        filteredAttrsItems.forEach((e) => {
          waterName += `, ${e}`
        })
      }
    }
  }

  let text = ''

  if (typeName) {
    text += ` ${typeName}`
  }
  if (compositionName) {
    text += ` ${compositionName}`
  }

  if (waterName) {
    text += ` ${waterName}`
  }

  if (comments) {
    text += `, ${comments?.toLowerCase()}`
  }

  text = text.replaceAll(' ,', ',')

  return text ? text?.toLowerCase() : '-'
}

const pFrostServiceName = (data) => {
  if (data?.soil_type >= 11201 && data?.soil_type <= 11205) {
    return 'water_saturations_sand'
  } else if (data?.soil_type >= 12102 && data?.soil_type <= 12103) {
    return 'water_saturations_clay'
  } else if (data?.soil_type === 12101) {
    return 'water_saturations_sandy_clay'
  } else {
    return 'water_saturations_other'
  }
}
