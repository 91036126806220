<script setup>
import { computed } from 'vue'
import { useRitmDate } from '@/composables'
import { usePrintStore } from '@/stores'

const rDate = useRitmDate()
const printStore = usePrintStore()

const activeObject = computed(() => {
  return printStore.dashboard.active
})

const today = computed(() => {
  return rDate(new Date()).format('DD.MM.YYYY')
})
</script>

<template>
  <div class="dp-header">
    <s-title type="title">{{ activeObject?.title_short }}</s-title>
    <s-title type="title">{{ today }}</s-title>
  </div>
</template>

<style lang="scss">
.dp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
