<script setup>
import { useMq } from 'vue3-mq'
import { ref } from 'vue'

import importData from './import/import-data.vue'
import ExportData from './/export/export-data.vue'

defineProps({
  title: {
    type: String,
    default: null
  },
  backButton: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['back'])

const mq = useMq()

const exportModal = ref(false)
const importModal = ref(false)

const closeModal = (type) => {
  if (type === 'export') {
    exportModal.value = !exportModal.value
    return
  }

  importModal.value = !importModal.value
}
</script>

<template>
  <div class="object-header">
    <div class="object-header-content">
      <div class="object-header-content__left-part">
        <s-button
          v-if="backButton"
          icon="chevron-left"
          simple
          class="object-header-content__back-button"
          @click="emits('back')"
        />
        <s-title v-if="title" class="object-header-content__title">
          {{ title }}
        </s-title>
      </div>
      <div v-if="mq.current === 'lg'" class="object-header-content__actions">
        <s-button @click="importModal = true"> Импорт из файла </s-button>
        <s-button class="primary" @click="exportModal = true"> Экспорт данных </s-button>
      </div>
    </div>

    <import-data v-if="importModal" :is-visible="importModal" @close="closeModal('import')" />

    <export-data v-if="exportModal" :is-visible="exportModal" @close="closeModal('export')" />
  </div>
</template>

<style lang="scss">
.object-header {
  position: relative;
  display: grid;
  grid-gap: 2rem;
  padding: 16px;
  padding-right: 24px;
  height: 100%;
  overflow: hidden;
  align-content: start;
  border-bottom: 1px solid var(--bg);
  background: var(--card-bg);

  @include phones {
    border-bottom: none;
    padding: 16px 0 8px 0;
  }

  &-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    min-height: 40px;

    &__left-part {
      display: flex;
      align-items: center;
      position: relative;
      gap: 8px;

      @include phones {
        width: 100%;
      }
    }

    &__title {
      @include phones {
        flex-grow: 1;
        justify-self: center;
      }
    }

    &__actions {
      display: flex;
      gap: 16px;
    }
  }
}

.light-theme {
  &.lg {
    & .object-header {
      border-bottom: 1px solid var(--main-bg);
    }
  }
}

@include phones {
  .object-header {
    &-content {
      justify-content: space-between;
      padding: 0;

      &__back-button {
        position: absolute;
        top: auto;
        left: 16px;
      }
    }
  }
}
</style>
