export const largeScaleSteps = {
  main: [
    {
      id: 'water_saturation',
      value: 'water_saturation',
      title: 'Укажите степень водонасыщения',
      type: 'select',
      service: 'water_saturations_sand',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к степени водонасыщения'
    },
    {
      id: 'filler',
      title: 'Укажите информацию о заполнителе',
      type: 'group-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Заполнитель',
        description:
          'В крупнообломочных грунтах необходимо указывать вид и процентное содержание заполнителя. При наличии в крупнообломочных грунтах песчаного заполнителя более 40 % или глинистого заполнителя более 30 % общей массы воздушно-сухого грунта в наименование крупнообломочного грунта включают наименование вида заполнителя и указывают характеристики его состояния (влажность, плотность, показатель текучести). Вид заполнителя устанавливают после удаления из крупнообломочного грунта частиц крупнее 2 мм.',
        source:
          'ГОСТ 25100. Грунты. Классификация. НИИОСП им. Н.М. Герсеванова» — АО «НИЦ «Строительство», 2020'
      },
      groups: [
        {
          id: 'filler_type',
          value: 'filler_type',
          title: 'Вид грунта',
          type: 'select',
          service: 'fillers_types'
        },
        {
          id: 'filler_amount',
          value: 'filler_amount',
          required: 'filler_type',
          title: 'Количество',
          type: 'select',
          service: 'fillers_amounts'
        },
        {
          id: 'filler_water_saturation',
          value: 'filler_water_saturation',
          required: 'filler_amount',
          title: 'Состояние',
          type: 'select',
          service: 'filler_saturations'
        }
      ],
      commentField: 'filler_comments',
      commentTitle: 'Комментарий к заполнителю'
    },
    {
      id: 'color',
      value: 'color',
      title: 'Укажите цвет грунта',
      type: 'color-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Цвет грунта',
        description:
          'Цвет грунта описывают в естественном состоянии, при полном дневном освещении. Допускается описание цвета при искусственном освещении с использованием ламп дневного света или при любом освещении с применением сравнительного образца или эталона, цветовых шкал. Для объективного описания цветов рекомендуется пользоваться цветовыми шкалами (палетками), например выполненными по системе Манселла, или другими системами, специализированными для определения цвета грунтов (например, цветовая таблица грунтов S133N). Цвет скального грунта описывают во влажном (смоченном) состоянии. Если необходимо, цве­товые различия могут быть уточнены использованием таких терминов, как «пестрый», «пятнистый», «испещренный», «крапчатый», «полосчатый».',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'text_thaw',
      value: 'text_thaw',
      title: 'Укажите текстуру грунта',
      type: 'select',
      service: 'texts_thaw',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к текстуре',
      additionalTrigger: true,
      createTrigger: true
    }
  ],
  additional: [
    {
      id: 'debris',
      type: 'group-select',
      groups: [
        {
          title: 'Укажите степень окатанности обломков',
          id: 'debris_round',
          value: 'debris_round',
          type: 'select',
          service: 'debris_rounds'
        },
        {
          title: 'Укажите состав обломков',
          id: 'debris_composition',
          value: 'debris_composition',
          type: 'select',
          search: true,
          service: 'soil_types',
          filterValue: '20000;29999'
        }
      ],
      commentField: 'debris_comments',
      commentTitle: 'Комментарий к составу обломков'
    },
    {
      id: 'strength',
      value: 'strength',
      title: 'Укажите прочность крупных обломков',
      type: 'select',
      service: 'strengths',
      commentField: 'strength_comments',
      commentTitle: 'Комментарий к прочности обломков'
    },
    {
      id: 'inclusions',
      value: 'inclusions',
      title: 'Включения',
      type: 'inclusions'
    },
    {
      id: 'interlayers',
      value: 'interlayers',
      title: 'Прослои',
      type: 'interlayers'
    },
    {
      id: 'additional',
      title: 'Укажите дополнительную информацию',
      type: 'additional',
      createTrigger: true
    }
  ]
}
