<script setup>
import { useRitmDate } from '@/composables'
import { useMq } from 'vue3-mq'
import GroundwaterType from './groundwater-type.vue'

defineProps({
  no: {
    type: Number,
    required: true
  },
  groundwater: {
    type: Object,
    required: true
  }
})

const mq = useMq()
const rDate = useRitmDate()

const getDate = (date) => {
  if (mq.current === 'sm') {
    return date ? rDate(date).format('DD.MM.YY') : '-'
  }

  return date ? rDate(date).format('DD.MM.YY HH:mm') : '-'
}
</script>

<template>
  <div class="groundwater-list__item">
    <s-text>{{ no }}</s-text>
    <div class="groundwater-list__item-col">
      <groundwater-type />
      <s-text type="info">
        {{ groundwater.level_d?.toFixed(1) }}
      </s-text>
      <s-text class="groundwater-list__item-appear-date">
        {{ getDate(groundwater.appear_date) }}
      </s-text>
    </div>
    <div v-if="groundwater.fixed_date" class="groundwater-list__item-col">
      <groundwater-type fixed />
      <s-text type="info">
        {{ groundwater.fixed_d?.toFixed(1) }}
      </s-text>
      <s-text class="groundwater-list__item-fixed-date">
        {{ getDate(groundwater.fixed_date) }}
      </s-text>
    </div>
  </div>
</template>

<style lang="scss">
.groundwater-list__item {
  display: grid;
  grid-template-columns: 1rem 1fr 1fr;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  grid-gap: 2rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  background-color: var(--bg);
  cursor: pointer;

  &-col {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;
    align-items: center;
  }

  &-col {
    @media (max-width: 480px) {
      display: flex;
      row-gap: 4px;
      flex-wrap: wrap;
    }
  }
}
</style>
