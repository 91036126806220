import debounce from 'lodash.debounce'
import db from '@/libs/db'
import { useMainStore } from '@/stores'
import { useRitmDate, useRequests } from '@/composables'
import { getExcavationsChanges } from '@/utils'
import cloneDeep from 'lodash.clonedeep'
import appGlobalProperties from '@/main'

export const checkExcavationDepth = debounce((soilsList, excavation, action = 'update') => {
  if (!excavation) throw new Error('Данные по скважине отсутствуют')

  const excavDepth = excavation.h || 0
  let maxH = 0

  soilsList.forEach((e) => {
    if (maxH < e?.foot_d) {
      maxH = e?.foot_d
    }
  })

  if (maxH !== excavDepth) {
    saveExcavationH(excavation, maxH, action)
  }
}, 256)

const saveExcavationH = async (excavation, h, action) => {
  const mainStore = useMainStore()
  const rDate = useRitmDate()
  const { patchRequest, postRequest } = useRequests()

  try {
    const { server_id, id } = excavation

    const date_front = rDate(new Date()).format('iso')
    const delta_value = h - (excavation.h || 0)

    const logData = {
      client_id: rDate(date_front).format('x'),
      current_height: h,
      date_front,
      delta_value,
      action
    }

    if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
      await patchRequest(`excavations/${server_id}/`, {
        date_front,
        h
      })

      await postRequest(`excavations/${server_id}/log/`, logData)
    } else {
      logData.excavation_id = server_id || `idb_${id}`
      await db.log.add(logData)
    }

    if ((!mainStore.isOnline || mainStore.noSyncMode) && server_id) {
      const updatedItems = await db.updated.where('table').equals('excavations').toArray()
      const inUpdated = !!updatedItems.find((e) => e.item_id === server_id)
      const createdItems = await db.created.where('table').equals('excavations').toArray()
      const inCreated = !!createdItems.find((e) => e.item_id === server_id)

      if (!inUpdated && !inCreated) {
        await db.updated.put({
          table: 'excavations',
          item_id: server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    excavation.h = h

    const clone = cloneDeep(excavation)

    await db.excavations.put(clone)
  } catch (e) {
    const message = `Произошла ошибка при сохранении глубины скважины. ${e}`
    appGlobalProperties.$notify({
      message,
      type: 'error'
    })
  }
}
