<script setup>
import { computed } from 'vue'

const props = defineProps({
  settings: {
    type: Object,
    required: true
  }
})

const styleSettings = computed(() => {
  const { x, y } = props.settings

  return {
    left: `${x}px`,
    top: `${y}px`
  }
})
</script>

<template>
  <div :style="styleSettings" class="map-popup">
    <s-title type="small">{{ settings.title }}</s-title>
    <ul class="map-popup__list">
      <li v-for="row in settings.description" :key="row" class="map-popup__item">
        <s-text>{{ row }}</s-text>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.map-popup {
  position: absolute;
  padding: 12px;
  border: 1px solid var(--bg);
  background-color: var(--secondary-bg);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 144px;
  z-index: 10;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
</style>
