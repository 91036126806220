<script setup>
import { computed, ref } from 'vue'
import { useObjectsStore } from '@/stores'
import { useRitmDate } from '@/composables'
import { excavationStatuses, excavationStatusesColors } from '@/configs/info'
import itemCell from './item-cell.vue'

const ritmDate = useRitmDate()

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  config: {
    type: Array,
    required: true
  }
})

const objectsStore = useObjectsStore()

const sourceTemplate = ref(props.source)

const status = computed(() => {
  const { status } = props.source

  return {
    value: excavationStatuses[status],
    flag: excavationStatusesColors[status]
  }
})

const date = computed(() => {
  const { date } = props.source

  return ritmDate(date).format('DD.MM.YYYY')
})

const getGeologistTitleByID = (id) => {
  const geologist = objectsStore.activeObject.actual_geologists.find((user) => user.id === id)
  if (!geologist) return '—'
  return geologist.get_short_name || geologist.username
}
</script>

<template>
  <li class="e-list-item">
    <item-cell
      :width="item.width"
      :center="item.center"
      :stretched="item.stretched"
      v-for="(item, index) in config"
      :key="index"
    >
      <s-checkbox
        v-if="item.type === 'checkbox'"
        v-model="sourceTemplate.checked"
        :checked="sourceTemplate.checked"
      />

      <s-title v-if="item.type === 'title'" type="small">
        {{ item.titleType === 'excavation' ? 'Скв.' : 'ТН' }} {{ source.title }}
      </s-title>

      <s-text
        v-if="item.type === 'status'"
        :class="`e-list-item__status e-list-item__status--${status.flag}`"
      >
        {{ status.value }}
      </s-text>

      <s-text v-if="item.type === 'h'" type="secondary">
        {{ source.h }} / {{ source.h_plan }}
      </s-text>

      <s-text v-if="item.type === 'date'" type="secondary" small>
        {{ date }}
      </s-text>

      <s-text v-if="item.type === 'geologist'" type="secondary" small>
        {{ getGeologistTitleByID(source.geologist) }}
      </s-text>

      <s-text v-if="item.type === 'site'" type="secondary" small>
        {{ source.site }}
      </s-text>
    </item-cell>
  </li>
</template>

<style lang="scss">
.e-list-item {
  display: flex;
  align-items: center;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--bg);
  }

  &__status {
    &--orange {
      color: var(--orange) !important;
    }

    &--primary {
      color: var(--primary) !important;
    }

    &--success {
      color: var(--success) !important;
    }
  }
}
</style>
