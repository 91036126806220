<script setup>
import { useSlots } from 'vue'

defineProps({
  leftPanelMaxWidth: {
    type: String,
    required: false,
    default: ''
  },
  leftPanelWidth: {
    type: String,
    required: false,
    default: ''
  }
})

const slots = useSlots()

const hasHeader = () => {
  return slots.header
}

const hasMap = () => {
  return slots.map
}
</script>

<template>
  <div class="data-desktop-layout">
    <div v-if="hasHeader()">
      <slot name="header" />
    </div>
    <div class="data">
      <div
        class="main-panel"
        :style="{
          'max-width': leftPanelMaxWidth,
          width: leftPanelWidth
        }"
      >
        <slot />
      </div>
      <slot v-if="hasMap()" name="map" />
    </div>
  </div>
</template>

<style lang="scss">
.data-desktop-layout {
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;

  & .data {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 100%;

    &__content-wrapper {
      display: flex;
      flex: 1;
    }
  }
}

.main-panel {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--card-bg);
}
</style>
