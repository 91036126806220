export class KalmanFilter {
  constructor(R, Q, A, B, C) {
    this.R = R // Noise variance
    this.Q = Q // Process variance
    this.A = A || 1 // Factor of real value to previous real value
    this.B = B || 0 // Factor of real value to control input
    this.C = C || 1 // Factor of measured value to real value

    this.cov = NaN
    this.x = NaN // Estimated signal without noise
  }

  filter(z, u = 0) {
    if (isNaN(this.x)) {
      this.x = (1 / this.C) * z
      this.cov = (1 / this.C) * this.Q * (1 / this.C)
    } else {
      const predX = this.A * this.x + this.B * u
      const predCov = this.A * this.cov * this.A + this.R

      const K = predCov * this.C * (1 / (this.C * predCov * this.C + this.Q))

      this.x = predX + K * (z - this.C * predX)
      this.cov = predCov - K * this.C * predCov
    }

    return this.x
  }
}

export class MovingAverage {
  constructor(period) {
      this.period = period;
      this.values = [];
  }

  addValue(value) {
      this.values.push(value);
      if (this.values.length > this.period) {
          this.values.shift();
      }
      return this.getAverage();
  }

  getAverage() {
      const sum = this.values.reduce((a, b) => a + b, 0);
      return sum / this.values.length;
  }
}