// excavation icons
import excavationDarkUrl from '@/assets/icons/map/excavation-dark-theme.svg'
import excavationLightUrl from '@/assets/icons/map/excavation-light-theme.svg'
import excavationSateliteUrl from '@/assets/icons/map/excavation-satelite.svg'
import excavationOutdoorsUrl from '@/assets/icons/map/excavation-outdoors.svg'
import excavationCompletedUrl from '@/assets/icons/map/excavation-completed.svg'
import excavationWorkingUrl from '@/assets/icons/map/excavation-working.svg'
import excavationPrepareUrl from '@/assets/icons/map/excavation-prepare.svg'

// recon items
import reconDarkUrl from '@/assets/icons/map/recon-dark-theme.svg'
import reconLightUrl from '@/assets/icons/map/recon-light-theme.svg'
import reconSateliteUrl from '@/assets/icons/map/recon-satelite.svg'
import reconOutdoorsUrl from '@/assets/icons/map/recon-outdoors.svg'
import reconCompletedUrl from '@/assets/icons/map/recon-completed.svg'

// processes items
import processesDarkUrl from '@/assets/icons/map/processes-dark-theme.svg'
import processesLightUrl from '@/assets/icons/map/processes-light-theme.svg'
import processesSateliteUrl from '@/assets/icons/map/processes-satelite.svg'
import processesOutdoorsUrl from '@/assets/icons/map/processes-outdoors.svg'

const icons = [
  { name: 'excavation-dark-theme', imageUrl: excavationDarkUrl },
  { name: 'excavation-light-theme', imageUrl: excavationLightUrl },
  { name: 'excavation-satelite', imageUrl: excavationSateliteUrl },
  { name: 'excavation-outdoors', imageUrl: excavationOutdoorsUrl },
  { name: 'excavation-completed', imageUrl: excavationCompletedUrl },
  { name: 'excavation-working', imageUrl: excavationWorkingUrl },
  { name: 'excavation-prepare', imageUrl: excavationPrepareUrl },
  { name: 'recon-dark-theme', imageUrl: reconDarkUrl },
  { name: 'recon-light-theme', imageUrl: reconLightUrl },
  { name: 'recon-satelite', imageUrl: reconSateliteUrl },
  { name: 'recon-outdoors', imageUrl: reconOutdoorsUrl },
  { name: 'recon-completed', imageUrl: reconCompletedUrl },
  { name: 'processes-dark-theme', imageUrl: processesDarkUrl },
  { name: 'processes-light-theme', imageUrl: processesLightUrl },
  { name: 'processes-satelite', imageUrl: processesSateliteUrl },
  { name: 'processes-outdoors', imageUrl: processesOutdoorsUrl }
]

export const loadImages = (mapgl) => {
  return Promise.all(
    icons.map(
      ({ name, imageUrl }) =>
        new Promise((resolve) => {
          const url = imageUrl
          const image = new Image(32, 32)

          image.crossOrigin = 'Anonymous'
          image.style.backgroundSize = '100%'
          image.style.backgroundPosition = 'center'

          image.addEventListener('load', () => {
            if (!mapgl.hasImage(name)) {
              mapgl.addImage(name, image)
            }
            resolve()
          })

          image.addEventListener('error', () => {
            console.log('map icons not loaded')
          })

          image.src = url
        })
    )
  ).then(() => console.log('map icons loaded!'))
}
