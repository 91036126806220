import { useMapStore } from '@/stores'
import { getUserLocation } from '@/utils'
import { innerCircleConfig, outerCircleConfig } from '../config'

export class LocationController {
  constructor(mapgl) {
    const mapStore = useMapStore()

    this.$store = mapStore
    this.mapgl = mapgl
    this.layerId = 'my-location'
  }

  async showLocation(type) {
    if (!this.$store.userLocation) {
      await getUserLocation()
    }

    this.setLocationOnMap(type)
  }

  setLocationOnMap (type) {
    if (!this.mapgl.isStyleLoaded()) return

    const coordinates = this.$store.userLocation

    if (!coordinates) return

    const data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates
          }
        }
      ]
    }
    const source = this.mapgl.getSource(this.layerId)

    if (source) {
      source.setData(data)
    } else {
      this.mapgl.addSource(this.layerId, {
        type: 'geojson',
        data
      })
      this.mapgl.addLayer({
        id: `${this.layerId}-inner`,
        source: this.layerId,
        ...outerCircleConfig
      })
      this.mapgl.addLayer({
        id: `${this.layerId}-outer`,
        source: this.layerId,
        ...innerCircleConfig
      })
    }

    if (type === 'with-fly-to') {
      this.mapgl.setZoom(17)
      this.mapgl.setCenter(coordinates)
    }
  }
}
