<script setup>
import { computed } from 'vue'
import { formatStringArray } from '@/utils'

const props = defineProps({
  object: {
    type: Object,
    default: () => null
  }
})

const fields = ['geologists', 'bore_masters', 'bore_machines']

const fieldsMap = {
  geologists: 'Пользователи',
  bore_masters: 'Буровые мастера',
  bore_machines: 'Буровые установки'
}

const message = computed(() => {
  if (!props.object) return

  const arrOfEmptyData = []

  fields.forEach((field) => {
    if (!props.object[field].length) {
      arrOfEmptyData.push(fieldsMap[field])
    }
  })

  if (!arrOfEmptyData.length) {
    return false
  }

  return formatStringArray(arrOfEmptyData)
})
</script>

<template>
  <s-alert v-if="message" class="object-data-tip" icon="circle-info" type="primary">
    <s-text>
      В проекте отсутствуют {{ message }}. <br />
      Вы можете добавить их в
      <router-link class="object-data-tip__link" :to="'./info'">
        общей информации по проекту
      </router-link>
    </s-text>
  </s-alert>
</template>

<style lang="scss">
.object-data-tip {
  max-width: 100%;

  &__link {
    color: var(--primary);
  }

  &__icon {
    margin-left: 4px;
  }
}
</style>
