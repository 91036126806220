<script setup>
import { computed } from 'vue'
import { statuses } from './configs'

import ProgressBar from './components/widget-progress-bar.vue'
import WidgetList from './components/widget-list.vue'

const props = defineProps({
  stats: {
    type: Object,
    default: () => null
  },
  type: {
    type: String,
    default: 'excavations'
  },
  card: {
    type: Boolean,
    default: true
  }
})

const titles = {
  excavations: 'Скважины',
  recon: 'Точки наблюдения'
}

const statsSum = computed(() => {
  if (!props.stats) return 0

  return statuses[props.type]
    .map((status) => status.id)
    .reduce((prev, item) => {
      return prev + (props.stats[item]?.count || 0)
    }, 0)
})
</script>

<template>
  <div :class="`data-widget ${card ? 'data-widget--card' : ''}`">
    <div class="data-widget__header">
      <s-title type="small"> {{ titles[type] }} </s-title>
    </div>
    <template v-if="stats">
      <div v-if="!!statsSum" class="data-widget__progress">
        <progress-bar :stats="stats" :sum="statsSum" :statuses="statuses[type]" />
      </div>
      <widget-list :stats="stats" :statuses="statuses[type]" :type="type" />
    </template>
  </div>
</template>

<style lang="scss">
.data-widget {
  &--card {
    width: 300px;
    padding: 16px;
    border: 1px solid var(--section-border-color);
    background-color: var(--card-bg);
    border-radius: 8px;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__progress {
    margin-bottom: 16px;

    &-mock {
      height: 24px;
      border-radius: 8px;
      background-color: var(--bg);
      border: 1px solid var(--main-bg);
      opacity: 0.75;
    }
  }
}
</style>
