import posthog from 'posthog-js'
import { API_HOST, POSTHOG_TOKEN } from '@/configs/posthog'

const { MODE } = import.meta.env

export default {
  install(app) {
    if (MODE === 'development') return

    app.config.globalProperties.$posthog = posthog.init(POSTHOG_TOKEN, {
      api_host: API_HOST
    })
  }
}
