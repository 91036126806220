export const interlayersTemplate = {
  d0: null,
  d1: null,
  type: null,
  composition: null,
  state_pfrost: null,
  water_saturation: null,
  comments: '',
  date: null,
  date_front: null
}
