import { getFromService } from './index'

export const getIceDescription = (parent, data) => {
  let soilDescription = parent?.soilName

  if (parent?.soilSource) {
    const items = attrs.map(e => {
      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredItems = items.filter(e => e)

    if (filteredItems?.length) {
      filteredItems.forEach(e => {
        soilDescription += `, ${e}`
      })
    }
  }

  parentAttrs.forEach(a => {
    if (parent[a]) {
      soilDescription += `, ${parent[a]?.toLowerCase()}`
    }
  })

  return soilDescription
}

const attrs = [{ id: 'ice_type', service: 'ice_types' }]

const parentAttrs = ['comment', 'color', 'colorComment']
