<script setup>
import itemCell from '@/modules/print/dashboard/components/item-cell.vue'

defineProps({
  chartsData: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const calculateDeviation = (planned, fact) => {
  const result = {}

  if (planned < fact) {
    result.total = `${(planned - fact).toFixed(2)}`
    result.isNegative = true
  }

  if (fact < planned) {
    result.total = `+${(planned - fact).toFixed(2)}`
    result.isPositive = true
  }

  return result
}
</script>

<template>
  <li class="dp-list-item">
    <item-cell :width="72" center>
      <s-text> {{ index + 1 }} </s-text>
    </item-cell>
    <item-cell stretched center>
      <s-text> {{ chartsData.date }} </s-text>
    </item-cell>

    <item-cell stretched center>
      <s-text> {{ Number(chartsData.planned_production).toFixed(2) }} </s-text>
    </item-cell>

    <item-cell stretched center>
      <s-text> {{ Number(chartsData.production).toFixed(2) }} </s-text>
    </item-cell>

    <item-cell stretched center>
      <s-text
        :class="{
          positive: calculateDeviation(chartsData.planned_production, chartsData.production)
            .isPositive,
          negative: calculateDeviation(chartsData.planned_production, chartsData.production)
            .isNegative
        }"
      >
        {{ calculateDeviation(chartsData.planned_production, chartsData.production).total }}
      </s-text>
    </item-cell>
  </li>
</template>

<style lang="scss">
.dp-list-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--main-bg);

  & .negative {
    color: var(--error) !important;
  }

  & .positive {
    color: var(--success) !important;
  }

  & .d-item-cell:last-child {
    & .s-text {
      font-weight: 700 !important;
    }
  }
}
</style>
