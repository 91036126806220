import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Vue3Mq } from 'vue3-mq'
import axios from 'axios'

import App from './App.vue'
import router from './router'

import db from '@/libs/db'
import useSoilboxUI from './libs/soilbox-ui'
import posthog from '@/libs/posthog'
import initAppAndMonitoring from '@/libs/init'

import { getDataFromLS } from '@/utils'

import './assets/styles/main.scss'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(Vue3Mq, {
  breakpoints: {
    sm: 0,
    md: 640,
    lg: 1024
  },
  defaultBreakpoint: 'lg'
})

app.use(posthog)
initAppAndMonitoring(app)

useSoilboxUI(app)

// set auth token
const token = getDataFromLS('userToken')

if (token) {
  axios.defaults.headers.common.Authorization = `Token ${token}`
}

app.mount('#app')

const appGlobalProperties = app.config.globalProperties
appGlobalProperties.$db = db

export default appGlobalProperties
