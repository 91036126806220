const pointsID = 's-ruler-points'
const linesID = 's-ruler-lines'

import { createApp } from 'vue'
import mapboxgl from 'mapbox-gl'
import length from '@turf/length'
import { useRulerStore } from '@/stores'
import { lineString } from '@turf/helpers'
import { pointsConfig, linesConfig } from '../../../config/point.js'
import mapPopup from '../map-popup.vue'

export class ViewController {
  constructor(mapgl) {
    this.mapgl = mapgl
    this.currentPopup = null
  }

  clearMapAssets() {
    const rulerStore = useRulerStore()

    rulerStore.clearPoints()
    rulerStore.setLastPoint(null)
    this.mapgl.off('click')

    const existPointsLayer = this.mapgl.getLayer(pointsID)
    if (existPointsLayer) {
      this.mapgl.removeLayer(pointsID)
      this.mapgl.removeSource(pointsID)
    }

    const existLinesLayer = this.mapgl.getLayer(linesID)
    if (existLinesLayer) {
      this.mapgl.removeLayer(linesID)
      this.mapgl.removeSource(linesID)
    }

    if (this.currentPopup) {
      this.currentPopup.remove()
    }
  }

  addPoints() {
    this.mapgl.addSource(pointsID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.mapgl.addLayer({
      id: pointsID,
      source: pointsID,
      ...pointsConfig
    })
  }

  updatePoints() {
    const rulerStore = useRulerStore()

    const features = rulerStore.points.map((e) => {
      const { lng, lat } = e.lngLat
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        }
      }
    })

    const source = this.mapgl.getSource(pointsID)
    source.setData({
      type: 'FeatureCollection',
      features
    })
  }

  addLines() {
    this.mapgl.addSource(linesID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.mapgl.addLayer({
      id: linesID,
      source: linesID,
      ...linesConfig
    })
  }

  updateLines() {
    const rulerStore = useRulerStore()

    const coords = rulerStore.points.map((e) => {
      const { lng, lat } = e.lngLat
      return [lng, lat]
    })
    const source = this.mapgl.getSource(linesID)

    if (coords.length < 2) {
      source.setData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      })

      return
    }

    this.calculateDistance(coords)

    source.setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coords
      }
    })
  }

  calculateDistance(coords) {
    const rulerStore = useRulerStore()

    const line = lineString(coords)

    const totalLength = length(line, { units: 'meters' })
    rulerStore.setTotalDistance(totalLength)
  }

  showPopup(e) {
    const popupElement = document.createElement('div')

    const popupApp = createApp(mapPopup)

    popupApp.mount(popupElement)

    if (this.currentPopup) {
      this.currentPopup.remove()
    }

    this.currentPopup = new mapboxgl.Popup({ offset: 25, closeButton: false })
      .setLngLat([e.lngLat.lng, e.lngLat.lat])
      .setDOMContent(popupElement)
      .addTo(this.mapgl)
  }

  addClickHandler() {
    const rulerStore = useRulerStore()

    this.mapgl.on('click', (e) => {
      if (rulerStore.isRulerOn) {
        rulerStore.pushPointToPoints({ lngLat: e.lngLat, x: e.point.x, y: e.point.y })
        rulerStore.setLastPoint(e.point)

        if (!this.mapgl.getSource(linesID)) {
          this.addLines()
        }

        if (!this.mapgl.getSource(pointsID)) {
          this.addPoints()
        }

        if (rulerStore.points.length > 1) {
          this.showPopup(e)
          this.updateLines()
        }

        if (this.mapgl.getSource(pointsID)) {
          this.updatePoints()
        }
      }
    })
  }
}
