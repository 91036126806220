<script setup>
import { computed } from 'vue'
import ItemCell from './item-cell.vue'

defineProps({
  mode: {
    type: String,
    default: 'dashboard'
  }
})

const config = {
  id: {
    id: 'title',
    width: 72,
    label: '№пп',
    center: true
  },
  date: { id: 'date', stretched: true, label: 'Дата', center: true },
  plan: { id: 'plan', stretched: true, label: 'Остаток (план), п.м.', center: true },
  fact: { id: 'plan', stretched: true, label: 'Остаток (факт), п.м.', center: true },
  deviations: { id: 'deviations', stretched: true, label: 'Отклонение, п.м.', center: true }
}

const items = {
  print: ['id', 'date', 'plan', 'fact', 'deviations']
}

const filteredItems = computed(() => {
  return items.print.map((id) => config[id])
})
</script>

<template>
  <div class="d-list-header">
    <item-cell
      v-for="cell in filteredItems"
      :key="cell.id"
      :width="cell.width"
      :stretched="cell.stretched"
      :center="cell.center"
    >
      <s-text type="secondary">
        {{ cell.label }}
      </s-text>
    </item-cell>
  </div>
</template>

<style lang="scss">
.d-list-header {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
</style>
