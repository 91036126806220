import extent from 'turf-extent'
import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'

export * from './data-points'
export * from './data-clusters'
export * from './images'
export * from './location'
export * from './objects'
export * from './objects-clusters'

export const getGeojson = array => {
  return {
    type: 'FeatureCollection',
    features: array.map(item => ({
      type: 'Feature',
      properties: {
        ...item
      },
      geometry: {
        type: 'Point',
        coordinates: [item.y, item.x]
      }
    }))
  }
}

export const isLngLatValid = coords => {
  const [lng, lat] = coords

  return (
    isFinite(lng) &&
    Math.abs(lng) <= 180 &&
    isFinite(lat) &&
    Math.abs(lat) <= 90
  )
}

export const getPaddingSettings = () => {
  return {
    top: 48,
    bottom: 48,
    left: 48,
    right: 48
  }
}

export const flyToFeatures = (mapgl, data) => {
  const { length } = data.features

  if (!mapgl) return
  if (!length) return

  const geometry = bboxPolygon(bbox(data)).geometry
  const bounds = extent(geometry)
  const padding = getPaddingSettings()

  mapgl.setPadding({ left: 0, right: 0, top: 0, bottom: 0 })

  mapgl.fitBounds(bounds, {
    maxZoom: length === 1 ? 18 : 12,
    padding,
    maxDuration: 500
  })
}
