<script setup>
import { ref } from 'vue'
import { loadImages } from '@/utils'
import { ExcavationsController } from './controllers'

import SMap from '@/components/s-map/s-map.vue'

const mapgl = ref(null)
const excavationsController = ref(null)

const initHandler = async (mapglInstance) => {
  mapgl.value = mapglInstance

  excavationsController.value = new ExcavationsController(mapgl.value)

  await loadImages(mapgl.value)
  await excavationsController.value.showDataPoints()
}
</script>
 
<template>
  <div class="dashboard-print-map">
    <s-map
      module="dashboard-print-map"
      :buffer="true"
      :baselayers="false"
      :location="false"
      :legend="false"
      :controls="false"
      @init="initHandler"
    />
  </div>
</template>

<style lang="scss">
.dashboard-print-map {
  height: 420px;
  border-radius: 8px;
  border: 1px solid var(--main-bg);
  overflow: hidden;
}
</style>