<script setup>
import { ref, watch } from 'vue'
import { loadImages } from '@/utils'
import { getPlanPointConfig } from './config'

import SMap from '@/components/s-map/s-map.vue'

const props = defineProps({
  center: {
    type: Array,
    default: () => [37.6165, 55.752]
  },
  source: {
    type: Object,
    default: () => null
  }
})
const emits = defineEmits(['center-handler'])

const mapgl = ref(null)

const updatePosition = () => {
  const { lng, lat } = mapgl.value.getCenter()
  emits('center-handler', [lng, lat])
}

const initHandler = async (mapglInstance) => {
  mapgl.value = mapglInstance

  await loadImages(mapgl.value)

  mapgl.value.on('moveend', (e) => {
    if (e.fromParent) return

    updatePosition()
  })

  if (props.source) {
    showPlanLocation()
  }

  updatePosition()

  mapgl.value.on('style.load', async () => {
    await loadImages(mapgl.value)

    if (props.source) {
      showPlanLocation()
    }
  })
}

const showPlanLocation = () => {
  const layerId = 'data-point-plan'
  const { lat_plan, lon_plan } = props.source
  const data = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon_plan, lat_plan]
        }
      }
    ]
  }

  if (!mapgl.value.getSource(layerId)) {
    mapgl.value.addSource(layerId, {
      type: 'geojson',
      data
    })
  }
  if (!mapgl.value.getLayer(layerId)) {
    mapgl.value.addLayer({
      id: layerId,
      source: layerId,
      ...getPlanPointConfig(props.source.type)
    })
  }
}

watch(
  () => props.center,
  () => {
    if (!mapgl.value) return

    mapgl.value.setCenter(props.center, { fromParent: true })
  },
  { deep: true }
)
</script>

<template>
  <div class="data-map-editor">
    <s-map module="data-map-editor" :center="center" @init="initHandler" />
  </div>
</template>

<style lang="scss">
.data-map-editor {
  flex: 1;
  position: relative;
}
</style>
