const pointCircleColor = '#3C98E7'
const lineColor = '#3C98E7'

export const pointsConfig = {
  type: 'circle',
  paint: {
    'circle-color': pointCircleColor,
    'circle-radius': 6,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#ffffff'
  }
}

export const linesConfig = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': lineColor,
    'line-width': 3,
    'line-dasharray': [2, 4]
  }
}
