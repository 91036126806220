<script setup>
import { computed, useSlots } from 'vue'

const slots = useSlots()

const hasHeader = computed(() => {
  return slots.header
})
</script>

<template>
  <div class="data-mobile-layout">
    <div v-if="hasHeader">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<style lang="scss">
.data-mobile-layout {
  position: relative;
  display: flex;
  background: var(--card-bg);
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>
