<script setup>
import { computed } from 'vue'
import ChartComponent from './chart-component.vue'
import WorkSummary from './work-summary.vue'
import SWikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  card: {
    type: Boolean,
    default: true
  }
})

const chartData = computed(() => {
  return {
    labels: props.data.dates,
    series: [
      {
        name: 'Остаток (план.)',
        data: props.data.plan.map((y, i) => ({ x: props.data.dates[i], y }))
      },
      {
        name: 'Остаток (факт.)',
        data: props.data.fact.map((y, i) => ({ x: props.data.dates[i], y }))
      }
    ]
  }
})

const wikiTipData = {
  title: 'График выполнения работ',
  description:
    'Сравнительный график план. и факт. объема выполнения работ по дням от\n' +
    'начала до окончания полевых работ'
}
</script>

<template>
  <div :class="`production-chart ${card ? 'production-chart--card' : ''}`">
    <div class="production-chart__title">
      <s-title type="small"> График выполнения работ </s-title>
      <s-wiki-tip :data="wikiTipData">
        <s-icon name="circle-info" color="var(--placeholder)" />
      </s-wiki-tip>
    </div>

    <div class="production-chart__chart">
      <chart-component :options="chartData" />
    </div>

    <work-summary :gap="data.production_gap" />
  </div>
</template>

<style lang="scss">
.production-chart {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 480px;

  &--card {
    padding: 16px;
    border: 1px solid var(--section-border-color);
    background-color: var(--card-bg);
    border-radius: 8px;
  }

  &__title {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__chart {
    background-color: var(--bg);
    border: 1px solid var(--main-bg);
    border-radius: 0.5em;
    padding: 0.5em;
  }
}
</style>
