<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { goToRoute } from '@/utils'
import { useAuthStore, useSyncStore } from '@/stores'

const route = useRoute()
const authStore = useAuthStore()
const syncStore = useSyncStore()

const links = [
  {
    id: '/app/home',
    label: 'Главная',
    icon: 'house'
  },
  { id: '/app/data/objects', label: 'Проекты', admin: false, icon: 'database' }
]

const filteredLinks = computed(() => {
  if (authStore.isAdmin) return links

  return links.filter((l) => !l.admin)
})

const syncChanges = computed(() => {
  return [...syncStore.changesList, ...syncStore.dataChangesList].length || 0
})

const isActive = (path) => {
  if (path.includes('data/object')) {
    return route.fullPath?.includes('data/object') || route.fullPath?.includes('data/excavation')
  }

  return route.fullPath?.includes(path)
}
</script>

<template>
  <ul class="desktop-navbar">
    <li
      v-for="link in filteredLinks"
      :key="link.id"
      class="desktop-navbar__item"
      :class="{ active: isActive(link.id) }"
      @click="goToRoute(link.id, route)"
    >
      <s-icon :name="link.icon" />
      <span>{{ link.label }}</span>
      <s-tag
        v-if="syncChanges && link.id.includes('home')"
        :value="syncChanges"
        type="red"
        rounded
        fill
      />
    </li>
  </ul>
</template>

<style lang="scss">
.desktop-navbar {
  display: flex;
  height: 100%;
  flex: 1;

  &__item {
    position: relative;
    height: 100%;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    padding: 0 1rem;
    text-decoration: none;
    color: var(--text);

    &.active {
      .s-icon {
        color: var(--primary) !important;
      }
      color: var(--primary);
      background-color: var(--primary--active);
    }

    &:hover {
      color: var(--primary);
      .s-icon {
        color: var(--primary) !important;
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }

    .s-tag {
      position: absolute;
      top: 0.5rem;
      right: 0;
    }
  }
}
</style>
