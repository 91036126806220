<script setup>
import { computed, onMounted, ref, watch, inject } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useMq } from 'vue3-mq'

import { useServicesStore, useMainStore, useExcavationStore } from '@/stores'
import db from '@/libs/db'
import { useRequests } from '@/composables'
import { sampleTemplate } from './config'
import { deleteObject, getExcavationsChanges, saveImages, deleteImages, getUuid } from '@/utils'

import SoilInfo from '../excavation-soils/create-guide/components/soil-info.vue'
import SAttachments from '@/components/s-attachments/s-attachments.vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  excavation: {
    type: Object,
    required: true
  },
  editorName: {
    type: String,
    default: null
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  activeObject: {
    type: Object,
    default: null
  },
  soilsList: {
    type: Array,
    default: () => []
  }
})
const emits = defineEmits(['toggle'])

const mq = useMq()
const servicesStore = useServicesStore()
const mainStore = useMainStore()
const excavationStore = useExcavationStore()
const { getRequest, postRequest, putRequest } = useRequests()
const $notify = inject('$notify')

const loading = ref(false)
const imagesLoading = ref(false)
const sampleTemplateLocal = ref(null)
const initSource = ref(null)
const files = ref([])
const images = ref([])
const cloneImages = ref([])
const imageChanges = ref(false)
const sampleCommentsConfig = ref({
  title: 'comments',
  id: 'comments',
  type: 'textarea',
  label: 'Комментарий',
  autocompleteTags: {
    conditions: {
      field: 'type',
      defaultValue: [],
      values: {
        1: ['Разъемный грунтонос', 'Шелби', 'Бюкс №', 'Проктор', 'Кольцо', 'Экология', 'Коррозия'],
        2: ['Разъемный грунтонос', 'Шелби', 'Бюкс №', 'Проктор', 'Кольцо', 'Экология', 'Коррозия'],
        11: ['ПЭТ', 'Стекло', 'CaCO3', 'HCl', 'H2SO4', '0.5л', '1л', '1.5л']
      }
    }
  }
})

const hasChanges = computed(() => {
  return !isEqual(initSource.value, sampleTemplateLocal.value)
})

const title = computed(() => {
  return props.activeObject ? 'Редактирование пробы' : 'Создание пробы'
})

const disabled = computed(() => {
  if (loading.value) return true
  if (!sampleTemplateLocal.value) return true
  const { selection_from_d, selection_to_d } = sampleTemplateLocal.value
  const from = !!selection_from_d || selection_from_d === 0
  const to = !!selection_to_d || selection_to_d === 0

  return !from || !to
})

const soilInterval = computed(() => {
  const selection_from_d = sampleTemplateLocal.value?.selection_from_d
  const length = props.soilsList.length

  if (!sampleTemplateLocal.value || !length || (!selection_from_d && selection_from_d !== 0))
    return null
  const sorted = props.soilsList
    .map((e) => e)
    ?.sort((a, b) => {
      return a.foot_d - b.foot_d
    })

  const index = sorted.findIndex((e) => e.foot_d > selection_from_d)
  const from = index === 0 ? 0 : index > 0 ? sorted[index - 1]?.foot_d : null
  const to =
    index === -1
      ? null
      : index === 0
        ? sorted[index]?.foot_d
        : index > 0
          ? sorted[index]?.foot_d
          : null

  return to ? { from, to } : null
})

const soilData = computed(() => {
  const selection_from_d = sampleTemplateLocal.value?.selection_from_d
  const length = props.soilsList.length

  if (!sampleTemplateLocal.value || !length || (!selection_from_d && selection_from_d !== 0))
    return null
  const sorted = props.soilsList
    .map((e) => e)
    ?.sort((a, b) => {
      return a.foot_d - b.foot_d
    })

  const index = sorted.findIndex((e) => e.foot_d > selection_from_d)
  const soil = index === -1 ? null : sorted[index]

  return soil
})

const service = computed(() => {
  return servicesStore?.sample?.types || []
})

const minValue = computed(() => {
  return 0
})

const maxValue = computed(() => {
  let max = 0

  props.soilsList?.forEach((e) => {
    max = e.foot_d > max ? e.foot_d : max
  })

  return max
})

watch(
  () => sampleTemplateLocal.value?.selection_from_d,
  (newValue) => {
    intervalInputHandler(newValue)
  },
  { deep: true }
)

const intervalInputHandler = (val) => {
  if (val === null) {
    sampleTemplateLocal.value.selection_to_d = null
  } else {
    const initVal = Number(val)
    if (props.activeObject) {
      return
    }

    // для монолитов делаем to_d +0.2, для остальных делаем таким же как и from_d
    if (sampleTemplateLocal.value.type === 1) {
      const sum = Number((initVal + 0.2)?.toFixed(2))

      sampleTemplateLocal.value.selection_to_d = sum > maxValue.value ? maxValue.value : sum
    } else {
      sampleTemplateLocal.value.selection_to_d = sampleTemplateLocal.value.selection_from_d
    }
  }
}

const loadImages = async ({ id, server_id }) => {
  imagesLoading.value = true

  try {
    if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
      const data = await getRequest(`samples/${server_id}/images/`)

      images.value = data || []
    }

    const idb = await db.images
      .where({
        table: 'samples',
        item_id: id
      })
      .toArray()

    const idbParsed = idb.map((e) => ({
      ...e,
      idb: true
    }))

    images.value = [...images.value, ...idbParsed]
    cloneImages.value = cloneDeep(images.value)
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при загрузке фотографий. ${e}`,
      type: 'error'
    })
  } finally {
    imagesLoading.value = false
  }
}

onMounted(() => {
  if (props.activeObject) {
    sampleTemplateLocal.value = cloneDeep(props.activeObject)
    loadImages(props.activeObject)
  } else {
    sampleTemplateLocal.value = sampleTemplate()
  }

  initSource.value = cloneDeep(sampleTemplateLocal.value)
})

const handleDelete = async () => {
  await deleteObject(sampleTemplateLocal.value, 'samples', updateSamples)
  emits('toggle')
}

const updateSamples = () => {
  excavationStore.setField('updateSamples', true)
}

const saveHandler = () => {
  if (!props.activeObject) {
    createHandler()
  } else {
    updateHandler()
  }
}

const createHandler = async () => {
  loading.value = true

  try {
    sampleTemplateLocal.value.date_front = new Date()
    sampleTemplateLocal.value.uuid = getUuid()
    const data = cloneDeep(sampleTemplateLocal.value)
    const idbData = cloneDeep(sampleTemplateLocal.value)
    const excavationId = props.excavation?.server_id || `idb_${props.excavation.id}`

    idbData.excavation_id = String(excavationId)
    if (mainStore.isOnline && !mainStore.noSyncMode && props.excavation?.server_id) {
      const response = await postRequest(`excavations/${excavationId}/samples/`, data)

      if (!response) return

      idbData.server_id = response?.id
    }

    const idbId = await db.addObject('samples', idbData, {
      field: 'excavation_id',
      value: excavationId
    })

    sampleTemplateLocal.value.id = idbId
    sampleTemplateLocal.value.server_id = idbData.server_id

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      await db.created.add({
        table: 'samples',
        date: new Date(),
        item_id: idbId
      })
      getExcavationsChanges()
    }

    const title = 'Создание'
    const message = 'Проба успешно сохранена'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (e) {
    const title = 'Ошибка'
    const message = 'Произошла ошибка при создании пробы'
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
    emits('toggle')

    if (files.value?.length) {
      await saveImagesLocal()
    }

    excavationStore.setField('updateSampleByID', sampleTemplateLocal.value.id)
  }
}

const updateHandler = async () => {
  loading.value = true

  try {
    sampleTemplateLocal.value.date_front = new Date()

    const data = cloneDeep(sampleTemplateLocal.value)
    const idbData = cloneDeep(sampleTemplateLocal.value)
    const excavationId = props.excavation?.server_id || `idb_${props.excavation.id}`

    idbData.excavation_id = String(excavationId)

    if (mainStore.isOnline && !mainStore.noSyncMode && sampleTemplateLocal.value?.server_id) {
      delete data.server_id
      delete data.excavation_id
      data.id = sampleTemplateLocal.value.server_id

      const response = await putRequest(`samples/${sampleTemplateLocal.value.server_id}/`, data)

      if (!response) return

      idbData.server_id = response?.id
    }

    if ((!mainStore.isOnline || mainStore.noSyncMode) && excavationId) {
      const updatedItems = await db.updated.where('table').equals('samples').toArray()
      const inUpdated = !!updatedItems.find((e) => e.item_id === idbData.id)
      const createdItems = await db.created.where('table').equals('samples').toArray()
      const inCreated = !!createdItems.find((e) => e.item_id === idbData.id)

      if (!inUpdated && !inCreated) {
        await db.updated.put({
          table: 'samples',
          item_id: idbData.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    await db.updateObject('samples', idbData, {
      field: 'excavation_id',
      value: excavationId
    })

    const title = 'Редактирование'
    const message = 'Проба успешно изменена'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (e) {
    const title = 'Ошибка'
    const message = 'Произошла ошибка при редактировании пробы'
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    emits('toggle')

    if (cloneImages.value?.length) {
      await updateImages()
    }

    if (files.value?.length) {
      await saveImagesLocal()
    }

    loading.value = false
    excavationStore.setField('updateSampleByID', sampleTemplateLocal.value.id)
  }
}

const saveImagesLocal = async () => {
  const { server_id, id } = sampleTemplateLocal.value
  const excavation_id = props.excavation.server_id
    ? String(props.excavation.server_id)
    : `idb_${props.excavation.id}`

  const data = {
    server_id,
    table: 'samples',
    item_id: id,
    files: files.value,
    excavation_id,
    uuid: getUuid()
  }

  await saveImages(data)
}

const updateImages = async () => {
  try {
    const deleted = cloneImages.value?.filter((c) => !images.value.find((im) => im.id === c.id))
    const { server_id, id } = props.excavation

    const data = {
      table: 'samples',
      files: deleted,
      excavation_id: server_id ? String(server_id) : `idb_${id}`,
      excavation: props.excavation
    }
    await deleteImages(data)
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при удалении фотографий. ${e}`,
      type: 'error'
    })
  }
}

const changeSelect = (value) => {
  sampleTemplateLocal.value.type = value

  intervalInputHandler(sampleTemplateLocal.value.selection_from_d || 0)
}

const additionalText = 'Рекомендуется загружать фотографии в соотношении 4:3'

const wikiTipData = {
  color: 'placeholder',
  title: 'Фотодокументация проб',
  description:
    'На фотографируемый керн и образцы устанавливают этикетки с номером выработки и интервалом отбора.',
  source:
    'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.3.3. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
}
</script>

<template>
  <s-modal
    v-if="sampleTemplateLocal"
    :title="title"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :min-height="460"
    :confirm-condition="hasChanges || imageChanges"
    confirm-on-cancel
    @close="emits('toggle')"
  >
    <div v-loading="loading || imagesLoading" class="samples-editor">
      <div class="samples-editor-block">
        <s-title type="small"> Интервал отбора</s-title>
        <div v-if="sampleTemplateLocal" class="samples-editor-row">
          <s-number-input
            v-model="sampleTemplateLocal.selection_from_d"
            :min="minValue"
            :max="maxValue"
            label="От, м"
            placeholder="1.1"
          />
          <s-number-input
            v-model="sampleTemplateLocal.selection_to_d"
            :min="minValue"
            :max="maxValue"
            label="До, м"
            placeholder="1.3"
          />
        </div>
        <soil-info v-if="soilInterval && soilData" :data="soilData" :soil-interval="soilInterval" />
        <soil-info
          v-if="
            !soilInterval &&
            (sampleTemplateLocal?.selection_from_d || sampleTemplateLocal?.selection_to_d)
          "
          :data="{}"
          type="warning"
          tip="Проба не попадает в интервал существующих грунтов"
          :soil-interval="{}"
        />
      </div>
      <div v-if="soilInterval" class="samples-editor-block">
        <s-title type="small"> Информация об пробе</s-title>
        <s-select
          :value="sampleTemplateLocal.type"
          label="Тип пробы"
          placeholder="Не выбрано"
          :filterable="mq.current === 'lg'"
          :options="service"
          @change="changeSelect"
        >
        </s-select>
        <s-attachments
          v-if="!imagesLoading"
          v-loading="loading"
          label="Загрузка фото"
          :source="files"
          :images="images"
          :wiki-tip="wikiTipData"
          multiple
          :additionalText="additionalText"
          @change="imageChanges = true"
        />
        <div>
          <s-input-renderer :field="sampleCommentsConfig" :source="sampleTemplateLocal" />
        </div>
      </div>
    </div>
    <template #footer>
      <div :class="['samples-editor-footer', { exist: activeObject }]">
        <s-button
          v-if="activeObject"
          icon="trash-can"
          simple
          icon-color="var(--error)"
          @click="handleDelete"
        />
        <s-button type="success" :disabled="disabled" @click="saveHandler">
          {{ activeObject ? 'Сохранить' : 'Создать' }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.samples-editor {
  display: grid;
  align-content: start;
  grid-gap: 2rem;
  height: 100%;
  overflow: auto;

  &-block {
    display: grid;
    align-content: start;
    grid-gap: 1rem;
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1rem;
  }

  &-footer {
    display: grid;

    &.exist {
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }
  }
}
</style>
