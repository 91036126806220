<script setup>
import { usePrintStore } from '@/stores'
import ListHeader from '@/modules/print/dashboard/components/list-header.vue'
import ListItem from './list-item.vue'
import ListFooter from './list-footer.vue'

const printStore = usePrintStore()
</script>

<template>
  <div class="dp-excavations">
    <div class="dp-excavations__title">
      <s-title type="title"> Статистика выполнения работ по дням </s-title>
    </div>
    <ul class="dp-excavations__list">
      <list-header class="dp-excavations__list-header" mode="print" />
      <list-item
        v-for="(data, index) in printStore.filteredProductions"
        :key="data"
        :chartsData="data"
        :index="index"
      />
    </ul>
    <list-footer />
  </div>
</template>

<style lang="scss">
.dp-excavations {
  &__list {
    margin-top: 16px;
  }
}
</style>
