<script setup>
import { computed } from 'vue'
const props = defineProps({
  current: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['back'])

const title = computed(() => {
  if (props.current) {
    return props.current.label
  }

  return 'Выберите тип документа'
})
</script>

<template>
  <div class="export-header">
    <s-button
      v-if="!!current"
      icon="chevron-left"
      simple
      class="export-header__button"
      @click="emits('back')"
    />
    <s-title type="small"> {{ title }} </s-title>
  </div>
</template>

<style lang="scss">
.export-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &__button {
    margin-right: 8px;
    width: 2rem !important;
    height: 2rem !important;
  }
}
</style>
