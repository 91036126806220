<script setup>
import { ref, onMounted } from 'vue'
import { useMq } from 'vue3-mq'
import { useMainStore } from '@/stores'
import { useRitmDate, useRequests } from '@/composables'
import { getImageSrc } from '@/utils'
import { fields } from '../config'

import SimpleCard from '@/components/simple-card.vue'

const mainStore = useMainStore()
const rDate = useRitmDate()
const { getRequest } = useRequests()
const mq = useMq()

const start = new Date()
const end = new Date()
end.setDate(end.getDate() + 1)
start.setMonth(start.getMonth() - 1)

const dateRange = ref([start, end])
const updateFilter = (e) => {
  dateRange.value = e

  loadStats()
}

const loading = ref(false)
const stats = ref(null)
const getValue = (id) => {
  if (!stats.value) {
    return '-'
  }

  if (id === 'excavations_meters') {
    return stats.value[id] && Number(stats.value[id]) ? Number(stats.value[id]).toFixed(2) : 0
  }

  return Number(stats.value[id]) || 0
}
const getImageUrl = (id) => {
  const theme = mainStore.theme === 'dark-theme' ? `dark` : `light`

  return getImageSrc(`assets/images/profile/${id}_${theme}.svg`)
}

const loadStats = async () => {
  try {
    loading.value = true

    const [startDate, endDate] = dateRange.value
    const start = rDate(startDate).format('YYYY-MM-DD h:mm')
    const end = rDate(endDate).format('YYYY-MM-DD h:mm')
    const url = `users/statistics/?from_date=${start}&to_date=${end}`

    stats.value = await getRequest(url)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  loadStats()
})
</script>

<template>
  <simple-card title="Статистика" icon="chart-bar">
    <div v-loading="loading" class="profile-stats">
      <ul class="profile-stats__list">
        <li v-for="item in fields" :key="item.id" class="profile-stats__item">
          <div class="profile-stats__item-icon">
            <img :src="getImageUrl(item.id)" />
          </div>
          <div class="profile-stats__item-info">
            <div class="profile-stats__item-count">
              <s-title :type="mq.current === 'sm' ? 'small' : 'title'">
                {{ getValue(item.id) }}
              </s-title>
            </div>
            <div class="profile-stats__item-label">
              <s-text type="secondary">
                {{ item.label }}
              </s-text>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <template #actions>
      <s-date :value="dateRange" @change="updateFilter" range :text-only="true" />
    </template>
  </simple-card>
</template>

<style lang="scss" scoped>
.profile-stats {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__item {
    flex-basis: calc(33% - 32px);
    display: flex;
    align-items: center;

    &-icon {
      background-color: var(--card-bg);
      border-radius: 8px;
      border: 1px solid var(--main-bg);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      img {
        height: 24px;
        width: auto;
      }
    }

    &-info {
      margin-left: 16px;
      margin-top: -4px;
    }
  }
}

.sm {
  .profile-stats__item {
    flex-basis: calc(50% - 16px);

    &-icon {
      width: 40px;
      height: 40px;

      img {
        height: 20px;
      }
    }
  }
}

.dp__main {
  min-width: 160px !important;
}
</style>
