import joinProject from './actions/join-project'
import deleteExcav from './actions/delete-excav'

const contextMenu = {
  items: {
    joinProject,
    deleteExcav
  }
}

export default contextMenu
