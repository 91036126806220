export const listTips = {
  default: {
    title: 'Список проектов пуст',
    description: 'Вы не участвуете ни в одном проекте.',
    additional: 'Создайте новый проект или обратитесь к вашему администратору'
  },
  filtered: {
    title: 'Ничего не найдено',
    description: 'По вашему запросу не удалось найти ни одного проекта',
    image: 'filter'
  }
}

export const filtersConfig = {
  filters: [
    {
      id: 'status',
      filterTitle: 'Статус',
      filterValues: [
        {
          id: 1,
          title: 'Не начат',
          value: true
        },
        {
          id: 2,
          title: 'В работе',
          value: true
        },
        {
          id: 3,
          title: 'Завершен',
          value: true
        },
        {
          id: 4,
          title: 'Отложен',
          value: true
        }
      ]
    },
    {
      id: 'archived',
      filterTitle: 'Архивный',
      filterValues: [
        {
          id: true,
          title: 'Да',
          value: true
        },
        {
          id: false,
          title: 'Нет',
          value: true
        }
      ]
    }
  ],
  sort: {
    value: 'title_short',
    order: 'asc',
    list: [
      {
        id: 'title_short',
        title: 'Кор. название'
      },
      {
        id: 'status',
        title: 'Статус'
      },
      {
        id: 'num',
        title: 'Шифр'
      },
      {
        id: 'date_start',
        title: 'Дата начала'
      },
      {
        id: 'date_end',
        title: 'Дата окончания'
      }
    ]
  }
}
