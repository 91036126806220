<script setup>
import { computed } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const itemType = computed(() => {
  return props?.item?.type === 201 ? 'Точка наблюдения' : 'Скважина'
})

const getItemLocationText = computed(() => {
  return props?.item?.type === 201 ? 'точке наблюдения' : 'скважине'
})
</script>

<template>
  <s-alert
    v-if="item.sync_status === 1"
    class="unassigned-geologists-tip"
    icon="circle-info"
    type="warning"
  >
    <s-text
      >{{ itemType }} находится в процессе синхронизации. Настоятельно рекомендуется не
      редактировать данные по {{ getItemLocationText }} пока синхронизация не завершится.</s-text
    >
  </s-alert>
</template>
