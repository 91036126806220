<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  }
})

const servicesStore = useServicesStore()

const title = computed(() => {
  return serviceName.value?.includes('clay')
    ? 'Укажите консистенцию грунта'
    : 'Укажите степень водонасыщения'
})

const serviceName = computed(() => {
  if (props.source?.soil_type >= 11101 && props.source?.soil_type <= 11205) {
    return 'sand'
  } else if (props.source?.soil_type >= 12102 && props.source?.soil_type <= 12103) {
    return 'clay'
  } else if (props.source?.soil_type === 12101) {
    return 'sandy_clay'
  } else {
    return 'other'
  }
})

const services = computed(() => {
  return servicesStore?.soil
})

const waterField = computed(() => {
  return {
    id: 'water_saturation',
    title: 'water_saturation',
    type: 'select',
    commentField: 'water_saturation_comments',
    commentTitle: 'Комментарий к консистенции',
    service: `water_saturations_${serviceName.value}`
  }
})
</script>

<template>
  <div class="soil-water-select">
    <s-title type="small">{{ title }}</s-title>
    <s-input-renderer :field="waterField" :source="source" :services="services" />
  </div>
</template>

<style lang="scss" scoped>
.soil-water-select {
  display: grid;
  grid-gap: 0.5rem;
}
</style>
