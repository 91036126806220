<script setup>
import { ref } from 'vue'
import SoilSelectComp from './soil-select.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const initSource = ref(props.source)
</script>

<template>
  <div class="soil-group">
    <s-title v-if="field.title" type="small">
      {{ field.title }}
    </s-title>
    <soil-select-comp
      v-for="group in field.groups"
      :key="group.id"
      :source="initSource"
      :field="group"
    />
    <s-input
      v-if="field.commentField"
      v-model="initSource[field.commentField]"
      :label="field.commentTitle || 'Комментарий'"
      type="textarea"
    />
  </div>
</template>

<style lang="scss" scoped>
.soil-group {
  display: grid;
  grid-gap: 1rem;
}
</style>
