import { getExcavationsChanges } from './excavation'
import { getDataChanges } from './data.js'
import { useRequests } from '@/composables'
import { useSyncStore } from '@/stores'
import db from '@/libs/db'
export * from './excavation.js'
export * from './data.js'

export const getAllChanges = async () => {
  await getExcavationsChanges()
  await getDataChanges()
}

export const sendSyncProgress = async (item, syncStoreField = 'changesList', url) => {
  const syncStore = useSyncStore()
  const { patchRequest } = useRequests()

  const { item_id } = item
  const serverId = item?.data?.server_id || item_id

  if (!serverId) return
  if (!item_id) return

  const findedItem = syncStore[syncStoreField].find((i) => i.item_id === item_id)

  if (!findedItem) return

  const changesArray = []
  findedItem.updates.forEach((update) => {
    changesArray.push(update.title)
  })

  const data = {
    sync_status: 1,
    sync_progress: changesArray
  }

  try {
    await patchRequest(`${url}/${serverId}/`, data)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const resetSyncProgress = async (item, dataUrl = 'excavations') => {
  const { patchRequest } = useRequests()

  const { item_id } = item
  const serverId = item?.data?.server_id || item_id

  if (!serverId) return
  if (!item_id) return

  const data = {
    sync_status: 2,
    sync_progress: []
  }

  try {
    await patchRequest(`${dataUrl}/${serverId}/`, data)
  } catch (e) {
    console.log(e)
    throw e
  }
}

const tablesToCheck = [
  'excavations',
  'soils',
  'samples',
  'groundwater',
  'coreboxes',
  'images',
  'inclusions',
  'interlayers',
  'recons'
]

export const checkUnsyncedData = async () => {
  for (const table of tablesToCheck) {
    const collection = await db[table].filter((item) => !item.server_id)
    const items = await collection.toArray()

    if (items.length) {
      await addDataToCreated(table, items)
    }
  }
}

const addDataToCreated = async (table, items) => {
  for (const item of items) {
    const existingItem = await db.created.where({ item_id: item.id, table }).first()

    if (!existingItem) {
      const object = { table, date: new Date(), item_id: item.id }

      if (item.uuid) {
        object.uuid = item.uuid
      }

      if (table === 'images' && item.excavation_id) {
        object.excavation_id = item.excavation_id
        object.table = 'images'
      }

      await db.created.add(object)
    }
  }
}
