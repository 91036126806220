import { layerTemplate } from '@/utils'

export const resetSoilSource = source => {
  const template = layerTemplate()

  const exceptions = [
    'interlayers',
    'inclusions',
    'color_main',
    'color_optional',
    'alteration_comments',
    'comments',
    'state',
    'foot_d'
  ]
  const soilType = source.soil_type

  Object.keys(template)?.forEach(f => {
    if (!exceptions?.includes(f)) {
      source[f] = f?.includes('comment') ? '' : null
    }
  })

  source.soil_type = soilType
}
