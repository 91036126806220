<script setup>
import { onMounted, ref } from 'vue'
import { useMq } from 'vue3-mq'
import { useServicesStore } from '@/stores'
import { getFormattedFromToDate } from '@/utils/strings'

const mq = useMq()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  hasObjName: {
    type: Boolean,
    default: false
  }
})

const servicesStore = useServicesStore()

const ready = ref(false)

onMounted(() => {
  ready.value = true
})

const getName = () => {
  const { title, type } = props.item
  if (type === 101) {
    return `Скв. ${title}`
  } else if (type === 2) {
    return `ТСЗ. ${title}`
  } else {
    return `ТН ${title}`
  }
}

const getGeologistName = () => {
  const user = servicesStore.users?.find((user) => user.id === props.item.geologist)
  if (!user) return '-'

  if (user.short_name) return user.short_name
  return user.username
}

const getDate = () => {
  if (props.item.type === 101) {
    return getFormattedFromToDate(props.item?.date_from, props.item?.date_to, props.item.type)
  }
  return getFormattedFromToDate(props.item?.date_end)
}
</script>

<template>
  <div v-if="ready && mq.current === 'sm'" class="data-item-info">
    <s-text type="info"> {{ getName() }}</s-text>
    <s-title v-if="hasObjName" type="small">
      {{ item.object_ref_title_short }}
    </s-title>
    <div v-else-if="item.site && mq.current !== 'sm'" class="data-item-info__plot">
      <s-text color="var(--caption)" small> уч. </s-text>
      <s-text color="var(--caption)">
        {{ item.site || '' }}
      </s-text>
    </div>
    <div class="data-item-info__date">
      <s-icon size="sm" name="calendar-days" />
      <s-text type="secondary">
        {{ getDate() }}
      </s-text>
    </div>
    <div v-if="!hasObjName" class="data-item-info__row">
      <s-icon size="sm" name="user" />
      <s-text type="secondary" semibold>
        {{ getGeologistName() }}
      </s-text>
    </div>
  </div>
  <div v-else-if="ready" class="data-item-info">
    <div class="data-item-info__title">
      <s-text type="info"> {{ getName() }}</s-text>
      <div v-if="!hasObjName && item.site" class="data-item-info__plot">
        <s-text color="var(--caption)" small> уч. </s-text>
        <s-text color="var(--caption)">
          {{ item.site || '' }}
        </s-text>
      </div>
      <s-title v-if="hasObjName" type="small">
        {{ item.object_ref_title_short }}
      </s-title>
    </div>
    <div class="data-item-info__info">
      <div class="data-item-info__row">
        <s-icon size="sm" name="calendar-days" />
        <s-text type="secondary" semibold>
          {{ getDate() }}
        </s-text>
      </div>
      <div class="data-item-info__row">
        <s-icon size="sm" name="user" />
        <s-text type="secondary" semibold>
          {{ getGeologistName() }}
        </s-text>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.data-item-info {
  display: grid;
  grid-gap: 0.5rem;

  &__plot {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__title {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__date {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    align-items: center;
    color: var(--caption);
  }

  &__row,
  &__info {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;
    align-items: center;
    color: var(--caption);
  }

  &__row {
    grid-gap: 0.5rem;
  }
}
</style>
