export const menuList = [
  {
    id: 'meta-data',
    label: 'Метаданные компании',
    icon: 'users-cog',
    type: 'default',
    admin: true
  },
  {
    id: 'books',
    label: 'Словари',
    icon: 'book-open',
    type: 'default'
  },
  {
    id: 'users-docs',
    label: 'Руководство пользователя',
    icon: 'graduation-cap',
    type: 'link',
    link: 'https://docs.soilbox.app'
  },
  {
    id: 'about-system',
    label: 'О системе',
    icon: 'circle-info',
    type: 'default',
    mobileOnly: true
  },
  {
    id: 'updates',
    label: 'Обновления',
    icon: 'clock-rotate-left',
    type: 'default',
    mobileOnly: true
  }
]
