<script setup>
import { SText, STitle, SButton, SDiv } from '@n966/soilbox_ui'
import router from '@/router/index.js'

const backToObjects = () => {
  router.push('/app/data/objects/list')
}
</script>

<template>
  <div class="not-found">
    <s-div center class="not-found__wrapper">
      <div class="not-found__image-wrapper">
        <img src="@/assets/images/emptylist_illustration_filter.svg" alt="" />
      </div>
      <s-title type="main">Страница не найдена</s-title>
      <s-text>
        Кажется, что-то пошло не так! Запрашиваемой Вами страницы не существует. Возможно она
        устарела, была удалена или был введен неверный адрес в адресной строке
      </s-text>
      <s-div class="not-found__button-wrapper" center>
        <s-button type="primary" @click="backToObjects"> Вернуться к списку проектов</s-button>
      </s-div>
    </s-div>
  </div>
</template>

<style lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
    max-width: 500px;
    margin: 100px auto;
    text-align: center;
  }

  &__button-wrapper {
    margin-top: 32px;
  }

  &__image-wrapper {
    text-align: center;
  }

  @include phones {
    margin: 50px;
    padding: 0 20px;
  }
}
</style>
