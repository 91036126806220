export const getInclusionTitle = item => {
  const { d0, d1 } = item

  if ((d0 || d0 === 0) && d1) {
    return `от ${d0} до ${d1} м`
  } else if (d0) {
    return `с ${d0} м`
  } else if (d1) {
    return `до ${d1} м`
  } else {
    return ''
  }
}

export const getInclusionText = (item, services) => {
  const { amount, type, composition, comments } = item
  let amountName, typeName, compositionName

  if (amount) {
    amountName = services?.inclusions_amounts.find(e => e.id === amount)?.title
  }

  if (type && type !== 1) {
    typeName = services?.inclusions_types.find(e => e.id === type)?.title
  }

  if (composition) {
    compositionName = services?.soil_types.find(
      e => e.id === composition
    )?.title_gen_short
  }

  let text = ''

  if (amountName) {
    text += amountName
  }

  if (typeName) {
    text += ` ${typeName}`
  }

  if (compositionName) {
    text += ` ${compositionName}`
  }

  if (comments) {
    text += `, ${comments?.toLowerCase()}`
  }

  return text ? text?.toLowerCase() : '-'
}
