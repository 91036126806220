<script setup>
import { useRouter } from 'vue-router'
import DataItemIcon from '@/components/s-data-item/s-data-item-icon.vue'
import DataItemInfo from '@/components/s-data-item/s-data-item-info.vue'
import DataItemSubinfo from '@/components/s-data-item/s-data-item-subinfo.vue'

const router = useRouter()

const props = defineProps({
  source: {
    type: Object,
    default() {
      return {}
    }
  },
  hasObjName: {
    type: Boolean
  }
})

const openDataItem = () => {
  const id = props.source?.server_id || `idb_${props.source.id}`
  if (props.source.type === 101) {
    router.push(`/app/data/excavation?id=${id}`)
  } else {
    router.push(`/app/data/recon?id=${id}`)
  }
}
</script>

<template>
  <div class="data-item" @click="openDataItem">
    <data-item-icon :type="props.source.type" />
    <data-item-info :item="source" :has-obj-name="hasObjName" />
    <data-item-subinfo :type="props.source.type" :data="source" />
  </div>
</template>

<style lang="scss">
.data-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 1rem;
  justify-content: start;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;

  @media (max-width: 500px) {
    grid-gap: 1rem;
    padding: 0;
  }

  &:hover {
    background-color: var(--primary--active);
    border-radius: 8px;
  }

  &:active {
    background-color: var(--primary--active);
    border-radius: 8px;
  }
}
@media (max-width: 350px) {
  .excavation-item {
    grid-template-columns: auto 1fr;
    justify-content: space-between;

    & .data-item-icon {
      display: none;
    }
  }
}
</style>
