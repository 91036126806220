<script setup>
import { ref, computed } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { objectMobileNavigationLinks } from './config/index.js'

const route = useRoute()
const previousQuery = ref(null)

onBeforeRouteUpdate(async (to, from, next) => {
  if (from.name === 'object-data') {
    previousQuery.value = from.query
  }
  next()
})

const getRouteWithQuery = (link) => {
  return computed(() => {
    if (link.routeName === 'object-data' && previousQuery.value) {
      return { name: link.routeName, query: previousQuery.value }
    }
    return { name: link.routeName }
  })
}
</script>

<template>
  <ul class="object-mobile-navigation">
    <li :key="link.routeName" v-for="link in objectMobileNavigationLinks">
      <router-link
        class="object-mobile-navigation__link"
        :to="getRouteWithQuery(link)"
        exact
        :class="{
          'router-link-exact-active': route.name === link.routeName
        }"
      >
        <s-text>{{ link.title }}</s-text>
      </router-link>
    </li>
  </ul>
</template>
<style lang="scss">
.object-mobile-navigation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
  background: var(--card-bg);

  &__link {
    text-decoration: none;
    text-align: center;
    background: var(--bg);
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 8px 20px;
    outline: 1px solid var(--main-bg);

    @include phones {
      padding: 6px 10px;
    }
  }

  & li:first-child {
    & .object-mobile-navigation__link {
      border-radius: 8px 0px 0px 8px;
      margin-left: 0;
    }
  }

  & li:last-child {
    & .object-mobile-navigation__link {
      border-radius: 0px 8px 8px 0px;
      margin-left: 0;
    }
  }

  & .router-link-active {
    background: var(--primary--active);
    & .s-text {
      color: var(--primary);
    }
  }
}
</style>
