<script setup>
import { computed } from 'vue'
import { useAuthStore, useMainStore } from '@/stores'
import router from '@/router'
import { useOnlyMobileHandler } from '@/composables'
import { menuList } from '@/configs/menu'

import CenteredContainer from '@/components/centered-container.vue'

const authStore = useAuthStore()
const mainStore = useMainStore()

const filteredMenuList = computed(() => {
  if (authStore.isAdmin) return menuList

  return menuList.filter((i) => !i.admin)
})

const showInfoModal = () => {
  mainStore.toggleInfoModal()
}

const menuClickHandler = (item) => {
  if (item.id === 'about-system') {
    showInfoModal()
    return
  }

  if (item.id === 'updates') {
    mainStore.toggleUpdatesModal()
    return
  }

  switch (item.type) {
    case 'link':
      window.open(item.link, '_blank')
      break
    default:
      router.push(`/app/${item.id}`)
  }
}

useOnlyMobileHandler()
</script>

<template>
  <centered-container>
    <div class="mobile-menu">
      <div class="mobile-menu__header">
        <s-title center> Меню </s-title>
      </div>

      <ul class="mobile-menu__list">
        <li
          class="mobile-menu__item"
          v-for="item in filteredMenuList"
          :key="item.id"
          @click="menuClickHandler(item)"
        >
          <div class="mobile-menu__item-info">
            <s-icon :name="item.icon" color="var(--caption)" size="lg" />
            <s-text semibold>
              {{ item.label }}
            </s-text>
          </div>

          <s-icon
            :name="item.type === 'default' ? 'angle-right' : 'up-right-from-square'"
            color="var(--caption)"
          />
        </li>
      </ul>
    </div>
  </centered-container>
</template>

<style lang="scss">
.mobile-menu {
  &__header {
    margin: 8px 0 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid var(--main-bg);
    border-radius: 8px;
    background-color: var(--bg);

    &-info {
      display: flex;
      gap: 12px;
    }
  }
}
</style>
