export const frozenSteps = {
  main: [
    {
      id: 'color',
      value: 'color',
      title: 'Укажите цвет грунта',
      type: 'color-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Цвет грунта',
        description:
          'Цвет грунта описывают в естественном состоянии, при полном дневном освещении. Допускается описание цвета при искусственном освещении с использованием ламп дневного света или при любом освещении с применением сравнительного образца или эталона, цветовых шкал. Для объективного описания цветов рекомендуется пользоваться цветовыми шкалами (палетками), например выполненными по системе Манселла, или другими системами, специализированными для определения цвета грунтов (например, цветовая таблица грунтов S133N). Цвет скального грунта описывают во влажном (смоченном) состоянии. Если необходимо, цве­товые различия могут быть уточнены использованием таких терминов, как «пестрый», «пятнистый», «испещренный», «крапчатый», «полосчатый».',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'text_pfrost',
      value: 'text_pfrost',
      title: 'Укажите криогенную текстуру грунта',
      type: 'text-pfrost',
      service: 'texts_pfrost',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к криогенной текстуре',
      wikiTipData: {
        color: 'placeholder',
        title: 'Криогенная текстура',
        description:
          'Совокупность признаков сложения мерзлого грунта, обусловленная ориентировкой, относительным расположением и распределением включений текстурообразующего льда и минеральных агрегатов',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 3.4.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'ice_content',
      value: 'ice_content',
      title: 'Укажите льдистость грунта',
      type: 'ice-content',
      commentField: 'ice_content_comments',
      commentTitle: 'Комментарий к льдистости',
      wikiTipData: {
        color: 'placeholder',
        title: 'Льдистость',
        description:
          'Полевое описание грунтового льда (включения и прослои свыше 10 см мощностью) при колонковом бурении включаете себя цвет, наличие и ориентацию трещин, включений (грунта или пузырьков газа)',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 7.1.4. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'state_pfrost',
      value: 'state_pfrost',
      title: 'Укажите состояние грунта',
      clearable: true,
      type: 'state-pfrost',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к состоянию / консистенции',
      additionalTrigger: true,
      createTrigger: true
    }
  ],
  additional: [
    {
      id: 'inclusions',
      value: 'inclusions',
      title: 'Включения',
      type: 'inclusions'
    },
    {
      id: 'interlayers',
      value: 'interlayers',
      title: 'Прослои',
      type: 'interlayers',
      frozen: true
    },
    {
      id: 'additional',
      value: 'additional',
      title: 'Укажите дополнительную информацию',
      type: 'additional',
      createTrigger: true
    }
  ]
}
