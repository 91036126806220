import { helpers, maxLength, required } from '@vuelidate/validators'
import { useObjectsStore } from '@/stores'

export const objectValidator = () => {
  const objectsStore = useObjectsStore()

  return {
    title: {
      required: helpers.withMessage('"Название" обязательно для заполнения', required),
      maxLength: helpers.withMessage(
        'Убедитесь, что значение в поле "Название" не превышает 150 символов',
        maxLength(150)
      ),
      unique: helpers.withMessage('Проект с таким названием уже существует', (value) => {
        if (!value) return true

        const isExisted = objectsStore.objectsList.find((item) => item.title === value)

        if (isExisted && isExisted.server_id === objectsStore.activeObject.server_id) {
          return true
        }

        return !isExisted
      })
    },
    title_short: {
      unique: helpers.withMessage('Проект с таким коротким названием уже существует', (value) => {
        if (!value) return true

        const isExisted = objectsStore.objectsList.find((item) => item.title_short === value)

        if (isExisted && isExisted.server_id === objectsStore.activeObject.server_id) {
          return true
        }

        return !isExisted
      })
    },
    num: {
      unique: helpers.withMessage('Проект с таким шифром уже существует', (value) => {
        if (!value) return true

        const isExisted = objectsStore.objectsList.find((item) => item.num === value)

        if (isExisted && isExisted.server_id === objectsStore.activeObject.server_id) {
          return true
        }

        return !isExisted
      })
    }
  }
}
