import cloneDeep from 'lodash.clonedeep'

const geoProcessesTypes = [
  {
    id: 'endogenous',
    title: 'Эндогенные геологические процессы',
    items: [],
    filter: 1
  },
  {
    id: 'exogenous',
    title: 'Экзогенные геологические процессы',
    items: [
      {
        id: 22,
        title: 'Энергия рельефа и сила тяжести',
        items: []
      },
      {
        id: 23,
        title: 'Поверхностные воды',
        items: []
      },
      {
        id: 24,
        title: 'Подземные и поверхностные воды',
        items: []
      },
      {
        id: 26,
        title: 'Промерзание и оттаивание горных пород',
        items: []
      }
    ],
    filter: 2
  }
]

export const parseGeoProcessesList = (list = []) => {
  const cloneList = cloneDeep(geoProcessesTypes)

  list.forEach((e) => {
    const firstIndex = String(e.id).charAt(0)
    cloneList.forEach((item) => {
      if (item.id === 'endogenous' && firstIndex === '1') {
        item.items.push(e)
      } else if (firstIndex === '2' && item.id === 'exogenous') {
        const firstTwoNum = Number(String(e.id).substring(0, 2))
        item.items.forEach((i) => {
          if (i.id === firstTwoNum) {
            i.items.push(e)
          }
        })
      }
    })
  })

  return cloneList
}
