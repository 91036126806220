<script setup>
import { useServicesStore } from '@/stores'
import { capitalizeFirstLetter, getSourceTitle } from '@/utils'
import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'

defineProps({
  group: {
    type: Object
  },
  activeTypes: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['change'])

const servicesStore = useServicesStore()

const clickHandler = (id) => {
  emits('change', id)
}

const getWikiTipData = (item) => {
  const { description, sources } = item

  if (!description) return null

  let parsedSource

  if (sources) {
    const splittedSources = sources.toString().split(';')

    parsedSource = splittedSources
      .map((s) => {
        const source = servicesStore.wiki.lib.find((item) => item.id === Number(s))

        if (!source) return ''
        return getSourceTitle(source)
      })
      .join(', ')
  }

  return {
    title: capitalizeFirstLetter(item.title),
    description: description ? capitalizeFirstLetter(description) : null,
    source: parsedSource
  }
}
</script>

<template>
  <div class="group-items">
    <div
      @click="clickHandler(item.id)"
      class="group-items__item"
      :class="{ active: activeTypes && activeTypes.includes(item.id) }"
      v-for="item in group.items"
      :key="item.id"
    >
      <s-text>
        {{ item.title_short }}
      </s-text>
      <wiki-tip v-if="getWikiTipData(item)" :data="getWikiTipData(item)">
        <div class="group-items__item__trigger">
          <s-icon name="fa-circle-question" color="var(--caption)" />
        </div>
      </wiki-tip>
    </div>
  </div>
</template>

<style lang="scss">
.group-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    cursor: pointer;
    background: var(--bg);
    border-radius: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 0;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__trigger {
      display: flex;
      align-items: center;
      padding: 10px 1rem;

      @include phones {
        padding: 10px 8px;
      }
    }

    &.active {
      background: var(--primary);
    }
  }
}
</style>
