<template>
  <div v-loading="true" class="app-not-ready" />
</template>

<style lang="scss">
.app-not-ready {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
