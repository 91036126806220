export const objectStatuses = {
  1: 'Не начат',
  2: 'В работе',
  3: 'Завершен',
  4: 'Отложен'
}

export const objectStatusesColors = {
  1: 'default',
  2: 'primary',
  3: 'success',
  4: 'warning'
}

export const excavationStatuses = {
  1: 'Не начата',
  2: 'Вынесена',
  3: 'Бурение',
  4: 'Закрыта'
}

export const excavationStatusesColors = {
  1: 'default',
  2: 'orange',
  3: 'primary',
  4: 'success'
}
