<script setup>
import { computed, ref, inject } from 'vue'
import { useMq } from 'vue3-mq'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useVuelidate } from '@vuelidate/core'
import { objectValidator } from './validator'
import { commentValudator } from '@/validators'

import { useMainStore } from '@/stores'
import router from '@/router'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import { ObjectEditorSteps, objectTemplate } from './config'

import StepsList from './components/steps/steps-list.vue'

const mq = useMq()
const { postRequest } = useRequests()
const mainStore = useMainStore()
const $notify = inject('$notify')

defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['toggle'])

const loading = ref(false)
const currentObjectTemplate = ref(cloneDeep(objectTemplate))

const filteredObjectEditorSteps = ObjectEditorSteps.filter((step) => !step.editorOnly)

const hasChanges = computed(() => {
  return !isEqual(objectTemplate, currentObjectTemplate.value)
})

const toggleModal = () => {
  emits('toggle')
}

const currentStep = ref(1)

const totalSteps = computed(() => {
  return filteredObjectEditorSteps.length
})

const MAX_PROGRESS_VALUE = 100

const currentProgressValue = computed(() => {
  return (MAX_PROGRESS_VALUE / totalSteps.value) * currentStep.value
})

const activeComponent = computed(() => {
  return filteredObjectEditorSteps.find((step) => step.step === currentStep.value)
})

const changeStep = async (value) => {
  const result = await v$.value.$validate()

  if (!result) {
    return
  }

  currentStep.value += value
}

const isLastStep = computed(() => {
  return currentStep.value === totalSteps.value
})

const clickHandler = () => {
  createObject(currentObjectTemplate.value, toggleModal)
}

const rules = computed(() => {
  return {
    ...objectValidator(),
    ...commentValudator
  }
})

const v$ = useVuelidate(rules, currentObjectTemplate.value)

const createObject = async (object, callback) => {
  loading.value = true
  try {
    let data = cloneDeep(object)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      const response = await postRequest('objects/', data)
      if (!response) return
      data = response
      data.server_id = response?.id
      delete data.id
    }

    await db.addObject('objects', data)

    await router.push(`/app/data/objects/${data.server_id}`)

    const title = 'Создание'
    const message = `Проект "${data.title_short}" успешно создан`
    $notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback()
    }
  } catch (error) {
    const title = 'Создание'
    const message = `Ошибка при создании проекта ${error}`
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

const isNextBtnDisabled = computed(() => {
  const requiredFields = ['title', 'title_short', 'num']

  return requiredFields.some((field) => !currentObjectTemplate.value[field])
})

const defaultModalMinHeight = 712

const calcMinHeight = computed(() => {
  let screentHeight = window.innerHeight

  if (screentHeight < defaultModalMinHeight) {
    return null
  }

  return defaultModalMinHeight
})
</script>

<template>
  <s-modal
    :maxWidth="720"
    mainClass="object-create-modal"
    title="Создание проеĸта"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    confirm-on-cancel
    :min-height="calcMinHeight"
    :confirm-condition="hasChanges"
    @close="toggleModal"
  >
    <template #header>
      <s-progress :value="currentProgressValue">
        <s-text type="secondary" small>Шаг: {{ currentStep }} / {{ totalSteps }}</s-text>
      </s-progress>
    </template>
    <div class="object-create">
      <div class="object-create__content">
        <steps-list
          v-if="mq.current === 'lg'"
          :steps="filteredObjectEditorSteps"
          isObjectCreate
          :current-step="currentStep"
        />
        <div class="object-create__step-content">
          <component
            :vuelidate="v$"
            :step="activeComponent"
            :fields="activeComponent.fields"
            :template="currentObjectTemplate"
            @change-step="changeStep"
            :is="activeComponent.component"
          />
          <div class="object-create__footer">
            <s-button v-if="currentStep !== 1" @click="changeStep(-1)" stretch>Назад</s-button>
            <s-button
              v-if="!isLastStep"
              :disabled="isNextBtnDisabled"
              @click="changeStep(1)"
              type="primary"
              stretch
            >
              Далее
            </s-button>
            <s-button v-else :loading="loading" @click="clickHandler" type="success" stretch
              >Создать</s-button
            >
          </div>
        </div>
      </div>
    </div>
  </s-modal>
</template>

<style lang="scss">
.object-create-modal {
  .s-modal {
    &__content {
      padding: 0 !important;
      grid-gap: 24px;
    }

    &__service-header {
      padding: 24px 24px 0 24px;
    }

    &__header {
      padding: 0 24px 0 24px;
    }

    &__footer {
      padding: 24px;
    }

    &__body {
      overflow: hidden !important;
    }
  }
}
.object-create {
  height: 100%;
  padding-left: 24px;
  border-top: 1px solid var(--bg);
  overflow: auto;

  @media (max-width: 640px) {
    padding-left: 0;
  }

  &__footer {
    display: flex;
    gap: 24px;
    padding: 24px 0;
  }

  &__step-content {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }

  &__content {
    display: grid;
    grid-template-columns: 260px auto;
    height: 100%;

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }

    & .steps-list {
      padding: 24px 0;
      border-right: 1px solid var(--bg);
    }
  }
}
</style>
