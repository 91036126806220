import { getFromService } from './index'

export const getClayDescription = (parent, data) => {
  let soilDescription =
    parent?.soilName +
    (parent?.comment ? `, ${parent?.comment?.toLowerCase()}` : '') +
    (parent?.color ? `, ${parent?.color}` : '') +
    (parent?.colorComment ? `, ${parent?.colorComment?.toLowerCase()}` : '') +
    (parent?.waterSaturation ? `, ${parent?.waterSaturation}` : '') +
    (parent?.waterSaturationComment ? `, ${parent?.waterSaturationComment?.toLowerCase()}` : '')

  if (parent?.soilSource) {
    const structItems = structAttrs.map((e) => {
      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredStructItems = structItems.filter((e) => e)

    if (filteredStructItems?.length) {
      filteredStructItems.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  return soilDescription
}

const structAttrs = [
  { id: 'struct_thaw_weight', service: 'structs_thaw_weight' },
  { id: 'struct_thaw_grains', service: 'structs_thaw_grains' },
  { id: 'struct_thaw_joint', service: 'structs_thaw_joint' },
  { id: 'struct_thaw_porosity', service: 'structs_thaw_porosity' },
  { id: 'text_thaw', service: 'texts_thaw' },
  { id: 'struct_text_comments' }
]
