<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { onMounted, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getAllChanges, saveDataToLS, checkUnsyncedData } from '@/utils'
import { useMainStore, useSyncStore } from '@/stores'
import { toggleBodyTheme } from '@/utils'

const { needRefresh } = useRegisterSW()

const mainStore = useMainStore()
const syncStore = useSyncStore()

const route = useRoute()
const router = useRouter()

const setVHValue = () => {
  const vh = window.innerHeight * 0.01

  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const setIsOnline = (e) => {
  const { type } = e

  mainStore.setIsOnline(type === 'online')
}

onMounted(async () => {
  toggleBodyTheme(mainStore.theme)

  await checkUnsyncedData()
  await getAllChanges()

  if (
    (syncStore.changesList?.length || syncStore.dataChangesList?.length) &&
    route.path !== '/app/home'
  ) {
    router.push('/app/home')
  }

  // handlers for CSS VH value and for online status
  setVHValue()
  window.addEventListener('resize', setVHValue)
  window.addEventListener('orientationchange', setVHValue)

  window.addEventListener('online', setIsOnline)
  window.addEventListener('offline', setIsOnline)
})

watch(
  () => needRefresh.value,
  () => {
    saveDataToLS('PWAUpdated', true)
  }
)

onUnmounted(() => {
  window.removeEventListener('resize', setVHValue)
  window.removeEventListener('orientationchange', setVHValue)

  window.removeEventListener('online', setIsOnline)
  window.removeEventListener('offline', setIsOnline)
})
</script>

<template>
  <router-view />
</template>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
</style>
