<template>
  <div class="files-templates">
    <s-text color="var(--caption)"> Скачать пример файла: </s-text>
    <a class="files-templates__example" href="/files/import_csv_template.csv" download> CSV </a>
    <a class="files-templates__example" href="/files/import_excel_template.xlsx" download> XLSX </a>
  </div>
</template>

<style lang="scss" scoped>
.files-templates {
  display: flex;
  align-items: center;
  gap: 12px;

  &__example {
    color: var(--primary);
    text-decoration: none;
  }
}
</style>
