<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const servicesStore = useServicesStore()

const soilTypes = computed(() => {
  return servicesStore?.soil?.soil_types
})

const soilType = computed(() => {
  return soilTypes.value?.find((e) => e.id === props.source?.soil_type)
})

const serviceName = computed(() => {
  if (props.source?.soil_type >= 11201 && props.source?.soil_type <= 11205) {
    return 'sand'
  } else if (props.source?.soil_type >= 12101 && props.source?.soil_type <= 12301) {
    return 'clay'
  } else if (props.source?.soil_type >= 20000 && props.source?.soil_type < 30000) {
    return 'rock'
  } else {
    return ''
  }
})

const initField = computed(() => {
  return {
    ...props.field,
    type: 'select',
    service: `ice_contents_${serviceName.value}`
  }
})

const services = computed(() => {
  const soilId = soilType.value?.id
  const soilClass = soilType.value?.soil_class
  const soilGroup = soilType.value?.soil_group
  const soilTypeLocal = soilType.value?.soil_type

  const serviceNameLocal = `ice_contents_${serviceName.value}`
  const service = servicesStore?.soil?.[serviceNameLocal]

  const serviceData =
    service?.filter((s) => {
      if (!s.soil_type && !s.soil_group && !s.soil_class && !s.soil_id) {
        return true
      }
      const suits = s.soil_type
        ? s.soil_class === soilClass && s.soil_group === soilGroup && s.soil_type === soilTypeLocal
        : s.soil_group
          ? s.soil_class === soilClass && s.soil_group === soilGroup
          : s.soil_class
            ? s.soil_class === soilClass
            : false

      if (s.soil_id?.includes(';')) {
        const [from, to] = s.soil_id.split(';')

        const between = soilId >= Number(from) && soilId <= Number(to)

        return (between || suits) && s.id
      } else {
        return (s.soil_id?.includes(String(soilId)) || suits) && s.id
      }
    }) || []

  return {
    [serviceNameLocal]: serviceData
  }
})
</script>

<template>
  <div class="soil-ice-content">
    <s-input-renderer :field="initField" :source="source" :services="services" />
  </div>
</template>

<style lang="scss" scoped>
.soil-ice-content {
  display: grid;
  grid-gap: 1rem;
}
</style>
