<script setup>
import { useMq } from 'vue3-mq'
import { onMounted, ref } from 'vue'
import { useMainStore } from '@/stores'
import { saveDataToLS, getDataFromLS } from '@/utils'
import { updates } from './config'

const mq = useMq()

const mainStore = useMainStore()

const currentVersion = ref(updates[0].version)

const closeModal = () => {
  mainStore.toggleUpdatesModal()
  saveDataToLS('version', currentVersion.value)
}

const checkVersion = () => {
  const LSVersion = getDataFromLS('version')

  if (!LSVersion || LSVersion !== currentVersion.value) {
    mainStore.toggleUpdatesModal()
  }
}

onMounted(() => {
  checkVersion()
})
</script>

<template>
  <s-modal
    title="Последние обновления"
    :show="mainStore.isShowUpdatesModal"
    :fullscreen="mq.current !== 'lg'"
    @close="closeModal"
  >
    <div class="soilbox-updates-modal">
      <div class="soilbox-updates-modal__block" v-for="update in updates" :key="update.title">
        <div class="soilbox-updates-modal__info">
          <div class="soilbox-updates-modal__info-title">
            <s-title type="title"> Soilbox {{ update.version }} </s-title>
            <s-tag value="НОВОЕ" type="green" v-if="update.lastUpdate" />
          </div>
          <s-text type="secondary">{{ update.date }}</s-text>
        </div>
        <div>
          <s-text semibold>Что изменилось:</s-text>
          <ul role="list">
            <li v-for="item in update.list" :key="item">
              <s-text>
                <div class="soilbox-updates-modal__item" v-html="item" />
              </s-text>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template #footer>
      <div>
        <s-button type="default" stretch @click="closeModal">Понятно</s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.soilbox-updates-modal {
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__block {
    color: var(--caption);
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    gap: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--section-border-color);

    &:last-child {
      border-bottom: none;
    }

    & ul {
      list-style-type: disc !important;
      padding-left: 32px !important;
      margin-top: 8px;

      & li {
        margin-bottom: 4px;
        list-style-type: disc !important;
      }
    }
  }

  &__item {
    span.su-code {
      padding: 0.125rem 0.25rem;
      border-radius: 0.25rem;
      background-color: var(--bg-lite);
      color: var(--info);
      line-height: 1.5rem;
    }

    span.su-beta {
      padding: 0.05rem 0.25rem;
      border-radius: 0.25rem;
      background: var(--gradient);
      color: #fff;
      line-height: 1.5rem;
      font-size: 0.75rem;
    }
  }
}
</style>
