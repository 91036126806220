<script setup>
import { ref, computed, onMounted } from 'vue'
import { useMq } from 'vue3-mq'
import isEqual from 'lodash.isequal'
import { useRoute } from 'vue-router'
import cloneDeep from 'lodash.clonedeep'
import {
  basicInformationFields,
  objectContractorsFields,
  objectFieldsEmployees,
  ObjectDeleteFields
} from './config'
import { editObjectHelper, deleteObjectHelper } from './helpers'
import BasicInformation from './components/steps/basic-information.vue'
import ContractorsInfo from './components/steps/contractors-info.vue'
import EmployeesInfo from './components/steps/employees-info.vue'
import DeleteObject from './components/steps/delete-object.vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  object: {
    type: Object,
    default: null
  }
})

const emits = defineEmits(['toggle', 'change'])

const mq = useMq()

const loading = ref(false)
const objectTemplateLocal = ref(null)
const initSource = ref(null)
const route = useRoute()

const hasChanges = computed(() => {
  if (initSource.value && objectTemplateLocal.value) {
    return !isEqual(initSource.value, objectTemplateLocal.value)
  }

  return false
})

onMounted(() => {
  init()
  initSource.value = cloneDeep(props.object)
})

const init = () => {
  objectTemplateLocal.value = cloneDeep(props.object)
}

const toggleModal = () => {
  emits('toggle')
}

const compMap = [
  {
    title: 'Основная информация',
    comp: BasicInformation,
    fields: basicInformationFields,
    id: 'basic-info'
  },
  {
    title: 'Контрагенты и сроки',
    comp: ContractorsInfo,
    fields: objectContractorsFields,
    id: 'contractors-info'
  },
  {
    title: 'Сотрудники и оборудование',
    comp: EmployeesInfo,
    fields: objectFieldsEmployees,
    id: 'employees-info'
  },
  {
    title: 'Удаление данных',
    comp: DeleteObject,
    fields: ObjectDeleteFields,
    id: 'delete-object'
  }
]

const changeActiveCollapse = (id) => {
  activeId.value = id
}

const activeId = ref('basic-info')

const editObject = async () => {
  loading.value = true
  await editObjectHelper(objectTemplateLocal.value)
  loading.value = false
  toggleModal()
}

const deleteObject = () => {
  const objectID = route.params.id
  deleteObjectHelper(objectID)
}
</script>

<template>
  <s-modal
    v-if="objectTemplateLocal"
    title="Редаĸтирование проеĸта"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    confirm-on-cancel
    :confirm-condition="hasChanges"
    @close="toggleModal"
  >
    <div class="object-editor-mobile">
      <s-collapse class="background">
        <s-collapse-item
          v-for="item in compMap"
          :key="item.id"
          @change="changeActiveCollapse"
          :active="activeId"
          :id="item.id"
          :title="item.title"
        >
          <div class="object-editor-mobile__block">
            <component :is="item.comp" :fields="item.fields" :template="objectTemplateLocal" />
            <s-button v-if="item.id === 'delete-object'" @click="deleteObject" type="error" stretch>
              Удалить проект
            </s-button>
          </div>
        </s-collapse-item>
      </s-collapse>
    </div>
    <template #footer>
      <s-button :loading="loading" @click="editObject" stretch type="success">
        {{ loading ? 'Изменение данных' : 'Сохранить изменения' }}
      </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.object-editor-mobile {
  display: grid;
  grid-gap: 2rem;
  align-content: start;
  overflow: auto;
  height: 100%;
  padding-right: 0.5rem;

  &__block {
    display: grid;
    grid-gap: 1rem;
    align-content: start;

    &--date {
      grid-auto-flow: column;
    }
  }
}

@include phones {
  .object-editor-mobile {
    &__block {
      &--date {
        grid-auto-flow: row;
      }
    }
  }
}
</style>
