export const statuses = {
  excavations: [
    { id: 'закрыта', label: 'Закрыта', flag: 'green' },
    { id: 'бурение', label: 'Бурение', flag: 'blue' },
    { id: 'вынесена', label: 'Вынесена', flag: 'orange' },
    { id: 'не начата', label: 'Не начата', flag: 'default' }
  ],
  recon: [
    { id: 'закрыта', label: 'Закрыта', flag: 'green' },
    { id: 'не начата', label: 'Не начата', flag: 'default' }
  ]
}
