<script setup>
import { computed, ref } from 'vue'
import { useObjectsStore } from '@/stores'
import {
  excavationsConfig,
  reconsConfig,
  headerExcavationsConfig,
  headerReconsConfig
} from '../../export-data/config'
import ExportDataList from './export-data-list.vue'
import DataStats from './data-stats.vue'

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true
  },
  dataType: {
    type: String,
    default: 'excavations'
  }
})

const objectsStore = useObjectsStore()

const emits = defineEmits(['close'])

const applyAndClose = () => {
  emits('close')
}

const list = computed(() => {
  const { excavationsToExport, reconsToExport } = objectsStore

  return props.dataType === 'excavations' ? excavationsToExport : reconsToExport
})

const modalTitle = computed(() => {
  return props.dataType === 'excavations' ? 'скважин' : 'точек наблюдения'
})

const headerConfig = computed(() => {
  return props.dataType === 'excavations' ? headerExcavationsConfig : headerReconsConfig
})

const dataConfig = computed(() => {
  return props.dataType === 'excavations' ? excavationsConfig : reconsConfig
})

const isEmptyList = ref(false)

const getIsEmpty = (val) => {
  isEmptyList.value = val
}
</script>

<template>
  <s-modal
    mainClass="export-data-modal"
    :title="`Выбор ${modalTitle}`"
    :show="isOpened"
    @close="emits('close')"
  >
    <div class="export-data-modal__content">
      <div class="export-data-modal__list">
        <export-data-list
          @empty-list="getIsEmpty"
          :header-config="headerConfig"
          :data="list"
          :config="dataConfig"
          :dataType
        />
      </div>
      <div class="export-data-modal__stats" v-if="!isEmptyList">
        <data-stats :dataType />
      </div>
    </div>

    <template #footer>
      <s-button type="primary" stretch @click="applyAndClose"> Применить </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.export-data-modal {
  overflow: hidden !important;

  & .s-modal__body {
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
  }

  &__list {
    flex: 1;
    min-height: 0;
    display: flex;
  }
}
</style>
