import {
  STitle,
  SButton,
  SText,
  SInput,
  SAlert,
  SIcon,
  STag,
  SPopTip,
  SLoading,
  STooltip,
  SBadge,
  SModal,
  SSelect,
  SCheckbox,
  SDiv,
  STab,
  STabs,
  SProgress,
  SDate,
  SNotify,
  SSwitch,
  SConfirm,
  SRadioGroup,
  SRadioButton,
  SUpload,
  SDropdown,
  SDropdownItem,
  SCollapse,
  SCollapseItem,
  SNumberInput,
  SBottomSheet,
  SSearchBlock
} from '@n966/soilbox_ui'
import '@n966/soilbox_ui/dist/style.css'

export default (app) => {
  app.component('SBottomSheet', SBottomSheet)
  app.component('SSearchBlock', SSearchBlock)
  app.component('SNumberInput', SNumberInput)
  app.component('SCollapse', SCollapse)
  app.component('SCollapseItem', SCollapseItem)
  app.component('SDropdown', SDropdown)
  app.component('SDropdownItem', SDropdownItem)
  app.component('SUpload', SUpload)
  app.component('SRadioGroup', SRadioGroup)
  app.component('SRadioButton', SRadioButton)
  app.component('SDate', SDate)
  app.component('SProgress', SProgress)
  app.component('STab', STab)
  app.component('STabs', STabs)
  app.component('SDiv', SDiv)
  app.component('SCheckbox', SCheckbox)
  app.component('SSelect', SSelect)
  app.component('SIcon', SIcon)
  app.component('SText', SText)
  app.component('STitle', STitle)
  app.component('SAlert', SAlert)
  app.component('SInput', SInput)
  app.component('SButton', SButton)
  app.component('STag', STag)
  app.component('SPopTip', SPopTip)
  app.component('SModal', SModal)
  app.component('SSwitch', SSwitch)

  app.directive('loading', SLoading)
  app.directive('tooltip', STooltip)
  app.directive('badge', SBadge)

  app.use(SNotify)
  app.use(SConfirm)
}
