<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores'
import { capitalizeFirstLetter, getSourceTitle } from '@/utils'
import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  active: {
    type: Boolean,
    default: false
  },
  hasCancel: {
    type: Boolean,
    default: false
  },
  end: {
    type: Number,
    default: 1
  },
  noTip: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['cancel', 'handler'])

const servicesStore = useServicesStore()

const computedWikiTipData = computed(() => {
  if (!props.item) return null

  const { description, images, sources } = props.item

  if (!description && !images) return null

  const parsedTitle = getTitle()
  let parsedSource

  if (sources) {
    const splittedSources = sources.toString().split(';')

    parsedSource = splittedSources
      .map((s) => {
        const source = servicesStore.wiki.lib.find((item) => item.id === Number(s))

        if (!source) return ''
        return getSourceTitle(source)
      })
      .join(', ')
  }

  return {
    title: parsedTitle ? capitalizeFirstLetter(parsedTitle) : null,
    description: description ? capitalizeFirstLetter(description) : null,
    image: images,
    source: parsedSource
  }
})

const cancelSelectItem = () => {
  emits('cancel')
  emits('handler', props.item)
}

const getTitle = () => {
  let title = props?.item?.title
  const end = props?.item?.[`end${props.end}`] || null

  if (title && end) {
    title += end
  }

  return props?.item?.title_short || title || '-'
}
</script>

<template>
  <div :class="['soil-select-item', { active }]">
    <div class="soil-select-item__text" @click="emits('handler', item)">
      <s-text v-if="item" ellipsis>
        {{ getTitle() }}
      </s-text>
    </div>
    <wiki-tip v-if="!active && !noTip && computedWikiTipData" :data="computedWikiTipData">
      <div class="soil-select-item__trigger">
        <s-icon name="fa-circle-question" color="var(--caption)" />
      </div>
    </wiki-tip>
    <s-button
      v-if="hasCancel"
      class="soil-select-item__cancel"
      icon="xmark"
      simple
      @click="cancelSelectItem"
    />
  </div>
</template>

<style lang="scss">
.soil-select-item {
  grid-template-columns: 1fr auto;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  background-color: var(--bg);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  &__trigger {
    display: flex;
    align-items: center;
    padding: 10px 1rem;
    @include phones {
      padding: 10px 8px;
    }
  }

  &__text {
    padding: 10px 0 10px 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include phones {
      padding: 10px 0 10px 8px;
    }
  }

  &.active {
    background-color: var(--primary);

    .s-text,
    .s-button__icon {
      color: var(--white-text);
    }
  }

  &__cancel.s-button {
    height: auto !important;
    width: auto !important;
    padding: 10px 1rem !important;

    @include phones {
      padding: 10px 8px !important;
    }

    &:hover {
      background-color: initial !important;
    }
  }
}
</style>
