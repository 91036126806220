<script setup>
import { useSlots } from 'vue'
defineProps({
  title: {
    type: String,
    default: null
  },
  backButton: {
    type: Boolean,
    default: false
  },
  contentFlex: {
    type: Boolean,
    default: false
  },
  actions: {
    type: Array,
    default: () => null
  }
})

const emits = defineEmits(['back'])

const slots = useSlots()
</script>

<template>
  <div :class="['card-layout', { flex: contentFlex }]">
    <div class="card-layout-header">
      <s-button
        v-if="backButton"
        icon="chevron-left"
        simple
        class="card-layout-header__left-button"
        @click="emits('back')"
      />
      <s-title v-if="title"> {{ title }} </s-title>
      <div class="card-layout-header__actions">
        <slot name="header-actions" />
      </div>
    </div>
    <div :class="['card-layout-content', { flex: contentFlex }]">
      <slot />
    </div>
    <div v-if="slots.footer" class="card-layout-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss">
.card-layout {
  position: relative;
  display: grid;
  grid-gap: 2rem;
  height: 100%;
  overflow: hidden;
  align-content: start;
  padding: 24px;

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    min-height: 40px;

    &__actions {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      align-items: center;
    }

    &__buttons {
      display: flex;
      gap: 16px;
      & .card-layout-header__right-button svg {
        font-size: 1.2rem;
      }
    }
  }

  &-content {
    display: grid;
    align-content: start;
    height: 100%;
    grid-gap: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.flex {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-content: flex-start;

    .card-layout-content {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      height: 100%;
      gap: 2rem;
    }
  }
}

@include tablets {
  .card-layout {
    padding: 16px;
  }
}

@include phones {
  .card-layout {
    padding: 16px;

    &-header {
      justify-content: space-between;
      padding: 0;

      &__left-button {
        position: relative;
        top: auto;
        left: auto;
        transform: translateY(0);
      }

      .card-layout-header__actions {
        position: relative;
        right: auto;
        top: auto;
        transform: translateY(0);
      }
    }
    &-content {
      grid-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
}
</style>
