import contextMenu from '../context-menu/context-menu'

export const reconsStaticColumns = [
  {
    id: 'general',
    label: 'Общее',
    attributes: [
      {
        type: 'text',
        data: 'title',
        title: 'Номер',
        key: 'title',
        readOnly: true,
        disabled: true
      },
      {
        type: 'dropdown',
        data: 'status',
        title: 'Статус',
        source: 'status',
        key: 'status'
      },
      {
        type: 'text',
        data: 'site',
        title: 'Участок',
        key: 'site'
      },
      {
        title: 'Местоположение',
        type: 'text',
        key: 'location',
        data: 'location'
      },
      {
        title: 'Геоморфология',
        type: 'dropdown',
        service: 'geomorphies',
        key: 'geomorph_id',
        data: 'geomorph_id'
      },
      {
        title: 'Комментарий к геоморфологии',
        type: 'text',
        key: 'geomorph_comments',
        data: 'geomorph_comments'
      },
      {
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        datePickerConfig: {
          i18n: {
            previousMonth: 'Прошлый месяц',
            nextMonth: 'След месяц',
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь'
            ],
            weekdays: [
              'Воскресенье',
              'Понедельник',
              'Вторник',
              'Среда',
              'Четверг',
              'Пятница',
              'Суббота'
            ],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
          }
        },
        correctFormat: true,
        title: 'Дата',
        key: 'date_end',
        data: 'date_end'
      },
      {
        type: 'dropdown',
        title: 'Геолог',
        source: '',
        key: 'geologist',
        data: 'geologist'
      }
    ]
  },
  {
    id: 'location',
    label: 'Местоположение',
    attributes: [
      {
        type: 'text',
        title: 'Широта (план), град.',
        key: 'lat_plan',
        units: 'град.',
        data: 'lat_plan',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Широта (план), ГМС',
        editor: 'degreesEditor',
        key: 'lat_plan_dms',
        units: 'ГМС',
        data: 'lat_plan_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'Долгота (план), град.',
        key: 'lon_plan',
        units: 'град.',
        data: 'lon_plan',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Долгота (план), ГМС',
        editor: 'degreesEditor',
        units: 'ГМС',
        key: 'lon_plan_dms',
        data: 'lon_plan_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'Широта (факт), град.',
        key: 'lat',
        units: 'град.',
        data: 'lat',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Широта (факт), ГМС',
        editor: 'degreesEditor',
        units: 'ГМС',
        key: 'lat_dms',
        data: 'lat_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'Долгота (факт), град.',
        key: 'lon',
        units: 'град.',
        data: 'lon',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Долгота (факт), ГМС',
        editor: 'degreesEditor',
        units: 'ГМС',
        key: 'lon_dms',
        data: 'lon_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'X MSK (план.), м',
        key: 'msk_x_plan',
        units: 'м',
        data: 'msk_x_plan'
      },
      {
        type: 'text',
        title: 'Y MSK (план.), м',
        key: 'msk_y_plan',
        units: 'м',
        data: 'msk_y_plan'
      },
      { type: 'text', title: 'X MSK (факт.), м', key: 'msk_x', units: 'м', data: 'msk_x' },
      { type: 'text', title: 'Y MSK (факт.), м', key: 'msk_y', units: 'м', data: 'msk_y' },
      // Абс отметка
      { type: 'text', title: 'Абс. отм., м', key: 'abs', units: 'м', data: 'abs' },
      { type: 'text', title: 'Абс. отм. (MSK), м', key: 'msk_abs', units: 'м', data: 'msk_abs' }
    ]
  },
  {
    id: 'natural',
    label: 'Описание',
    attributes: [
      {
        type: 'text',
        data: 'processes',
        title: 'Процессы',
        key: 'processes',
        readOnly: true
      },
      {
        type: 'text',
        data: 'exposures',
        title: 'Обнажения',
        key: 'exposures',
        width: 300
      },
      {
        type: 'text',
        data: 'waterfronts',
        title: 'Водопроявления',
        key: 'waterfronts',
        width: 300
      },
      {
        type: 'text',
        data: 'geobotany',
        title: 'Геоботанические индикаторы',
        key: 'geobotany',
        width: 300
      },
      {
        type: 'text',
        data: 'polls',
        title: 'Опрос местного населения',
        key: 'polls',
        width: 300
      },
      {
        key: 'inspections',
        data: 'inspections',
        title: 'Обследование объектов',
        type: 'text',
        width: 300
      }
    ]
  },
  {
    id: 'other',
    label: 'Другое',
    attributes: [
      {
        type: 'text',
        data: 'comments',
        title: 'Комментарий к точке',
        key: 'comments',
        width: 350
      }
    ]
  }
]

export const initialReconColumns = [
  'title',
  'status',
  'site',
  'lat_plan',
  'lon_plan',
  'lat',
  'lon',
  'geologist',
  'exposures',
  'waterfronts',
  'geobotany',
  'polls',
  'comments'
]

export const soilsListTips = {
  title: 'Список точек наблюдения пуст',
  description: 'В данном проекте отсутствуют добавленные точки наблюдения'
}

export const sheetsSettings = {
  filters: true,
  columnSorting: true,
  fixedColumnsStart: 1,
  selectionMode: 'multiple',
  rowHeaders: true,
  height: 'calc(100vh - 360px)',
  outsideClickDeselects: false,
  contextMenu: contextMenu,
  copyPaste: {
    copyColumnHeaders: false
  },
  search: {
    searchResultClass: 'searched-values'
  },
  dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar']
}
