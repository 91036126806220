export const rockSteps = {
  main: [
    {
      id: 'cement',
      value: 'cement',
      title: 'Укажите состав цемента грунта',
      type: 'select',
      service: 'cements',
      commentField: 'cement_comments'
    },
    {
      id: 'color',
      value: 'color',
      title: 'Укажите цвет грунта',
      type: 'color-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Цвет грунта',
        description:
          'Цвет грунта описывают в естественном состоянии, при полном дневном освещении. Допускается описание цвета при искусственном освещении с использованием ламп дневного света или при любом освещении с применением сравнительного образца или эталона, цветовых шкал. Для объективного описания цветов рекомендуется пользоваться цветовыми шкалами (палетками), например выполненными по системе Манселла, или другими системами, специализированными для определения цвета грунтов (например, цветовая таблица грунтов S133N). Цвет скального грунта описывают во влажном (смоченном) состоянии. Если необходимо, цве­товые различия могут быть уточнены использованием таких терминов, как «пестрый», «пятнистый», «испещренный», «крапчатый», «полосчатый».',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'structure_texture',
      title: 'Укажите информацию о структуре',
      type: 'group-select',
      groups: [
        {
          id: 'struct_thaw_grains',
          value: 'struct_thaw_grains',
          title: 'Зерна / содержание частиц',
          type: 'select',
          service: 'structs_thaw_grains'
        },
        {
          id: 'text_thaw',
          value: 'text_thaw',
          title: 'Укажите текстуру грунта',
          type: 'select',
          service: 'texts_thaw'
        }
      ],
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к структуре / текстуре'
    },
    {
      id: 'alteration_rock1',
      title: 'Укажите информацию о степени измененности и прочности',
      type: 'group-select',
      groups: [
        {
          id: 'alteration_weath',
          value: 'alteration_weath',
          title: 'Степень выветрелости',
          type: 'select',
          service: 'alterations_weath'
        },
        {
          id: 'strength',
          value: 'strength',
          title: 'Прочность',
          type: 'select',
          service: 'strengths'
        },
        {
          id: 'density',
          value: 'density',
          title: 'Плотность',
          type: 'select',
          service: 'densities'
        }
      ],
      commentField: 'strength_comments',
      commentTitle: 'Комментарий к измененности и прочности'
    },
    {
      id: 'alteration_rock2',
      title: 'Укажите информацию о трещиноватости',
      type: 'group-select',
      groups: [
        {
          id: 'alteration_fractures_integrity',
          value: 'alteration_fractures_integrity',
          title: 'Степень сплошности',
          type: 'select',
          service: 'alterations_fractures_integrity',
          wikiTipData: {
            color: 'placeholder',
            title: 'Степень сплошности',
            description:
              'Для подразделения массива скального грунта по степени сплошности следует руководствоваться отношением l/а, где l — средняя длина трещин, а — среднее расстояние между трещинами. Показателем КТП (коэффициент трещинной пустотности) следует пользоваться, если площадь естественного или искусственного обнажения (котлован, штольня и т. п.) не позволяет оценить реальные значения l и а.'
          }
        },
        {
          id: 'alteration_fractures_width',
          value: 'alteration_fractures_width',
          title: 'Ширина трещин',
          type: 'select',
          service: 'alterations_fractures_width'
        },
        {
          id: 'alteration_fractures_angles',
          value: 'alteration_fractures_angles',
          title: 'Ориентировка трещин',
          type: 'select',
          service: 'alterations_fractures_angles'
        },
        {
          id: 'alteration_fractures_compos',
          value: 'alteration_fractures_compos',
          title: 'Заполнитель трещин',
          type: 'select',
          service: 'alterations_fractures_compos'
        }
      ],
      commentField: 'alteration_fractures_comments',
      commentTitle: 'Комментарий к трещиноватости',
      additionalTrigger: true,
      createTrigger: true
    }
  ],
  additional: [
    {
      id: 'composition_chem',
      value: 'composition_chem',
      title: 'Укажите минеральный состав',
      type: 'select',
      service: 'compositions_chem'
    },
    {
      id: 'interlayers',
      value: 'interlayers',
      title: 'Прослои',
      type: 'interlayers'
    },
    {
      id: 'additional',
      title: 'Укажите дополнительную информацию',
      type: 'additional'
    }
    // {
    //   id: 'sample_info',
    //   value: 'sample_info',
    //   title: 'Добавьте информацию о керне',
    //   type: 'sample-info',
    //   createTrigger: true
    // }
  ]
}
