import { getFromService } from './index'

export const getFrozenDescription = (parent, data) => {
  const isTechno = !!data.alteration_techno

  let soilDescription =
    parent?.soilName +
    (parent?.stateName ? `, ${parent?.stateName}` : '') +
    (parent?.comment ? `, ${parent?.comment?.toLowerCase()}` : '') +
    (parent?.color ? `, ${parent?.color}` : '') +
    (parent?.colorComment ? `, ${parent?.colorComment?.toLowerCase()}` : '')

  if (parent?.soilSource) {
    if (isTechno) {
      const technoItems = technoAttrs.map((e) => {
        return getFromService(parent?.soilSource, data, e, parent?.soilServices)
      })

      const filteredTechnoItems = technoItems.filter((e) => e)

      if (filteredTechnoItems?.length) {
        filteredTechnoItems.forEach((e) => {
          soilDescription = `${e}, ${soilDescription}`
        })
      }

      soilDescription = `Техногенный грунт, ${soilDescription.toLowerCase()}`
    }

    const textItems = textAttrs.map((e) => {
      const value = getFromService(parent?.soilSource, data, e, parent?.soilServices)
      return value && e.prefix ? `${e.prefix} ${value}` : value
    })

    const filteredTextItems = textItems.filter((e) => e)

    if (filteredTextItems?.length) {
      filteredTextItems.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  if (data) {
    if (data?.ice_schliere_width_from && data?.ice_schliere_width_to) {
      soilDescription += `, ширина шлиров от ${
        data?.ice_schliere_width_from || '-'
      } до ${data?.ice_schliere_width_to || '-'} мм`
    } else if (data?.ice_schliere_width_from) {
      soilDescription += `, ширина шлиров от ${data?.ice_schliere_width_from || '-'} мм`
    } else if (data?.ice_schliere_width_to) {
      soilDescription += `, ширина шлиров до ${data?.ice_schliere_width_to || '-'} мм`
    }

    if (data?.ice_schliere_interval_from && data?.ice_schliere_interval_to) {
      soilDescription += `, расстояние между шлирами от ${
        data?.ice_schliere_interval_from || '-'
      } до ${data?.ice_schliere_interval_to || '-'} мм`
    } else if (data?.ice_schliere_interval_from) {
      soilDescription += `, расстояние между шлирами от ${
        data?.ice_schliere_interval_from || '-'
      } мм`
    } else if (data?.ice_schliere_interval_to) {
      soilDescription += `, расстояние между шлирами до ${data?.ice_schliere_interval_to || '-'} мм`
    }

    if (data?.struct_text_comments) {
      soilDescription += `, ${data?.struct_text_comments?.toLowerCase()}`
    }
  }

  if (parent?.soilSource) {
    const moreAttrs = [
      {
        id: 'ice_content',
        service: iceContentServiceName(data)
      },
      {
        id: 'ice_content_comments'
      },
      { id: 'state_pfrost', service: 'states_pfrost' },
      { id: 'water_saturation', service: pFrostServiceName(data) },
      { id: 'water_saturation_comments' }
    ]

    const moreAttrsItems = moreAttrs.map((e) => {
      const info = getFromService(parent?.soilSource, data, e, parent?.soilServices)

      if (e.id === 'water_saturation') {
        return info ? `при оттаивании ${info}` : ''
      } else {
        return info
      }
    })

    const filteredMoreAttrsItems = moreAttrsItems.filter((e) => e)

    if (filteredMoreAttrsItems?.length) {
      filteredMoreAttrsItems.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  return soilDescription
}

const technoAttrs = [
  { id: 'alteration_techno_type', service: 'alterations_techno_types', titleEnd: 'end1' }
]

const textAttrs = [{ id: 'text_pfrost', service: 'texts_pfrost', prefix: 'криотекстура' }]

const iceContentServiceName = (data) => {
  if (data?.soil_type >= 11201 && data?.soil_type <= 11205) {
    return 'ice_contents_sand'
  } else if (data?.soil_type >= 12101 && data?.soil_type <= 12301) {
    return 'ice_contents_clay'
  } else if (data?.soil_type >= 20000 && data?.soil_type < 30000) {
    return 'ice_contents_rock'
  } else {
    return ''
  }
}

const pFrostServiceName = (data) => {
  if (data?.soil_type >= 11201 && data?.soil_type <= 11205) {
    return 'water_saturations_sand'
  } else if (data?.soil_type >= 12102 && data?.soil_type <= 12103) {
    return 'water_saturations_clay'
  } else if (data?.soil_type === 12101) {
    return 'water_saturations_sandy_clay'
  } else {
    return 'water_saturations_other'
  }
}
