<script setup>
import { ref, computed, reactive, onMounted, watch } from 'vue'
import { useVirtualList } from '@vueuse/core'
import cloneDeep from 'lodash.clonedeep'

import { useObjectsStore } from '@/stores'
import { filterData, filterAndSortList, mappedGeologists, prepareGeologistFilter } from '@/utils'
import { filtersConfig } from '../../export-data/config'
import ListHeader from './list-header.vue'
import ListItem from './list-item.vue'

const objectsStore = useObjectsStore()

const props = defineProps({
  data: {
    type: Array,
    default: null
  },
  config: {
    type: Array,
    default: null
  },
  headerConfig: {
    type: Array,
    default: null
  },
  dataType: {
    type: String,
    default: 'excavations'
  }
})

const emits = defineEmits(['empty-list'])

const filterText = ref('')
let filtersLocal = reactive(cloneDeep(filtersConfig))

const filteredData = computed(() => {
  const { excavationsToExport, reconsToExport } = objectsStore
  const filterTextValue = filterText.value?.trim()?.toLowerCase()
  let { filters, sort } = filtersLocal
  if (!props.data) return

  const filteredList = filterData(props.data, filters)
  const sortedList = filterAndSortList(filteredList, filterTextValue, sort, ['title', 'site'])

  const dataIds = sortedList.map((excav) => excav.id)

  let exportList = props.dataType === 'excavations' ? excavationsToExport : reconsToExport
  exportList.forEach((item) => {
    if (dataIds.includes(item.id)) {
      item.checked = true
    } else {
      item.checked = false
    }
  })

  return sortedList
})

const { list, containerProps, wrapperProps } = useVirtualList(filteredData, {
  itemHeight: 37
})

const changeHandler = (checked, indeterminate) => {
  if (indeterminate) {
    filteredData.value.forEach((e) => {
      e.checked = true
    })

    return
  }

  if (checked) {
    filteredData.value.forEach((e) => {
      e.checked = false
    })
  } else {
    filteredData.value.forEach((e) => {
      e.checked = true
    })
  }
}

const object = computed(() => {
  return objectsStore?.activeObject || null
})

watch(
  () => object?.value?.actual_geologists,
  (newValue) => {
    const geologists = mappedGeologists(newValue)
    prepareGeologistFilter(geologists, filtersLocal)
  }
)

onMounted(() => {
  const geologists = object?.value?.actual_geologists
  const mapGeologists = mappedGeologists(geologists)
  prepareGeologistFilter(mapGeologists, filtersLocal)

  if (props.dataType === 'recons') {
    filtersLocal.filters = filtersLocal.filters.filter((f) => f.id !== 'status')
  }
})

const isActiveFilter = computed(() => {
  return filtersLocal.filters.some((item) => {
    return item.filterValues?.some((e) => !e.value)
  })
})

const isFilteredAndEmptyList = computed(() => {
  if (!filteredData.value.length && isActiveFilter.value) {
    emits('empty-list', true)
    return true
  }
  emits('empty-list', false)
  return false
})
</script>

<template>
  <div class="e-data-list">
    <div class="e-data-list__filter">
      <s-search-block
        :filter="filterText"
        :filters="filtersLocal"
        placeholder="Поиск по номеру или участку"
        @change="(e) => (filterText = e)"
      />
    </div>
    <div class="e-data-list__content" v-if="!isFilteredAndEmptyList">
      <list-header :data :header-config @select-change="changeHandler" />
      <div v-bind="containerProps">
        <ul v-bind="wrapperProps">
          <list-item v-for="{ data } in list" :source="data" :key="data.id" :config="config" />
        </ul>
      </div>
    </div>
    <div v-else>
      <s-text>Отсутствуют данные, соответствующие включенным фильтрам</s-text>
    </div>
  </div>
</template>

<style lang="scss">
.e-data-list {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  &__filter {
    margin-bottom: 16px;
  }

  &__virtual {
    display: grid;
    align-content: start;
    padding-right: 0.5rem;
    max-height: 100%;
    overflow: auto;
    padding-right: 0.5rem;

    & .wrap {
      & > div {
        border-bottom: 1px solid var(--bg);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
