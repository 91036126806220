<script setup>
import { useMainStore } from '@/stores'
import AuthForm from '@/modules/auth/components/auth-form.vue'

const mainStore = useMainStore()
</script>

<template>
  <div class="auth-page dark-theme">
    <div class="auth-page__overlay" />
    <div class="auth-page__content">
      <div class="auth-page__content-info">
        <div class="auth-page__content-text">
          <div class="auth-page__logo">
            <img src="@/assets/images/logo/soilbox_logotype_dark.png" alt="Soilbox" />
          </div>
          <div>
            <s-title>Система сбора и обработки полевых данных инженерных изысканий</s-title>
          </div>
          <div>
            <ul class="auth-page__content-list">
              <li>
                <img src="@/assets/images/auth-page/data_control.png" alt="" />
                <s-text>
                  <span>Полный контроль данных</span> от скважины <br />
                  до отчета
                </s-text>
              </li>
              <li>
                <img src="@/assets/images/auth-page/time_reduce.png" alt="" />
                <s-text>
                  <span>Сокращение трудозатрат</span> на обработку первичных данных и выпуск отчета
                  <br />
                  <span>в 2-3 раза</span>
                </s-text>
              </li>
            </ul>
          </div>
        </div>
        <div class="auth-page__main-image">
          <img src="@/assets/images/auth-page/main-image.png" alt="logo" />
        </div>
      </div>
      <div class="auth-page__content-login">
        <div class="auth-page__content-logo">
          <img src="@/assets/images/logo/soilbox_logotype_dark.png" alt="logo" />
        </div>
        <div class="login">
          <auth-form />
        </div>
        <div v-if="mainStore.version" class="login__footer">
          <s-text center type="secondary"> v. {{ mainStore.version }}</s-text>
          <s-text center type="secondary"> 2024</s-text>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.auth-page {
  background: linear-gradient(-225deg, #2792ed, #55b77e);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  max-height: 100dvh;
  position: relative;

  @include phones {
    padding: 16px;
  }

  &__main-image {
    & img {
      display: none;
    }

    @media screen and (min-height: 776px) {
      & img {
        width: 100%;
        display: block;
      }
    }
  }

  &__overlay {
    background: rgb(30 35 43 / 50%) 50%;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__logo {
    img {
      height: 40px;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1024px;
    height: 100%;
    max-height: calc(100% - 133px);

    &-logo {
      display: none;

      img {
        height: 40px;
      }

      @include tablets {
        text-align: center;
        display: block;
      }
    }

    @include tablets {
      display: flex;
      height: 640px;
      max-width: 360px;
      justify-content: center;
    }

    @include phones {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 100%;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }

    &-list {
      & li {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 22px;

        & .s-text {
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0;
        }

        & span {
          color: var(--primary);
        }

        & img {
          max-width: 40px;
        }
      }
    }

    &-login {
      background-color: var(--card-bg);
      border-radius: 0 48px 48px 0;
      border: 1px solid var(--main-bg);
      padding: 64px 96px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include tablets {
        border-radius: 32px;
        padding: 32px 16px;
      }

      @include phones {
        height: 100%;
      }

      &__footer {
        text-align: center;
      }

      & .login {
        height: 100%;
        justify-content: center;
        display: flex;
      }
    }

    &-info {
      padding: 64px;
      border-radius: 48px 0 0 48px;
      border: 1px solid var(--main-bg);
      background-color: var(--main-bg);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include tablets {
        display: none;
      }
    }
  }
}
</style>
