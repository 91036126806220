<script setup>
import { computed } from 'vue'

const props = defineProps({
  steps: {
    type: Array,
    default: () => []
  },
  active: {
    type: Number,
    default: 0
  }
})

const percentage = computed(() => {
  const length = props.steps?.length

  return (props.active / length) * 100
})

const info = computed(() => {
  return `Шаг: ${props.active}/${props.steps?.length}`
})
</script>

<template>
  <div class="excavation-start-progress">
    <s-progress :value="percentage" />
    <s-text type="secondary">
      {{ info }}
    </s-text>
  </div>
</template>

<style lang="scss">
.excavation-start-progress {
  display: grid;
  grid-gap: 0.25rem;

  .s-text {
    margin-left: auto;
  }
}
</style>
