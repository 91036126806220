export const filtersConfig = {
  filters: [
    {
      id: 'type',
      showOnlyOnMobile: true,
      filterTitle: 'Тип данных',
      filterValues: [
        {
          id: 'excavations',
          title: 'Скважины',
          value: true
        },
        {
          id: 'recon',
          title: 'Точки наблюдения',
          value: true
        }
      ]
    },
    {
      id: 'status',
      filterTitle: 'Статусы выработок',
      filterValues: [
        {
          id: 1,
          title: 'Не начата',
          value: true
        },
        {
          id: 2,
          title: 'Вынесена',
          value: true
        },
        {
          id: 3,
          title: 'Бурение',
          value: true
        },
        {
          id: 4,
          title: 'Закрыта',
          value: true
        }
      ]
    },
    {
      id: 'geologist',
      filterTitle: 'Геолог',
      filterValues: []
    }
  ],
  sort: {
    value: 'title',
    order: 'asc',
    list: [
      {
        id: 'title',
        title: 'Номер'
      },
      {
        id: 'date',
        title: 'Дата бурения'
      }
    ]
  }
}
