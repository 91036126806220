import Dexie from 'dexie'
import { useExcavationStore, useObjectsStore } from '@/stores'
import cloneDeep from 'lodash.clonedeep'
import { dbConfig } from './config'

const db = new Dexie('soilboxIDB')

db.version(27).stores({
  dictionary: '++id, services',
  profile: '++id, profile',
  objects:
    '++id, title, title_short, num, status, date_start, date_end, client, contractor, comment, server_id, bore_machines, bore_masters, geologists, date_front',
  excavations:
    '++id, uuid, object_id, title, server_id, geologist, geologist_short_name, abs, abs_plan, artificial_f, bore_machine, bore_machine_title, bore_master, bore_master_title, comments, date, date_front, diam1, diam2, excav_type, geomorph_id, geomorph_comments, geomorph_title, h, h_plan, lat, lat_plan, lon, lon_plan, msk_abs, msk_x, msk_x_plan, msk_y, msk_y_plan, natural_f, site, status, type, casing, casing_meters',
  recons:
    '++id, uuid, object_id, title, server_id, site, url, status, lon_plan, lat_plan, lon, lat, abs, abs_plan, geologist, date_from, geomorph_id, geomorph_comments, date, geobotany, processes, processes_comments, comments, waterfronts, polls, exposures, images_url, journal_url',
  soils:
    '++id, uuid, excavation_id, server_id, inclusions, interlayers, soil_type, color_optional, color_main, color_comments, specific, filler_type, filler_amount, filler_comments, cement, cement_comments, state, state_pfrost, struct_thaw_grains, struct_thaw_joint, struct_thaw_porosity, struct_thaw_weight, struct_comments, text_pfrost, text_thaw, struct_text_comments, water_saturation, water_saturation_comments, density, density_comments, strength, strength_comments, composition_chem, composition_grains, composition_comments, debris_round, homogen, ice_type, ice_content, ice_schliere_interval_from, ice_schliere_interval_to, ice_schliere_width_from, ice_schliere_width_to, alteration_peat_decompos, alteration_weath, alteration_form_carbonate, alteration_form_salt, alteration_form_ferrum, alteration_form_gypsum, alteration_form_organic, alteration_minor_mud, alteration_minor_heav, alteration_minor_swell, alteration_minor_subsidence, alteration_minor_compaction, alteration_techno, alteration_techno_type, alteration_fractures_angles, alteration_fractures_compos, alteration_fractures_integrity, alteration_fractures_width, alteration_fractures_comments, alteration_comments, description, comments, foot_d, ord_num, date, date_front',
  samples:
    '++id, uuid, server_id, excavation_id, selection_from_d, selection_to_d, type, comments, field_number, date_front, date, url',
  groundwater:
    '++id, uuid, server_id, excavation_id, level_d, fixed_d, appear_date, fixed_date, date_front, comments',
  images:
    '++id, uuid, server_id, table, item_id, excavation_id, image, title, date_front, source_id, image_type',
  coreboxes:
    '++id, uuid, server_id, excavation_id, url, image_b64, image, image_url, thumbnail_url, title, foot_d0, foot_d1, comments, date, date_front',
  inclusions:
    '++id, server_id, soil_server_id, soil_id, d0, d1, composition, amount, type, comments, date',
  interlayers:
    '++id, server_id, soil_server_id, soil_id, d0, d1, type, composition, state_pfrost, water_saturation, comments, date',
  log: '++id, excavation_id, client_id, date_front, delta_value, current_height, action',
  deleted: '++id, table, item_id, delete_url, date, object_id, title, excavation_id, soil_id',
  updated: '++id, table, item_id, date',
  created: '++id, uuid, table, item_id, date'
})

db.updateStoreData = async (table, filter = {}) => {
  const excavationsStore = useExcavationStore()
  const objectsStore = useObjectsStore()

  try {
    const { field, value } = filter

    let response

    if (field && value) {
      response = await db[table].where(field).equals(value)?.toArray()
      if (typeof value !== 'number' && Number(value)) {
        const additional = await db[table].where(field).equals(Number(value)).toArray()
        response.push(...additional)
      }
      if (typeof value !== 'string') {
        const additional = await db[table].where(field).equals(String(value)).toArray()
        response.push(...additional)
      }
    } else if (table === 'objects') {
      response = await db[table].toArray()
    } else {
      response = []
    }

    const uniqueIds = {}
    const filteredItems = response.filter((item) => {
      if (uniqueIds[item.server_id]) {
        db[table].delete(item.id)
        return false
      }

      if (item.server_id) {
        uniqueIds[item.server_id] = true
      }

      return true
    })

    const { field: storeStateField } = dbConfig[table]

    switch (dbConfig[table].store) {
      case 'objects': {
        objectsStore.setField(storeStateField, filteredItems)
        break
      }
      case 'excavations': {
        excavationsStore.setField(storeStateField, filteredItems)
        break
      }
    }
  } catch (e) {
    throw new Error(e)
  }
}

db.addObject = async (table, data, filter = {}, noUpdate) => {
  try {
    const response = await db[table].add(data)
    if (!noUpdate) {
      await db.updateStoreData(table, filter)
    }
    return response
  } catch (e) {
    throw new Error(e)
  }
}

db.deleteObject = async (table, id) => {
  try {
    await db[table].delete(id)
  } catch (e) {
    throw new Error(e)
  }
}

db.updateObject = async (table, data, filter = {}, noUpdate) => {
  const cloneData = cloneDeep(data)

  try {
    await db[table].put(cloneData)
    if (!noUpdate) {
      await db.updateStoreData(table, filter)
    }
  } catch (e) {
    throw new Error(e)
  }
}

export default db
