export const reconTemplate = {
  title: null,
  type: 201,

  site: null,
  status: 1,
  comments: null,
  geologist: null,

  lon_plan: null,
  lat_plan: null,
  lon: null,
  lat: null,
  abs: null,
  abs_plan: null,

  date: null,
  date_front: null,

  geomorph_id: null,
  geomorph_comments: null,
  processes: [],
  processes_comments: null,
  geobotany: null,
  waterfronts: null,
  polls: null,
  exposures: null,
  inspections: null,
  location: null,
  sync_status: 0,
  sync_progress: []
}

export const basicFields = [
  {
    id: 1,
    title: 'title',
    label: 'Номер',
    required: true,
    type: 'string',
    placeholder: 'Т-100'
  },
  {
    id: 2,
    title: 'status',
    label: 'Статус',
    required: true,
    type: 'select',
    service: 'statuses'
  },
  {
    id: 3,
    title: 'geologist',
    label: 'Геолог',
    type: 'select',
    service: 'object_users'
  },
  {
    id: 4,
    title: 'date_end',
    label: 'Дата',
    placeholder: '01.01.2022',
    type: 'date',
    format: 'dd.MM.yyyy',
    setCurrentDate: true
  },
  {
    id: 5,
    title: 'site',
    label: 'Участок работ',
    type: 'string',
    placeholder: 'ПАД'
  },
  {
    id: 6,
    title: 'comments',
    label: 'Комментарий к точке',
    type: 'textarea'
  },
  {
    id: 7,
    title: 'sync_status',
    label: 'Статус синхронизации',
    type: 'select',
    service: 'sync_statuses',
    concat_by: 'sync_progress'
  }
]

export const locationFields = [
  {
    id: 7,
    title: 'lat_plan',
    label: 'Широта',
    required: true,
    type: 'number',
    placeholder: '55.663',
    width: 'half',
    min: -90,
    max: 90
  },
  {
    id: 8,
    title: 'lon_plan',
    label: 'Долгота',
    required: true,
    type: 'number',
    placeholder: '-37.658',
    width: 'half',
    min: -180,
    max: 180
  },
  {
    id: 9,
    title: 'abs_plan',
    label: 'Абсолютная отметка, м',
    type: 'number',
    placeholder: '156.42',
    min: 0
  }
]

export const descriptionFields = [
  {
    id: 10,
    title: 'location',
    label: 'Местоположение',
    type: 'string'
  },
  {
    id: 11,
    title: 'geomorph_id',
    label: 'Геоморфология',
    type: 'select',
    service: 'geomorphies',
    wikiTipData: {
      color: 'placeholder',
      title: 'Геоморфология',
      description: 'Проводится визуальная оценка рельефа',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 12,
    title: 'geomorph_comments',
    label: 'Комментарий к геоморфологии',
    type: 'string'
  },
  {
    id: 13,
    title: 'processes',
    label: 'Инженерно-геологические процессы',
    type: 'select',
    service: 'types',
    multiple: true,
    wikiTipData: {
      color: 'placeholder',
      title: 'Процессы',
      description:
        'Проводится описание и фотофиксация внешних проявлений опасных геологических и инженерно-геологических процессов',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 14,
    title: 'processes_comments',
    label: 'Комментарий к ИГ процессам',
    type: 'string'
  },
  {
    id: 15,
    title: 'exposures',
    label: 'Обнажения',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Обнажения',
      description:
        'Проводится описание и фотофиксация имеющихся обнажений, в том числе карьеров, строительных выработок и др.',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 16,
    title: 'waterfronts',
    label: 'Водопроявления',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Водопроявления',
      description: 'Проводится описание и фотофиксация водопроявлений, водных объектов',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 17,
    title: 'geobotany',
    label: 'Геоботанические индикаторы',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Геоботанические индикаторы',
      description:
        'Проводится описание и фотофиксация геоботанических индикаторов гидрогеологических условий',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 18,
    title: 'polls',
    label: 'Опрос местного населения',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Опрос населения',
      description:
        'Проводится опрос местного населения (с записью на диктофон или в полевой журнал) о проявлении опасных геологических и инженерно-геологических процессов, об имевших место чрезвычайных ситуациях, связанных с природными явлениями (приих наличии)',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 19,
    title: 'inspections',
    label: 'Обследование объектов',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Обследование объектов',
      description:
        'Проводится обследование объектов, подвергшихся разрушению в результате воздействия природных (землетрясений, лавин, оползней и т. д.) и техногенных факторов; фиксация деформаций зданий, сооружений, опор линий электропередачи и связи, транспортных магистралей',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  }
]

export const locationFactFields = [
  {
    id: 16,
    title: 'lat',
    label: 'Широта',
    required: true,
    type: 'number',
    placeholder: '55.663',
    width: 'half',
    min: -90,
    max: 90
  },
  {
    id: 17,
    title: 'lon',
    label: 'Долгота',
    required: true,
    type: 'number',
    placeholder: '-37.658',
    width: 'half',
    min: -180,
    max: 180
  },
  {
    id: 18,
    title: 'abs',
    label: 'Абсолютная отметка, м',
    type: 'number',
    placeholder: '156.42',
    min: 0
  }
]

export const fieldsList = [
  {
    id: 2,
    title: 'Описание точки наблюдения',
    value: 'description',
    fields: descriptionFields
  }
]

export const basicItem = {
  id: 1,
  title: 'Базовая информация',
  value: 'basic',
  fields: basicFields
}
