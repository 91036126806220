<script async setup>
import { computed, onMounted, inject, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { loadObject } from '../object-editor/helpers'
import { useMq } from 'vue3-mq'

import db from '@/libs/db'
import { useObjectsStore, useServicesStore, useMainStore } from '@/stores'
import { checkStorage, updateStorage } from '@/libs/db/helpers'
import { useRequests } from '@/composables'
import { getDataFromLS, saveDataToLS } from '@/utils'
import { saveToRecentList } from '@/utils/recent'

import DataDesktopLayout from '@/layouts/desktop/data-desktop-layout.vue'
import DataMobileLayout from '@/layouts/mobile/data-mobile-layout.vue'
import ObjectHeader from './components/object-header.vue'
import ObjectDesktopNavigation from './components/object-navigation/object-desktop-navigation.vue'
import ObjectMobileNavigation from './components/object-navigation/object-mobile-navigation.vue'
import DataMap from '../../maps/data-map/data-map.vue'
import ExcavationEditor from '../../excavation/components/excavation-editor/excavation-editor.vue'
import ReconEditor from '@/modules/app/data/recon/components/recon-editor/recon-editor.vue'

const route = useRoute()
const router = useRouter()
const mq = useMq()

const serviceStore = useServicesStore()
const objectsStore = useObjectsStore()
const mainStore = useMainStore()
const { getRequest } = useRequests()

const $notify = inject('$notify')

const routesToLoadObject = [
  'sheets-excavations',
  'object-dashboard',
  'object-planning',
  'object-info'
]

watch(
  () => route.name,
  async (newValue, oldValue) => {
    if (routesToLoadObject.includes(oldValue) && newValue === 'object-data' && object.value) {
      await loadObject(object.value)
    }
  }
)

const isRemoveContentPadding = computed(() => {
  return route?.name === 'object-dashboard' || route?.name === 'object-planning'
})

const object = computed(() => {
  return objectsStore.activeObject
})

const objectId = computed(() => {
  return route.params.id
})

const objectTitle = computed(() => {
  return object.value?.title_short || '-'
})

const goBack = () => {
  router.push('/app/data/objects/list')
}

const services = computed(() => {
  const { users, bore_machines, bore_masters } = serviceStore

  return { users, bore_machines, bore_masters }
})

const excavationsList = computed(() => {
  return objectsStore.excavationsList || []
})

const reconsList = computed(() => {
  return objectsStore.reconsList || []
})

const isIdbObject = computed(() => {
  return String(objectId.value).includes('idb_')
})

const parsedObjectId = computed(() => {
  if (!objectId.value || objectId.value === 'undefined') {
    return false
  }
  return !isIdbObject.value ? Number(objectId.value) : Number(objectId.value.replace('idb_', ''))
})

const setProjectMetadata = ({ geologists, bore_machines, bore_masters }) => {
  const object_users = services.value?.users?.filter((e) => geologists?.find((g) => g === e.id))

  const object_bore_machines = services.value?.bore_machines?.filter((e) =>
    bore_machines?.find((g) => g === e.id)
  )

  const object_bore_masters = services.value?.bore_masters?.filter((e) =>
    bore_masters?.find((g) => g === e.id)
  )

  serviceStore.setService(['object_users', object_users])
  serviceStore.setService(['object_bore_machines', object_bore_machines])
  serviceStore.setService(['object_bore_masters', object_bore_masters])
}

onMounted(() => {
  if (!parsedObjectId.value) {
    const title = 'Ошибка'
    const message = `Некорректный идентификатор проекта. Обратитесь к администратору.`
    $notify({
      title,
      message,
      type: 'error'
    })

    objectsStore.setField('excavationsList', [])
    objectsStore.setField('reconsList', [])
    router.push('/app/not-found')
  } else {
    init()
  }

  const isCollapsed = getDataFromLS('isCollapsed')

  if (isCollapsed && !objectsStore.isCollapsed) {
    toggleCollapse()
  }
})

onUnmounted(() => {
  objectsStore.setField('excavationsList', [])
  objectsStore.setField('reconsList', [])
})

const init = async () => {
  mainStore.setLoading(true)

  if (mainStore.isOnline && !mainStore.noSyncMode) {
    await loadObjectOnline()
    await loadExcavsOnline()
    await loadReconsOnline()
  } else {
    await loadObjectOffline()
    await loadDataOffline('excavations')
    await loadDataOffline('recons')
  }

  mainStore.setLoading(false)
}

const loadObjectOnline = async () => {
  try {
    let object

    if (isIdbObject.value) {
      object = await db.objects.get(parsedObjectId.value)
    } else {
      const idbObject = await db.objects
        .where({
          server_id: parsedObjectId.value
        })
        .first()
      const idbId = idbObject?.id

      object = await getRequest(`objects/${parsedObjectId.value}/`)

      object.id = idbId
      object.server_id = parsedObjectId.value

      await db.updateObject('objects', object)
    }
    if (!object) return
    setProjectMetadata(object)

    saveToRecentList('recentObjId', object.id)

    objectsStore.setField('activeObject', object)
  } catch (error) {
    throw new Error(error)
  }
}

const loadObjectOffline = async () => {
  const filterField = isIdbObject.value ? 'id' : 'server_id'
  const object = await db.objects.where({ [filterField]: parsedObjectId.value }).first()

  if (!object) {
    router.push('/app/not-found')
    return
  }

  setProjectMetadata(object)

  objectsStore.setField('activeObject', object)

  saveToRecentList('recentObjId', object.id)
}

const loadReconsOnline = async () => {
  try {
    const filter = { field: 'object_id', value: parsedObjectId.value }

    await db.updateStoreData('recons', filter)

    const server = await getRequest(`objects/${parsedObjectId.value}/reconnaissance/`)
    await checkStorage('recons', reconsList.value, server, filter)
    await updateStorage('recons', reconsList.value, server, filter)
  } catch (e) {
    console.log(e)
    if (e.code === 'ECONNABORTED') return

    router.push('/app/not-found')
  }
}

const loadDataOffline = async (data) => {
  try {
    const filter = { field: 'object_id', value: parsedObjectId.value }

    await db.updateStoreData(data, filter)
  } catch (e) {
    console.log(e)
  }
}

const loadExcavsOnline = async () => {
  try {
    const filter = { field: 'object_id', value: parsedObjectId.value }

    await db.updateStoreData('excavations', filter)

    const server = await getRequest(`objects/${parsedObjectId.value}/excavations/`)
    await checkStorage('excavations', excavationsList.value, server, filter)
    await updateStorage('excavations', excavationsList.value, server, filter)
  } catch (e) {
    console.log(e)

    if (e.code === 'ECONNABORTED') return

    const title = 'Ошибка'
    const message = `Произошла ошибка при загрузке данных проекта. ${e?.message}. ${e?.request?.responseURL}`

    $notify({
      title,
      message,
      type: 'error'
    })

    if (e?.response?.status === 500) return

    router.push('/app/not-found')
  } finally {
    mainStore.setLoading(false)
  }
}

const isDataPage = computed(() => {
  return route.name === 'object-data'
})

const toggleCollapse = () => {
  objectsStore.setField('isCollapsed', !objectsStore.isCollapsed)

  saveDataToLS('isCollapsed', objectsStore.isCollapsed)
}

const closeExcavModal = () => {
  objectsStore.setField('isShowExcavationEditorModal', false)
}

const closeReconModal = () => {
  objectsStore.setField('isShowReconEditorModal', false)
}
</script>

<template>
  <div class="object">
    <data-desktop-layout v-if="mq.current === 'lg'" :left-panel-width="!isDataPage ? '100%' : ''">
      <template #header>
        <object-header back-button :title="objectTitle" @back="goBack()" />
      </template>
      <div class="object-content">
        <div class="object-left-panel">
          <div class="object-left-panel_absolute">
            <object-desktop-navigation nav-width="256px" />
            <s-button
              v-tooltip.right="objectsStore.isCollapsed ? 'Развернуть' : 'Cвернуть панель'"
              @click="toggleCollapse"
              :icon="objectsStore.isCollapsed ? 'angles-right' : 'angles-left'"
              class="object-left-panel__collapse-btn"
            />
          </div>
        </div>
        <div
          :class="`object-page-content object-page-content--${route.name} ${objectsStore.isCollapsed ? 'collapsed' : ''}`"
          :style="{ 'padding-left': isRemoveContentPadding ? '256px' : '280px' }"
        >
          <router-view />
        </div>
      </div>
      <template v-if="isDataPage" #map>
        <data-map type="data-points" />
      </template>

      <excavation-editor
        v-if="objectsStore.isShowExcavationEditorModal"
        :is-visible="objectsStore.isShowExcavationEditorModal"
        :object-id="object?.server_id"
        @toggle="closeExcavModal"
      />
      <recon-editor
        v-if="objectsStore.isShowReconEditorModal"
        :is-visible="objectsStore.isShowExcavationEditorModal"
        @toggle="closeReconModal"
        :object-id="object?.server_id"
      />
    </data-desktop-layout>

    <data-mobile-layout v-else>
      <template #header>
        <object-header back-button :title="objectTitle" @back="goBack()" />
      </template>
      <object-mobile-navigation />
      <div class="object-page-content">
        <router-view />
      </div>

      <excavation-editor
        v-if="objectsStore.isShowExcavationEditorModal"
        :is-visible="objectsStore.isShowExcavationEditorModal"
        :object-id="object?.server_id"
        @toggle="closeExcavModal"
      />
      <recon-editor
        :object-id="object?.server_id"
        v-if="objectsStore.isShowReconEditorModal"
        :is-visible="objectsStore.isShowExcavationEditorModal"
        @toggle="closeReconModal"
      />
    </data-mobile-layout>
  </div>
</template>

<style lang="scss" scoped>
.object {
  max-height: 100%;

  &-content {
    flex-grow: 1;
    display: flex;
    width: 100%;
    max-height: 100%;
  }

  &-page-content {
    flex-grow: 1;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 250px;
    background: var(--card-bg);
    max-width: 100%;
    max-height: 100%;

    @include phones {
      max-height: calc(100% - 140px);
    }

    &--object-info {
      background: var(--main-bg);
    }

    &--object-dashboard {
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 200px;
    }

    &--object-planning {
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 200px;
    }

    @include tablets {
      max-height: calc(100% - 140px);
      padding: 16px;
      animation-name: backwards !important;
    }
  }

  &-left-panel {
    position: relative;

    &_absolute {
      position: absolute;
      height: 100%;
    }

    &__collapse-btn {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }
}

.object-page-content {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

.object-page-content:not(.collapsed) {
  animation-name: defaultExpandPanel;
}

.object-page-content.collapsed {
  animation-name: defaultCollapsePanel;
}

@keyframes defaultExpandPanel {
  from {
    padding-left: v-bind("isRemoveContentPadding ? '88px': '112px'");
  }
  to {
    padding-left: v-bind("isRemoveContentPadding ? '256px': '280px'");
  }
}

@keyframes defaultCollapsePanel {
  from {
    padding-left: v-bind("isRemoveContentPadding ? '256px': '280px'");
  }
  to {
    padding-left: v-bind("isRemoveContentPadding ? '88px': '112px'");
  }
}
</style>

<style lang="scss">
.object-left-panel__collapse-btn {
  & svg {
    width: 14px !important;
    height: 14px !important;
  }
}
</style>
