<script setup>
import { computed, ref } from 'vue'
import SoilSelectComp from './soil-select-comp.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const stateField = ref({ id: 'state_pfrost', value: 'state_pfrost', service: 'states_pfrost' })
const initSource = ref(props.source)

const serviceName = computed(() => {
  if (props.source?.soil_type >= 11101 && props.source?.soil_type <= 11205) {
    return 'sand'
  } else if (props.source?.soil_type >= 12102 && props.source?.soil_type <= 12103) {
    return 'clay'
  } else if (props.source?.soil_type === 12101) {
    return 'sandy_clay'
  } else {
    return 'other'
  }
})

const waterField = computed(() => {
  return {
    id: 'water_saturation',
    value: 'water_saturation',
    service: `water_saturations_${serviceName.value}`
  }
})
</script>

<template>
  <div class="create-guide-state-pfrost">
    <div class="create-guide-state-pfrost__item">
      <soil-select-comp
        :source="source"
        :soil-interval="soilInterval"
        :field="stateField"
        @trigger-changed="emits('trigger-changed', $event)"
      />
    </div>
    <div v-if="source.state_pfrost" class="create-guide-state-pfrost__item">
      <s-title type="small"> Укажите состояние при оттаивании </s-title>
      <soil-select-comp
        :source="source"
        :soil-interval="soilInterval"
        :field="waterField"
        @trigger-changed="emits('trigger-changed', true)"
      />
    </div>
    <s-input
      v-if="field.commentField"
      v-model="initSource[field.commentField]"
      type="textarea"
      :label="field.commentTitle || 'Комментарий'"
    />
  </div>
</template>

<style lang="scss">
.create-guide-state-pfrost {
  display: grid;
  grid-gap: 2rem;

  &__item {
    display: grid;
    grid-gap: 0.5rem;

    &-row {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
    }
  }
}
</style>
