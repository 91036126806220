import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useRulerStore = defineStore('ruler', () => {
  const isRulerOn = ref(false)
  const setRuler = (val) => {
    isRulerOn.value = val
  }
  const toggleRuler = () => {
    isRulerOn.value = !isRulerOn.value
  }

  const isShowNotify = ref(false)
  const setShowNotify = (val) => {
    isShowNotify.value = val
  }

  const lastPointData = ref(null)
  const setLastPoint = (data) => {
    lastPointData.value = data
  }

  const points = ref([])
  const pushPointToPoints = (point) => {
    points.value.push(point)
  }

  const removeLastPoint = () => {
    points.value = points.value.slice(0, -1)
  }

  const clearPoints = () => {
    points.value = []
  }

  const totalDistance = ref(0)
  const setTotalDistance = (distance) => {
    totalDistance.value = distance
  }

  const isShowRulerModal = ref(false)
  const setIsShowRulerModal = (val) => {
    isShowRulerModal.value = val
  }

  const clearAssets = ref(false)
  const setClearAssets = (val) => {
    clearAssets.value = val
  }

  return {
    setRuler,
    clearAssets,
    setClearAssets,
    isShowRulerModal,
    setIsShowRulerModal,
    totalDistance,
    setTotalDistance,
    removeLastPoint,
    clearPoints,
    points,
    pushPointToPoints,
    lastPointData,
    setLastPoint,
    isRulerOn,
    toggleRuler,
    isShowNotify,
    setShowNotify
  }
})
