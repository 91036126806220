import joinProject from './actions/join-project'
import deleteSample from './actions/delete-sample'

const contextMenu = {
  items: {
    joinProject,
    deleteSample
  }
}

export default contextMenu
