export const fields = [
  { id: 'objects', icon: 'layer-group', label: 'Объекты' },
  { id: 'excavations', icon: 'bullseye', label: 'Скважины' },
  { id: 'excavations_meters', icon: 'list', label: 'Метраж' },
  { id: 'samples', icon: 'crosshairs', label: 'Пробы' },
  { id: 'coreboxes', icon: 'image', label: 'Керн' }
]

export const logLabels = {
  object: 'проект',
  excavation: 'скважину',
  excavationimage: 'фотографию общего вида',
  soil: 'слой',
  inclusion: 'включение',
  interlayer: 'прослой',
  sample: 'проба',
  sampleimage: 'фотографию проб',
  corebox: 'керн',
  groundwater: 'УГВ',
  user: 'пользователя',
  company: 'компанию'
}
