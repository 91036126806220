import { deleteHandler } from '@/utils'
import db from '@/libs/db'
import { useObjectsStore } from '@/stores'

let recon_object_id = null

const deleteExcav = {
  name() {
    return `<div class="context-menu__delete">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
              <p>Удалить ТН</p>
            </div>`
  },
  callback(key, selection) {
    const objectsStore = useObjectsStore()

    const numOfExcav = this.getDataAtRow(selection[0].start.row)[0]

    const recon = objectsStore.reconsList.find((recon) => recon.title === numOfExcav)

    recon_object_id = recon.object_id

    handleDelete(recon)
  }
}

function parsedObjectId() {
  if (!recon_object_id) return false
  return !isIdbObject() ? Number(recon_object_id) : Number(recon_object_id.replace('idb_', ''))
}

function isIdbObject() {
  return String(recon_object_id).includes('idb_')
}

function updateStore() {
  const filter = { field: 'object_id', value: parsedObjectId() }
  db.updateStoreData('recons', filter)
  recon_object_id = null
}

async function handleDelete(recon) {
  await deleteHandler(recon, 'recons', updateStore, 'recons')
}

export default deleteExcav
