export const layerTabs = [
  {
    id: 1,
    name: 'Слои',
    value: 'soils'
  },
  {
    id: 2,
    name: 'Пробы',
    value: 'samples'
  },
  {
    id: 3,
    name: 'УГВ',
    value: 'groundwater'
  },
  {
    id: 4,
    name: 'Керн',
    value: 'coreboxes'
  }
]
