<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { useMq } from 'vue3-mq'

import { interlayersTemplate } from '../../configs'
import SelectComp from '../soil-select-comp.vue'
import SearchSelectComp from '../soil-search-select-comp.vue'
import SoilInfo from '../soil-info.vue'
import StatePfrost from '../state-pfrost.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Новой прослой'
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  interlayer: {
    type: Object,
    default: () => null
  },
  soilInterval: {
    type: Object,
    required: true,
    default: () => {}
  },
  frozen: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['toggle', 'add-handler', 'remove-handler'])
const mq = useMq()

const initTemplate = ref(null)
const frozenField = ref({
  id: 'state_pfrost',
  value: 'state_pfrost',
  title: 'Укажите состояние грунта',
  clearable: true,
  type: 'state-pfrost'
})
const typeField = ref({
  id: 'type',
  type: 'select',
  service: 'interlayers_types'
})
const soilField = ref({
  id: 'composition',
  type: 'select',
  service: 'soil_types',
  filter: { attr: 'soil_class', value: 1 }
})

const serviceName = computed(() => {
  const soilType = initTemplate.value?.composition

  if (soilType >= 11201 && soilType <= 11205) {
    return 'sand'
  } else if (soilType >= 12102 && soilType <= 12103) {
    return 'clay'
  } else if (soilType === 12101) {
    return 'sandy_clay'
  } else if ((soilType >= 11101 && soilType <= 11106) || (soilType >= 12201 && soilType <= 12301)) {
    return 'other'
  } else {
    return null
  }
})

const waterField = computed(() => {
  if (!serviceName.value) return false

  return {
    id: 'water_saturation',
    type: 'select',
    service: `water_saturations_${serviceName.value}`
  }
})

const warningDepth = computed(() => {
  const { d0, d1 } = initTemplate.value

  if (!d0 && d0 !== 0) return null
  else if (d1 === d0)
    return {
      from: d0,
      to: d1
    }
  else return null
})

const warningTip = computed(() => {
  const { d0, d1 } = initTemplate.value

  return `Нельзя создать слой с нулевой мощностью (${d0} - ${d1} м)`
})

const disabled = computed(() => {
  const { d0, composition, type } = initTemplate.value

  return (!Number.isInteger(d0) && !d0) || !type || (!composition && type !== 7 && type !== 8)
})

watch(
  () => props.isVisible,
  (newValue) => {
    if (!newValue) return
    init()
  }
)

watch(
  () => initTemplate.value?.type,
  (value) => {
    if (!value) {
      initTemplate.value.composition = null
      initTemplate.value.water_saturation = null
      initTemplate.value.state_pfrost = null
    }
  }
)

const init = () => {
  if (props.interlayer) {
    initTemplate.value = cloneDeep(props.interlayer)

    if (!initTemplate.value.d0) {
      initTemplate.value.d0 = props.soilInterval.from
    }
    if (!initTemplate.value.d1) {
      initTemplate.value.d1 = props.soilInterval.to
    }
  } else {
    interlayersTemplate.d0 = props.soilInterval.from
    interlayersTemplate.d1 = props.soilInterval.to
    initTemplate.value = cloneDeep(interlayersTemplate)
  }
}

const toggleModal = () => {
  emits('toggle')
}

const saveHandler = () => {
  const clone = cloneDeep(initTemplate.value)
  const { d0, d1 } = initTemplate.value
  const { from, to } = props.soilInterval

  if (d0 === from) {
    clone.d0 = null
  }

  if (d1 === to) {
    clone.d1 = null
  }
  emits('add-handler', clone)
  toggleModal()
}

const removeHandler = () => {
  emits('remove-handler', initTemplate.value)
  toggleModal()
}

onMounted(() => {
  init()
})
</script>

<template>
  <s-modal
    :title="title"
    :show="isVisible"
    :min-height="460"
    :fullscreen="mq.current !== 'lg'"
    @close="toggleModal"
    v-if="initTemplate"
  >
    <div class="interlayers-editor">
      <div class="interlayers-editor-block">
        <s-number-input
          v-model="initTemplate.d0"
          label="От, м"
          :min="soilInterval.from"
          :max="soilInterval.to"
          placeholder="0.0"
        />
        <s-number-input
          v-model="initTemplate.d1"
          label="До, м"
          :min="soilInterval.from"
          :max="soilInterval.to"
          placeholder="0.0"
        />
      </div>
      <soil-info
        v-if="warningDepth"
        :data="{}"
        :tip="warningTip"
        type="error"
        :soil-interval="warningDepth"
      />
      <select-comp
        title="Тип прослоев"
        :source="initTemplate"
        :field="typeField"
        :soil-interval="soilInterval"
        :filter="false"
      />
      <search-select-comp
        v-if="initTemplate.type && initTemplate.type !== 7 && initTemplate.type !== 8"
        title="Состав"
        :source="initTemplate"
        :field="soilField"
        :soil-interval="soilInterval"
        :filter="false"
        @change="initTemplate.soil_type = $event"
      />
      <select-comp
        v-if="waterField && initTemplate.composition && !frozen"
        title="Консистенция / водонасыщение"
        :filter="false"
        :source="initTemplate"
        :field="waterField"
        :soil-interval="soilInterval"
      />
      <state-pfrost
        v-if="initTemplate.composition && frozen"
        :source="initTemplate"
        :field="frozenField"
        :soil-interval="soilInterval"
      />
      <s-input
        v-model="initTemplate.comments"
        type="textarea"
        label="Комментарий к прослою"
        placeholder="Дополнительное описание"
      />
    </div>
    <template #footer>
      <div :class="['interlayers-editor-footer', { exist: interlayer }]">
        <s-button
          v-if="interlayer"
          icon="trash-can"
          simple
          icon-color="var(--error)"
          @click="removeHandler"
        />
        <s-button type="success" :disabled="disabled" @click="saveHandler">
          {{ interlayer ? 'Сохранить' : 'Добавить' }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.interlayers-editor {
  display: grid;
  align-content: start;
  grid-gap: 2rem;
  height: 100%;
  overflow: auto;

  &-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1rem;
  }

  &-footer {
    display: grid;

    &.exist {
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }
  }
}
</style>
