<script setup>
import { ref, inject, reactive } from 'vue'
import { useObjectsStore } from '@/stores'

import { ObjectEditorSteps } from '@/modules/app/data/objects/object-editor/config'
import StepsList from '@/modules/app/data/objects/object-editor/components/steps/steps-list.vue'
import ObjectEditor from '@/modules/app/data/objects/object-editor/object-editor.vue'

const $confirm = inject('$confirm')

const objectsStore = useObjectsStore()

const currentStep = ref(1)
const steps = reactive(ObjectEditorSteps)

const clickStepHandler = async (step) => {
  if (currentStep.value === step) return
  if (objectsStore.objectEditorHasChanges) {
    await $confirm(
      'Вы уверены, что хотите уйти? Все несохраненные данные будут потеряны.',
      'Переход',
      {
        cancelButtonText: 'Остаться',
        confirmButtonClass: 'confirm',
        confirmButtonType: 'primary',
        confirmButtonText: 'Перейти',
        cancelButtonClass: 'cancel'
      }
    ).then(() => {
      currentStep.value = step
      objectsStore.setField('objectEditorHasChanges', false)
    })

    return
  }

  currentStep.value = step
}
</script>

<template>
  <div class="editable-info">
    <s-title>Информация о проекте</s-title>
    <div class="editable-info__content">
      <steps-list
        :steps="steps"
        :item-styles-object="{ cursor: 'pointer' }"
        @changeStepHandler="clickStepHandler"
        :current-step="currentStep"
      />
      <object-editor :steps="ObjectEditorSteps" :current-step="currentStep" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editable-info {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 100%;

  &__content {
    display: flex;
    gap: 160px;
    max-height: 100%;
    overflow: auto;
  }
}
</style>
