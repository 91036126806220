<script setup>
import { computed } from 'vue'
import { useRulerStore } from '@/stores'
import { SText, SIcon } from '@n966/soilbox_ui'
import { getDataFromLS } from '@/utils'

const rulerStore = useRulerStore()

const iconsConfig = [
  {
    icon: 'copy',
    id: 'copy'
  },
  {
    icon: 'trash-can',
    id: 'delete'
  },
  {
    icon: 'close',
    id: 'close'
  }
]

const isShowPopup = computed(() => {
  return rulerStore.lastPointData && rulerStore.points.length > 1
})

const clickHandler = (id) => {
  switch (id) {
    case 'delete':
      {
        rulerStore.removeLastPoint()
        const lastItem = rulerStore.points[rulerStore.points.length - 1]
        rulerStore.setLastPoint(lastItem)
      }
      break
    case 'copy':
      {
        navigator.clipboard
          .readText()
          .then(() => navigator.clipboard.writeText(formattedDistance.value))
        rulerStore.setShowNotify(true)
      }
      break
    case 'close':
      {
        const isShowed = getDataFromLS('dont-show')
        if (isShowed) {
          rulerStore.setClearAssets(true)
          return
        }
        rulerStore.setIsShowRulerModal(true)
      }
      break
  }
}

const formattedDistance = computed(() => {
  return parseFloat(rulerStore.totalDistance.toFixed(2))
})
</script>

<template>
  <div class="map-popup" :class="{ show: isShowPopup }">
    <div class="map-popup__distance">
      <s-text>{{ formattedDistance }} м</s-text>
    </div>
    <div
      @click="clickHandler(item.id)"
      class="map-popup__icon"
      v-for="item in iconsConfig"
      :key="item.id"
    >
      <s-icon :name="item.icon" />
    </div>
  </div>
</template>

<style lang="scss">
.s-map {
  & .mapboxgl-popup-content:has(.map-popup) {
    border-radius: 10px;
    padding: 0;
  }

  & .mapboxgl-popup-tip {
    display: none;
  }
  & .mapboxgl-popup-anchor-bottom:has(.map-popup) {
    left: 7%;
  }
}

#data-map {
  & .map-popup {
    position: relative;
    flex-direction: row;
    display: none;
    align-items: center;
    gap: 16px;
    background-color: var(--secondary-bg);
    border: 1px solid var(--bg);
    height: 40px;
    min-width: auto;
    border-radius: 8px;
    padding: 0 12px;

    &.show {
      display: flex;
    }

    &__distance {
      flex-shrink: 0;
    }

    &__icon {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &:last-child {
        border-left: 1px solid var(--bg);
        padding-left: 12px;
      }
    }

    & svg {
      height: 16px;
    }
  }
}
</style>
