<script setup>
import { useServicesStore, useObjectsStore } from '@/stores/index.js'
import { reactive } from 'vue'
import { orderBy } from 'natural-orderby'

const props = defineProps({
  template: {
    type: Object,
    required: true
  },
  fields: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['changeSelect'])

const serviceStore = useServicesStore()
const objectsStore = useObjectsStore()

const getSourceServices = (field) => {
  if (field.service === 'users') {
    const geologists = serviceStore[field.service].map((item) => {
      return {
        id: item.id,
        title: item.short_name || item.username,
        disabled: !item.is_active
      }
    })

    return orderBy(
      geologists,
      [(v) => v.disabled, (v) => v.title.toLowerCase(), (v) => v.title],
      ['asc', 'asc', 'asc']
    )
  }

  return orderBy(
    serviceStore[field.service],
    [(v) => v.title.toLowerCase(), (v) => v.title],
    ['asc']
  )
}

const sources = reactive(props.template)

const changeHandle = (value, field) => {
  sources[field] = value
  emits('changeSelect', sources)
  objectsStore.setChangedSelectFields(field, value)
}
</script>

<template>
  <div class="employees-info">
    <div v-for="field in fields" :key="field.id">
      <s-select
        :value="sources[field.title]"
        @change="changeHandle($event, field.title)"
        :options="getSourceServices(field)"
        :selectLabel="field.label"
        :placeholder="field.placeholder"
        :multiple="field.multiple"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.employees-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
}
</style>
