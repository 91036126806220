import mapboxgl from 'mapbox-gl'
import { useMapStore } from '@/stores'
import { getPaddingSettings } from '@/utils'
import { mapColors, haloColors, statusColors } from './data-points'

const defaultFilter = ['<', ['to-number', ['get', 'status']], 2]
const prepareFilter = ['==', ['to-number', ['get', 'status']], 2]
const workingFilter = ['==', ['to-number', ['get', 'status']], 3]
const completedFilter = ['==', ['to-number', ['get', 'status']], 4]

const clusterCountColors = {
  'light-theme': '#242730',
  'dark-theme': '#FAFAFA',
  outdoors: '#242730',
  satelite: '#FAFAFA'
}

export const clusterProperties = {
  default_count: ['+', ['case', defaultFilter, 1, 0]],
  prepare_count: ['+', ['case', prepareFilter, 1, 0]],
  working_count: ['+', ['case', workingFilter, 1, 0]],
  completed_count: ['+', ['case', completedFilter, 1, 0]]
}

const donutSegment = (start, end, r, r0, color) => {
  if (end - start === 1) end -= 0.00001
  const a0 = 2 * Math.PI * (start - 0.25)
  const a1 = 2 * Math.PI * (end - 0.25)
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0)
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1)
  const largeArc = end - start > 0.5 ? 1 : 0

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
    r + r * y0
  } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1} ${
    r + r0 * y1
  } A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`
}

const createDonutChart = (props, module) => {
  const mapStore = useMapStore()
  const activeBaselayer = mapStore.getActiveBaselayerName(module)
  const colors = [
    mapColors[activeBaselayer],
    statusColors.orange,
    statusColors.blue,
    statusColors.green
  ]
  const offsets = []
  const counts = [
    props.default_count,
    props.prepare_count,
    props.working_count,
    props.completed_count
  ]
  let total = 0

  for (const count of counts) {
    offsets.push(total)
    total += count
  }

  const fontSize = total >= 1000 ? 20 : total >= 100 ? 18 : total >= 10 ? 16 : 14
  const r = total >= 1000 ? 36 : total >= 100 ? 28 : total >= 10 ? 26 : 22
  const r0 = Math.round(r * 0.75)
  const w = r * 2

  let html = `<div class="excavation-cluster-marker">
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, colors[i])
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="${haloColors[activeBaselayer]}" />
<text dominant-baseline="central" transform="translate(${r}, ${r})" style="fill: ${clusterCountColors[activeBaselayer]}; font-weight: bold;">
${props.point_count_abbreviated}
</text>
</svg>
</div>`

  const el = document.createElement('div')
  el.innerHTML = html

  return el.firstChild
}

export const updateClusterMarkers = (controller, module = 'data-map') => {
  const { mapgl, markers, layerId } = controller

  if (!mapgl.getSource(layerId)) return
  if (!mapgl.isSourceLoaded(layerId)) return

  const newMarkers = {}
  const features = mapgl.querySourceFeatures(layerId)

  for (const feature of features) {
    const coords = feature.geometry.coordinates
    const props = feature.properties

    if (!props.cluster) continue

    const id = props.cluster_id

    let marker = markers.all[id]

    if (!marker) {
      const element = createDonutChart(props, module, layerId)

      element.addEventListener('click', () => {
        mapgl.getSource(layerId).getClusterExpansionZoom(id, (err, zoom) => {
          if (err) return

          mapgl.flyTo({
            center: coords,
            padding: getPaddingSettings(),
            zoom
          })
        })
      })

      marker = markers.all[id] = new mapboxgl.Marker({
        element
      }).setLngLat(coords)
    }

    newMarkers[id] = marker

    if (markers.onScreen[id]) marker.addTo(mapgl)
  }

  for (const id in markers.onScreen) {
    if (!newMarkers[id]) markers.onScreen[id].remove()
  }

  markers.onScreen = newMarkers
}
