import { ref } from 'vue'
import { orderBy } from 'natural-orderby'
import { defineStore } from 'pinia'

export const useEditorStore = defineStore('editor', () => {
  const createModeOn = ref(false)
  const setCreateMode = (value) => {
    createModeOn.value = value
  }
  const toggleCreateMode = () => {
    createModeOn.value = !createModeOn.value
  }

  const currentStep = ref(0)
  const setCurrentStep = (val) => {
    currentStep.value = val
  }

  const cloneExcavationsList = ref([])

  const pushPointsToClonePoints = (point) => {
    cloneExcavationsList.value.push(point)
  }

  const setClonePoints = (points) => {
    const sortedPoints = orderBy(points, [(v) => v['title']], ['asc'])
    cloneExcavationsList.value = sortedPoints
  }

  const recentChangesPoints = ref([])
  const pushChangedPoint = (point) => {
    recentChangesPoints.value.push(point)
  }

  const clearChangedPoints = () => {
    recentChangesPoints.value = []
  }

  const removePoint = (pointID, listID) => {
    if (listID === 'recent') {
      recentChangesPoints.value = recentChangesPoints.value.filter(
        (point) => point.point_id !== pointID
      )
    } else {
      cloneExcavationsList.value = cloneExcavationsList.value.filter(
        (point) => point.point_id !== pointID
      )
    }
  }

  const mapEditorPopupData = ref({})
  const setPopupData = (field, data) => {
    mapEditorPopupData.value[field] = data
  }

  const mapEditorPopupOpen = ref(false)
  const setMapEditorPopupOpen = (val) => {
    mapEditorPopupOpen.value = val
  }

  const isShowDistanceTip = ref(true)
  const setIsShowDistanceTip = (val) => {
    isShowDistanceTip.value = val
  }

  const currentPopup = ref(null)
  const setPopup = (val) => {
    currentPopup.value = val
  }

  return {
    currentPopup,
    setPopup,
    cloneExcavationsList,
    pushPointsToClonePoints,
    setClonePoints,
    currentStep,
    setCurrentStep,
    isShowDistanceTip,
    setIsShowDistanceTip,
    createModeOn,
    toggleCreateMode,
    setCreateMode,
    removePoint,
    mapEditorPopupData,
    setPopupData,
    mapEditorPopupOpen,
    setMapEditorPopupOpen,
    clearChangedPoints,
    recentChangesPoints,
    pushChangedPoint
  }
})
