export const checkOnDeleteAllString = (pastValue, event, input) => {
  if (!pastValue && event.key === 'Backspace') {
    event.target.value = `° ' "`
    focusAndSetCaretPosition(input, 0)
    const emitEvent = new Event('input', { bubbles: true })
    input.dispatchEvent(emitEvent)
  }
}

export const focusAndSetCaretPosition = (input, pos) => {
  input.setSelectionRange(pos, pos)
  input.focus()
}

export const DMSToDecimalDegrees = (d, m, s, isLatProp) => {
  const coordinates = getClampValues(d, m, s, isLatProp)
  const { degrees, minutes, seconds } = coordinates
  return degrees + minutes / 60 + seconds / 3600
}

const getClampValues = (degrees, minutes, seconds, isLatProp) => {
  if (isLatProp && degrees) {
    degrees = clampValueInRange(degrees, -89, 89)
  } else {
    degrees = clampValueInRange(degrees, -179, 179)
  }

  if (!degrees) {
    degrees = 0
  }

  if (minutes) {
    minutes = clampValueInRange(minutes, 0, 59)
  }

  if (typeof seconds === 'number') {
    seconds = String(seconds)
  }

  if (seconds) {
    if (seconds.includes(',')) {
      seconds = seconds.replace(',', '.')
    }

    const [integerPart, decimalValue] = seconds.split('.')
    if (decimalValue && decimalValue.length >= 3) {
      seconds = `${integerPart}.${decimalValue.slice(0, 3)}`
    }

    seconds = clampValueInRange(seconds, 0, 59.999)
  }

  if (isLatProp && (degrees === 89 || degrees === -89)) {
    minutes = 59
    seconds = 59.999
  } else if (!isLatProp && (degrees === 179 || degrees === -179)) {
    minutes = 59
    seconds = 59.999
  }

  return {
    degrees: Number(degrees),
    minutes: Number(minutes),
    seconds: Number(seconds)
  }
}

export const parseDMS = (input) => {
  const regex = /(-?\d+)?°\s*(\d+)?'\s*(\d+(?:[.,]\d+)?)?"?/
  const match = input.match(regex)
  if (match) {
    const degrees = match[1] ? parseInt(match[1], 10) : 0
    const minutes = match[2] ? parseInt(match[2], 10) : 0
    const seconds = match[3] ? parseFloat(match[3].replace(',', '.')) : 0.0

    return { degrees, minutes, seconds }
  }
  return { degrees: 0, minutes: 0, seconds: 0 }
}

export const decimalDegreesToDMS = (deg) => {
  if (deg == null) {
    return null
  }

  const sign = deg < 0 ? -1 : 1
  deg = Math.abs(deg)

  let d = Math.floor(deg)
  const minfloat = (deg - d) * 60
  const m = Math.floor(minfloat)
  const secfloat = (minfloat - m) * 60
  const s = parseFloat(secfloat.toFixed(3))

  d = d * sign

  return `${d}° ${m}' ${s}"`
}

export const preventToDeleteSymbols = (undeletebleSymbols, pastValue, event) => {
  if (undeletebleSymbols.includes(pastValue) && event.key === 'Backspace') {
    event.stopImmediatePropagation()
    event.preventDefault()
    return true
  }

  return false
}

export const checkIsCharIsAllowed = (regex, allowedButtons, event) => {
  if (!regex.test(event.key) && !allowedButtons.includes(event.key)) {
    event.stopImmediatePropagation()
    event.preventDefault()
    return false
  }

  return true
}

export const checkWriteInSpace = (value, caretPos, allowedButtons, event) => {
  if (value[caretPos] === ' ' && !allowedButtons.includes(event.key)) {
    event.preventDefault()
    return true
  }

  return false
}

export const clampValueInRange = (value, minValue, maxValue) => {
  if (value === undefined || value === null) return

  return Math.max(minValue, Math.min(maxValue, value))
}

export const updateValueWithClampedCoordinates = (degrees, minutes, seconds, event, isLatProp) => {
  if (isLatProp && degrees) {
    degrees = clampValueInRange(degrees, -89, 89)
  } else {
    degrees = clampValueInRange(degrees, -179, 179)
  }

  if (minutes) {
    minutes = clampValueInRange(minutes, 0, 59)
  }

  if (seconds) {
    if (seconds.includes(',')) {
      seconds = seconds.replace(',', '.')
    }

    const [integerPart, decimalValue] = seconds.split('.')
    if (decimalValue && decimalValue.length >= 3) {
      seconds = `${integerPart}.${decimalValue.slice(0, 3)}`
    }

    seconds = clampValueInRange(seconds, 0, 59.999)
  }

  if (isLatProp && (degrees === 89 || degrees === -89)) {
    minutes = 59
    seconds = 59.999
  } else if (!isLatProp && (degrees === 179 || degrees === -179)) {
    minutes = 59
    seconds = 59.999
  }

  const newValue = `${degrees ? degrees : ''}° ${minutes || minutes === 0 ? minutes : ''}' ${seconds || seconds === 0 ? seconds : ''}"`
  event.target.value = newValue
}
