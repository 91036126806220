<script setup>
import { ref, computed, onMounted } from 'vue'
import { useMq } from 'vue3-mq'

import db from '@/libs/db'
import { getDataFromLS, saveDataToLS } from '@/utils'

import ObjectsList from '@/modules/app/data/objects/list/components/object-list.vue'
import ExcavationsList from '@/modules/app/data/objects/object/modules/data-page/components/excavations-list.vue'

const mq = useMq()

const title = ref('Здесь будут показаны документы, с которыми вы недавно работали')
const recentObjects = ref([])
const recentExcavations = ref([])
const recentOptions = ref([
  {
    title: 'Скважины',
    id: 1
  },
  {
    title: 'Объекты',
    id: 2
  }
])
const selectedOption = ref(2)

const clearedRecentObjectsArray = computed(() => {
  return recentObjects.value.filter((obj) => Boolean(obj))
})

const clearedRecentExcavsArray = computed(() => {
  return recentExcavations.value.filter((obj) => Boolean(obj))
})

const getDataFromDB = (idArray, dbName) => {
  return db[dbName].bulkGet(idArray)
}

const getExcavations = async () => {
  const idRecentExcavs = getDataFromLS('recentExcavsId')
  if (!idRecentExcavs) return

  recentExcavations.value = await getDataFromDB(idRecentExcavs, 'excavations')
}

const getObjects = async () => {
  const idRecentObjects = getDataFromLS('recentObjId')
  if (!idRecentObjects) return

  recentObjects.value = await getDataFromDB(idRecentObjects, 'objects')
}

const changeOption = (option) => {
  selectedOption.value = option
  saveDataToLS('selectedRecentOption', option)
}

onMounted(() => {
  const optionFromLS = getDataFromLS('selectedRecentOption')
  if (optionFromLS) {
    selectedOption.value = optionFromLS
  }

  getExcavations()
  getObjects()
})
</script>

<template>
  <div class="recent">
    <div class="recent__header">
      <s-title type="title">Недавнее</s-title>
      <div class="recent__select-wrapper">
        <s-select :options="recentOptions" :value="selectedOption" @change="changeOption" />
      </div>
    </div>
    <s-alert v-if="mq.current !== 'sm'" :title="title" icon="circle-info" type="primary" />
    <div class="recent__list-excavations" v-if="selectedOption === 1">
      <excavations-list has-obj-name :excavations="clearedRecentExcavsArray" />
    </div>
    <div class="recent__list-objects" v-else>
      <objects-list :objects="clearedRecentObjectsArray" />
    </div>
  </div>
</template>

<style lang="scss">
.recent {
  display: grid;
  grid-gap: 1.5rem;
  max-height: calc(100% - 286px);
  min-height: 100%;

  &__list-excavations {
    min-height: 100%;
    overflow: auto;
  }

  &__list-objects {
    overflow: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include phones {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__select-wrapper {
    max-width: 180px;
    justify-self: end;
  }
}
</style>
