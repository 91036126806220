<script setup>
import { computed } from 'vue'
import { useMapStore } from '@/stores'
import { roundValue } from '@/utils'

const props = defineProps({
  stats: {
    type: Object,
    required: true
  },
  sum: {
    type: Number,
    default: 0
  },
  statuses: {
    type: Array,
    required: true
  }
})

const mapStore = useMapStore()

const inverseDefaultColor = computed(() => {
  return mapStore.activeBaselayers['data-map'] !== 'map'
})

const filteredStatuses = computed(() => {
  return props.statuses.filter((s) => modifiedStats.value[s.id])
})

const modifiedStats = computed(() => {
  const stats = props.statuses.map((status) => status.id).filter((id) => getPercent(id))

  return stats.reduce((prev, item, i, array) => {
    if (i !== array.length - 1) {
      prev[item] = getPercent(item)
    } else {
      prev[item] = 100 - Object.values(prev).reduce((sum, value) => sum + value, 0)
    }

    return prev
  }, {})
})

const getClass = (flag) => {
  return `widget-progress-bar__item ${flag} ${
    inverseDefaultColor.value && flag === 'default' ? 'inverse' : ''
  }`
}

const getPercent = (id) => {
  if (!props.stats[id]) return 0

  const value = props.stats[id].count || 0

  return roundValue((value / props.sum) * 100, 0)
}
</script>

<template>
  <div class="widget-progress-bar">
    <div
      v-for="item in filteredStatuses"
      :key="item.id"
      :class="getClass(item.flag)"
      :style="`width: ${modifiedStats[item.id]}%`"
    >
      <span v-show="modifiedStats[item.id] && modifiedStats[item.id] > 7">
        {{ modifiedStats[item.id] }}%
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.widget-progress-bar {
  background: var(--bg);
  border: 1px solid var(--main-bg);
  width: 100%;
  height: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &__item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
      color: var(--white-text);
    }

    &:first-child {
      flex: 1;
    }

    &.default {
      background-color: var(--info);

      span {
        color: #1e232b;
      }

      &.inverse {
        background-color: var(--caption);
      }
    }

    &.orange {
      background-color: var(--orange);

      span {
        color: #1e232b;
      }
    }

    &.blue {
      background-color: var(--primary);
    }

    &.green {
      background-color: var(--success);
    }
  }
}

.light-theme {
  .widget-progress-bar {
    border: none;

    &__item {
      opacity: 0.8;

      &.default {
        &.inverse {
          background-color: var(--placeholder);
        }
      }

      &.default:not(.inverse) {
        span {
          color: var(--white-text);
        }
      }
    }
  }
}
</style>
