// map colors config
export const statusColors = {
  orange: '#F39C4B',
  blue: '#3C98E7',
  green: '#55B77E'
}

export const mapColors = {
  'dark-theme': '#FEDA82',
  'light-theme': '#6682A8',
  satelite: '#ffffff',
  outdoors: '#585F70'
}
export const haloColors = {
  'dark-theme': '#242730',
  'light-theme': '#FAFAFA',
  satelite: '#242730',
  outdoors: '#F8F8F8'
}

// text field helpers
const heightsValue = [
  'concat',
  ['to-number', ['get', 'h']],
  ' / ',
  ['to-number', ['get', 'h_plan']],
  ' м'
]
const statusValue = [
  'case',
  ['==', ['get', 'status'], 2],
  'Вынесена',
  ['==', ['get', 'status'], 3],
  'Бурение',
  ['==', ['get', 'status'], 4],
  'Закрыта',
  ''
]
const getColorSettings = baselayer => [
  'case',
  ['==', ['get', 'status'], 2],
  statusColors.orange,
  ['==', ['get', 'status'], 3],
  statusColors.blue,
  ['==', ['get', 'status'], 4],
  statusColors.green,
  mapColors[baselayer]
]
const getStatusSettings = baselayer => ({
  'text-color': getColorSettings(baselayer),
  'font-scale': 0.9,
  'text-font': ['literal', ['Open Sans SemiBold', 'Arial Unicode MS Bold']]
})

const getIconImage = baselayer => ([
  'case',
  ['==', ['get', 'status'], 2],
  ['concat', ['get', 'type'], '-prepare'],
  ['==', ['get', 'status'], 3],
  ['concat', ['get', 'type'], '-working'],
  ['==', ['get', 'status'], 4],
  ['concat', ['get', 'type'], '-completed'],
  ['concat', ['get', 'type'], `-${baselayer}`]
])

const getTextField = baselayer => ([
  'case',
  ['==', ['get', 'type'], 'excavation'],
  [
    'format',
    ['concat', 'Скв. ', ['get', 'title']],
    { 'text-color': getColorSettings(baselayer) },
    '\n',
    {},
    heightsValue,
    {
      'font-scale': 0.9,
      'text-color': getColorSettings(baselayer)
    },
    '\n',
    {},
    statusValue,
    getStatusSettings(baselayer)
  ],
  ['==', ['get', 'type'], 'recon'],
  [
    'format',
    ['concat', 'TH ', ['get', 'title']],
    { 'text-color': getColorSettings(baselayer) }
  ],
  ['get', 'title']
])

// data points layers configs
export const getDataLayerConfig = (activeBaselayer, type) => {
  switch (type) {
    case 'icon':
      return {
        type: 'symbol',
        layout: {
          'icon-image': getIconImage(activeBaselayer),
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.35, 20, 0.6]
        },
        paint: {
          'icon-halo-color': haloColors[activeBaselayer],
          'icon-halo-width': 1.5
        }
      }
    case 'processes':
      return {
        type: 'symbol',
        layout: {
          'icon-anchor': 'center',
          'icon-offset': [-40, 0],
          'icon-pitch-alignment': 'map',
          'icon-image': `processes-${activeBaselayer}`,
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.35, 20, 0.6]
        }
      }
    case 'label':
      return {
        type: 'symbol',
        layout: {
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-size': ['interpolate', ['linear'], ['zoom'], 10, 10, 20, 12],
          'text-offset': [1.2, 0],
          'text-justify': 'left',
          'text-pitch-alignment': 'map',
          'text-anchor': 'left',
          'text-field': getTextField(activeBaselayer),
          'text-letter-spacing': 0.03
        },
        paint: {
          'text-color': mapColors[activeBaselayer],
          'text-halo-color': haloColors[activeBaselayer],
          'text-halo-width': 1.3,
          'text-opacity-transition': { duration: 0, delay: 0 }
        }
      }
  }
}

// remove layers helpers
export const removeDataLayers = (mapgl, layerId) => {
  if (mapgl.getLayer(`${layerId}-icon`)) {
    mapgl.removeLayer(`${layerId}-icon`)
  }
  if (mapgl.getLayer(`${layerId}-cluster-icon`)) {
    mapgl.removeLayer(`${layerId}-cluster-icon`)
  }
  if (mapgl.getLayer(`${layerId}-cluster-label`)) {
    mapgl.removeLayer(`${layerId}-cluster-label`)
  }
  if (mapgl.getLayer(`${layerId}-label`)) {
    mapgl.removeLayer(`${layerId}-label`)
  }
  if (mapgl.getLayer(`${layerId}-processes`)) {
    mapgl.removeLayer(`${layerId}-processes`)
  }
  if (mapgl.getSource(layerId)) {
    mapgl.removeSource(layerId)
  }
}
