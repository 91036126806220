<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { loadImages } from '@/utils'
import { centerMapOnActiveFeatures } from '@/modules/app/data/maps/data-map/helpers'
import cloneDeep from 'lodash.clonedeep'
import { useEditorStore, useObjectsStore, useMapStore } from '@/stores'
// import Overlays from './components/overlays/overlays.vue'
import MapEditor from './components/map-editor/map-editor.vue'
import SMap from '@/components/s-map/s-map.vue'
// import OverlaysLegend from './components/overlays/components/overlays-legend.vue'
import SRuler from './components/map-ruler/s-ruler.vue'
import MapEditorSettingsButton from './components/map-editor/map-editor-settings-button.vue'
import CenterMap from '@/components/s-map/components/center-map.vue'

const editorStore = useEditorStore()
const objectsStore = useObjectsStore()
const mapStore = useMapStore()
const loading = ref(false)

const tabs = [
  { id: 'editor', name: 'Скважины' }
  // { id: 'overlays', name: 'Слои' }
]

const activeTab = ref('editor')

const changeTab = (value) => {
  activeTab.value = value
}

const mapgl = ref(null)

const initHandler = async (mapglInstance) => {
  mapgl.value = mapglInstance
  await loadImages(mapgl.value)
}

const isCreateModeOn = computed(() => {
  return editorStore.createModeOn
})

const closePopup = () => {
  editorStore.removePoint(editorStore.currentPopup.id, 'recent')
  editorStore.setMapEditorPopupOpen(false)
}

const closePopupOnEsc = (event) => {
  if (event.key === 'Escape' && editorStore.mapEditorPopupOpen) {
    closePopup()
  }
}

const centerMapHandler = () => {
  mapStore.setCenterMapOnActive(true)
}

watch(
  () => mapStore.centerMapOnActive,
  () => {
    centerMapOnActiveFeatures('data-points', mapgl.value)
  }
)

onMounted(() => {
  const clone = cloneDeep(objectsStore.excavationsList)
  editorStore.setClonePoints(clone)

  window.addEventListener('keydown', closePopupOnEsc)
})

onUnmounted(() => {
  window.removeEventListener('keydown', closePopupOnEsc)
})
</script>

<template>
  <div class="planning-page" :class="{ isCreateModeOn }" v-loading="loading">
    <div v-if="editorStore.mapEditorPopupOpen" class="editor__overlay" @click="closePopup"></div>

    <s-map
      module="data-map"
      :center="[47.999187, 46.275621]"
      :controls="true"
      @init="initHandler"
    />

    <div class="planning-page__content" v-if="mapgl">
      <div>
        <s-tabs :tabs="tabs" @click="changeTab" :value="activeTab" even-width>
          <s-tab id="editor" name="Скважины" :active="activeTab">
            <map-editor :mapgl="mapgl" />
          </s-tab>

          <!-- <s-tab id="overlays" name="Слои" :active="activeTab">
            <overlays :mapgl="mapgl" />
          </s-tab> -->
        </s-tabs>
      </div>
    </div>

    <center-map module="data" @click="centerMapHandler" />
    <!-- <overlays-legend /> -->
    <div class="planning-page__actions">
      <map-editor-settings-button />
      <s-ruler v-if="mapgl" :mapgl="mapgl" />
    </div>
  </div>
</template>

<style lang="scss">
.planning-page {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  & .s-tabs__list .list-item__header.active {
    border-radius: var(--border-radius);
  }

  &__actions {
    position: absolute;
    top: 16px;
    right: 80px;
    display: flex;
    gap: 24px;
  }

  &.isCreateModeOn {
    & .mapboxgl-canvas-container.mapboxgl-interactive {
      cursor: crosshair;
    }
  }

  .data-map {
    border-left: none;
  }

  & .s-tab.active {
    padding: 0;
  }

  &__content {
    background-color: var(--card-bg);
    border: 1px solid var(--bg);
    border-radius: 8px;
    color: var(--text);
    top: 16px;
    left: 16px;
    position: absolute;
    width: 320px;
    padding: 16px;
  }
}
</style>
