import { roundValue } from '@/utils'

const PROGRESS_WIDTH = 80
export const getObjectPercent = (completed = 0, all = 0) => {
  completed = Number(completed)
  all = all === 'null' ? 0 : Number(all)

  let value
  let width

  if (all === 0) {
    value = 0
    width = 0
  } else {
    value = roundValue((completed / all) * 100, 0)
    width = (PROGRESS_WIDTH * value) / 100
  }

  return {
    value,
    width
  }
}