<script setup>
import { computed, ref, watch, reactive } from 'vue'
import debounce from 'lodash.debounce'
import { useServicesStore } from '@/stores/index.js'

const serviceStore = useServicesStore()

const props = defineProps({
  template: {
    type: [Object, null],
    required: true
  },
  fields: {
    type: Array,
    required: true
  },
  vuelidate: {
    type: Object,
    default: null
  },
  step: {
    type: Object,
    required: true
  }
})

const getStatusServices = computed(() => {
  return serviceStore.object.statuses
})

const step = reactive(props.step)
const sources = ref(props.template)

watch(
  () => props.template,
  (newValue) => {
    sources.value = newValue
  }
)

const changeSelect = (value) => {
  sources.value.status = value
}

watch(
  () => ({
    title: sources.value?.title,
    title_short: sources.value?.title_short,
    num: sources.value?.num
  }),
  () => {
    checkAllFields()
  },
  { deep: true }
)

const checkAllFields = debounce(() => {
  const fieldsToCheck = ['title', 'title_short', 'num']
  let hasError = false

  fieldsToCheck.forEach((field) => {
    props.vuelidate?.[field]?.$validate()
    if (props.vuelidate?.[field]?.$error) {
      hasError = true
    }
  })

  step.warning = hasError
}, 500)
</script>

<template>
  <div v-if="sources" class="basic-information">
    <div v-for="field in fields" :key="field.id">
      <s-select
        :value="sources.status"
        @change="changeSelect"
        v-if="field.tag === 'select'"
        :options="getStatusServices"
        :selectLabel="field.label"
      />
      <s-switch
        v-else-if="field.tag === 'switch'"
        v-model="sources[field.title]"
        :label="field.label"
      />
      <s-input
        v-else
        :type="field.tagType"
        v-model="sources[field.title]"
        :label="field.label"
        :placeholder="field.placeholder"
        :maxlength="field.maxlength"
      />
      <span class="vuelidate-error">{{ vuelidate?.[field.title]?.$errors[0]?.$message }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.basic-information {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
}

.vuelidate-error {
  color: var(--error);
  font-size: 0.8em;
  text-align: left;
}
</style>
