export const objectClustersConfig = {
  source: {
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 75
  },
  clusters: {
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#3C98E7',
      'circle-opacity': 0.6,
      'circle-radius': ['step', ['get', 'point_count'], 20, 10, 30, 20, 40]
    }
  },
  clustersCount: {
    type: 'symbol',
    filter: ['has', 'point_count'],
    paint: {
      'text-color': '#FAFAFA'
    },
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['Arial Unicode MS Bold', 'DIN Offc Pro Medium'],
      'text-size': 16
    }
  }
}