<script setup>
import { ref, reactive, computed, nextTick, watch, onMounted } from 'vue'
import { useMq } from 'vue3-mq'

import { getItemWidth } from './helpers'
import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import AutocompleteTags from '@/components/s-autocomplete-tags/s-autocomplete-tags.vue'

const props = defineProps({
  field: {
    type: Object,
    required: true
  },
  source: {
    type: Object,
    required: true
  },
  services: {
    type: Object,
    default: () => {}
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: 'Обязательное поле'
  }
})

const mq = useMq()

const emits = defineEmits(['change', 'input'])

const initSource = reactive(props.source)
const showComment = ref(false)
const hideTagsTimer = ref(null)
const textareaRef = ref(null)

const isShowAutocompleteTags = ref(false)
const autocompleteTagsRef = ref(null)

const filteredTags = computed(() => {
  const { autocompleteTags } = props.field
  if (!autocompleteTags) return []

  const { tags, conditions } = autocompleteTags
  if (tags) {
    return filterOutSubstring(tags, props.source[props.field.title])
  }

  if (!conditions) return []

  const { field, defaultValue, values } = conditions
  const fieldValue = props.source[field]

  if (!fieldValue) {
    return filterOutSubstring(defaultValue, props.source[props.field.title])
  }

  if (fieldValue && values[fieldValue]) {
    return filterOutSubstring(values[fieldValue], props.source[props.field.title])
  }

  return []
})

const filterOutSubstring = (array, substring) => {
  if (!substring) return array

  return array.filter((item) => !substring.toLowerCase().includes(item.toLowerCase()))
}

const scrollToTags = () => {
  const modal = document.querySelector('.s-modal__body')
  let nextDiv = modal.querySelector('div')
  const element = textareaRef.value.$parent.$el
  const coordinates = element.getBoundingClientRect()
  const nextDivCoordinates = nextDiv.getBoundingClientRect()

  const elementTopRelativeToNextDiv = coordinates.top - nextDivCoordinates.top + nextDiv.scrollTop

  const elementHeight = element.offsetHeight

  const scrollToPosition = elementTopRelativeToNextDiv + elementHeight - nextDiv.clientHeight

  nextDiv.scrollTo({
    top: scrollToPosition,
    behavior: 'smooth'
  })
}

const focusBlurHandler = (type) => {
  if (type === 'focus') {
    textareaRef.value.$el.focus()
  } else {
    hideTagsTimer.value = setTimeout(() => {
      isShowAutocompleteTags.value = false
    }, 300)
  }
}
const focusHandle = () => {
  isShowAutocompleteTags.value = true
}
const tagClickHandler = (tag) => {
  if (hideTagsTimer.value) {
    clearTimeout(hideTagsTimer.value)
    hideTagsTimer.value = null
  }

  if (!initSource[props.field.title]) {
    initSource[props.field.title] = tag
    focusBlurHandler('focus')
    return
  }

  initSource[props.field.title] += ' ' + tag

  focusBlurHandler('focus')
  nextTick(() => {
    scrollToTags()
  })
}

watch(
  () => isShowAutocompleteTags.value,
  (newValue) => {
    if (newValue && filteredTags.value.length > 0) {
      nextTick(() => {
        scrollToTags()
      })
    }
  }
)

const maxDate = computed(() => {
  if (!props.field.dateOptions) return null
  const { max } = props.field.dateOptions

  if (!props.field.dateOptions.condition) {
    if (!max) return null

    if (max === 'today') return new Date()
  }

  const { field, value, lessThenCurrentDay } = props.field.dateOptions.condition
  if (!field || !value) return null
  if (!props.source[field]) return null

  if (lessThenCurrentDay && value === 'less') {
    if (value === 'less') {
      const todayDate = new Date()
      const otherFieldDate = new Date(props.source[field])
      return todayDate > otherFieldDate ? otherFieldDate : todayDate
    }
  }

  if (value === 'less') {
    return new Date(props.source[field])
  }

  return null
})

const minDate = computed(() => {
  if (props.field.excav_date_from) {
    return props.field.excav_date_from
  }

  if (!props.field.dateOptions) return null
  if (!props.field.dateOptions.condition) return null
  const { field, value } = props.field.dateOptions.condition
  if (!field || !value) return null

  if (!props.source[field]) return null

  if (value === 'more') {
    return new Date(props.source[field])
  }

  return null
})

const sync_statuses = [
  {
    title: 'Не начата',
    id: 0
  },
  {
    title: 'В процессе',
    id: 1
  },
  {
    title: 'Завершена',
    id: 2
  }
]

const getServices = (val) => {
  if (val === 'sync_statuses') {
    return sync_statuses
  }

  if (Array.isArray(props.services)) {
    return props.services.map((element) => {
      return {
        title: getOptionLabel(element),
        id: element.id
      }
    })
  }

  const services = props.services?.[val]
  if (!services) return []

  const arrWithEndings = services.map((element) => {
    return {
      title: getOptionLabel(element),
      id: element.id,
      disabled: element.disabled
    }
  })
  if (Array.isArray(arrWithEndings)) return arrWithEndings
  else return Object.values(arrWithEndings)
}

const changeSelect = (e) => {
  initSource[props.field.title] = e
  emits('input', e)
}

const getOptionLabel = (item) => {
  if (props.field.service !== 'soil_types') {
    return item.title + (item.end1 || '')
  } else {
    return item.title
  }
}

onMounted(() => {
  if (props.field.commentField) {
    showComment.value = !!initSource[props.field.commentField]
  }

  if (props.field.type === 'date' && props.field.setCurrentDate && !initSource[props.field.title]) {
    initSource[props.field.title] = new Date()
  }
})
</script>

<template>
  <div class="input-renderer" :style="getItemWidth(field)">
    <div class="input-renderer__text">
      <s-text v-if="field.label">
        {{ field.label }}
      </s-text>
      <wiki-tip v-if="field.wikiTipData" :data="field.wikiTipData">
        <s-icon
          name="fa-circle-question"
          :color="`var(--${field.wikiTipData.color ? field.wikiTipData.color : 'caption'})`"
        />
      </wiki-tip>
    </div>
    <s-input
      v-if="field.type === 'string'"
      v-model="initSource[field.title]"
      :maxlength="field.maxlength"
      clearable
      :type="field.type"
      :placeholder="field.placeholder || ''"
      :disabled="disabled"
      @change.once="emits('change')"
    />
    <s-number-input
      v-else-if="field.type === 'number'"
      v-model="initSource[field.title]"
      :min="field.min"
      :max="field.max"
      :placeholder="field.placeholder || ''"
      :disabled="disabled"
      clearable
      @input.once="emits('change')"
    />
    <div v-else-if="field.type === 'textarea'" class="input-renderer__textarea">
      <s-input
        ref="textareaRef"
        v-model="initSource[field.title]"
        :clearable="field.clearable"
        :placeholder="field.placeholder || ''"
        :disabled="disabled"
        :maxlength="field.maxlength"
        type="textarea"
        :rows="3"
        @focus="focusHandle"
        @blur="focusBlurHandler('blur')"
        @change="emits('change')"
      />
      <autocomplete-tags
        v-if="filteredTags.length > 0 && isShowAutocompleteTags"
        ref="autocompleteTagsRef"
        :autocomplete-tags="filteredTags"
        @click="tagClickHandler"
      />
    </div>
    <s-input
      v-else-if="field.type === 'password'"
      v-model="initSource[field.title]"
      :placeholder="field.placeholder || ''"
      :disabled="disabled"
      :maxlength="field.maxlength"
      type="password"
    />
    <s-switch
      v-else-if="field.type === 'switch'"
      v-model="initSource[field.title]"
      @change.once="emits('change')"
    />
    <s-date
      v-else-if="field.type === 'date'"
      v-model="initSource[field.title]"
      :format="field.format"
      :max-date="maxDate"
      :min-date="minDate"
      :enable-time-picker="field.enableTimePicker"
      input-style
      label=""
      @input.once="emits('change')"
    />
    <s-select
      v-else-if="field.type === 'select' && getServices(field.service)"
      :value="initSource[field.title]"
      :placeholder="field.placeholder || 'Не выбрано'"
      :filterable="mq.current === 'lg'"
      :clearable="field.clearable"
      :multiple="field.multiple"
      :disabled="disabled"
      @change="changeSelect"
      @change.once="emits('change')"
      :options="getServices(field.service)"
    >
    </s-select>
    <s-text v-if="error" type="error"> {{ errorMessage }} </s-text>
    <s-text v-if="field.caption" type="secondary">
      {{ field.caption }}
    </s-text>
    <s-button
      v-if="field.commentField && !showComment"
      class="input-renderer__comment-btn"
      link
      @click="showComment = true"
    >
      Дополнить описание
    </s-button>
    <s-input
      v-if="showComment"
      v-model="initSource[field.commentField]"
      :maxlength="250"
      :label="field.commentTitle || 'Комментарий'"
      type="textarea"
      @change.once="emits('change')"
    />
  </div>
</template>

<style lang="scss">
.input-renderer {
  display: grid;
  grid-gap: 0.5rem;
  align-content: start;

  &__textarea {
    overflow: auto;
  }

  &__text {
    display: flex;
    justify-content: space-between;
  }

  &__comment-btn {
    justify-self: end;
  }
}
</style>
