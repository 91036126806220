<script setup>
import { computed } from 'vue'
const props = defineProps({
  groundwater: {
    type: Object,
    default: () => null
  },
  fixed: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'default'
  }
})

const depth = computed(() => {
  return props.groundwater.level_d?.toFixed(1) || null
})
</script>

<template>
  <div class="groundwater-type">
    <div :class="[`groundwater-type-icon`, { fixed }]" />
    <s-text v-if="type === 'simple'" type="info">
      {{ depth }}
    </s-text>
  </div>
</template>

<style lang="scss">
.groundwater-type {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 0.5rem;

  &-icon {
    position: relative;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    background-color: var(--primary);
    clip-path: polygon(0 15%, 100% 15%, 55% 100%, 44% 100%, 85% 25%, 15% 25%, 55% 100%, 45% 100%);

    &.fixed {
      clip-path: polygon(90% 20%, 10% 20%, 50% 100%);
    }
  }
}
</style>
