<script setup>
import { onMounted, ref, watch } from 'vue'
import { useMq } from 'vue3-mq'

import { useMainStore, useExcavationStore } from '@/stores'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import SampleType from './sample-type.vue'
import SAttachmentsImages from '@/components/s-attachments/s-attachments-images.vue'

const props = defineProps({
  no: {
    type: Number,
    required: true
  },
  sample: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['click-handler'])

const mq = useMq()
const mainStore = useMainStore()
const excavationStore = useExcavationStore()
const { getRequest } = useRequests()

const images = ref([])

const getDepth = (sample) => {
  const { selection_from_d, selection_to_d } = sample

  return `${selection_from_d?.toFixed(1)} - ${selection_to_d?.toFixed(1)}`
}

watch(
  () => excavationStore.updateSampleByID,
  (id) => {
    if (id === props.sample.id) {
      loadImages()
      excavationStore.setField('updateSampleByID', null)
    }
  }
)

onMounted(() => {
  loadImages()
})

const loadImages = async () => {
  const { id, server_id } = props.sample

  if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
    const data = await getRequest(`samples/${server_id}/images/`)

    images.value = data || []
  }

  const idb = await db.images
    .where({
      table: 'samples',
      item_id: id
    })
    .toArray()
  const idbParsed = idb?.map((e) => {
    return {
      id: `idb_${e.id}`,
      title: e.image.name,
      blob: e.image.file
    }
  })
  images.value.push(...idbParsed)
}
</script>

<template>
  <div class="samples-list__item" @click="emits('click-handler', sample)">
    <s-text>{{ no }}</s-text>
    <s-text type="info">
      {{ getDepth(sample) }}
    </s-text>
    <s-attachments-images sourceType="sample" :source="sample" :items="images" thumbnails />
    <sample-type :sample="sample" show-comment :only-icon="mq.current === 'sm'" />
  </div>
</template>

<style lang="scss">
.samples-list__item {
  display: grid;
  grid-template-columns: minmax(1.5rem, auto) minmax(5rem, auto) auto auto;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  grid-gap: 2rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  background-color: var(--bg);
  cursor: pointer;
}
</style>
