import { defineStore } from 'pinia'
import { orderBy } from 'natural-orderby'

export const usePrintStore = defineStore('print', {
  state: () => ({
    dashboard: null,
    showExcavations: true,
    loading: false
  }),
  getters: {
    sortedExcavationsToPrint: (state) => {
      const { excavationsList } = state.dashboard
      return orderBy(excavationsList, [(v) => v['title']], ['asc'])
    },
    filteredProductions () {
      if (!this.dashboard) return null
      
      return this.dashboard.unparsedChartData?.productions?.filter(
        (item) => item.production !== undefined
      )
    }
  },
  actions: {
    setField(field, value) {
      this[field] = value
    }
  }
})
