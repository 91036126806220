<script setup>
import { computed, onMounted, ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import SelectComp from './soil-select-comp.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const clone = ref(cloneDeep(props.source))

const serviceName = computed(() => {
  if (props.source?.soil_type >= 11201 && props.source?.soil_type <= 11205) {
    return 'sand'
  } else if (props.source?.soil_type >= 12102 && props.source?.soil_type <= 12103) {
    return 'clay'
  } else if (props.source?.soil_type === 12101) {
    return 'sandy_clay'
  } else {
    return 'other'
  }
})

const waterField = computed(() => {
  return {
    ...props.field,
    service: `water_saturations_${serviceName.value}`
  }
})

const hasChanges = computed(() => {
  return !!clone.value[props.field.id]
})

onMounted(() => {
  if (props.source[props.field.id]) {
    emits('trigger-changed', hasChanges.value)
  }
})
</script>

<template>
  <select-comp
    :source="source"
    :soil-interval="soilInterval"
    :field="waterField"
    @trigger-changed="emits('trigger-changed', $event)"
  />
</template>
