import { getFromService } from './index'

export const getRockDescription = (parent, data) => {
  let soilDescription =
    parent?.soilName + (parent?.comment ? `, ${parent?.comment?.toLowerCase()}` : '')

  if (parent?.soilSource) {
    const items1 = attrs1.map((e) => {
      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredItems1 = items1.filter((e) => e)

    if (filteredItems1?.length) {
      filteredItems1.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  parentAttrs1.forEach((a) => {
    if (parent[a]) {
      soilDescription += `, ${parent[a]?.toLowerCase()}`
    }
  })

  if (parent?.soilSource) {
    let appendText = false

    const items2 = attrs2.map((e) => {
      const value = getFromService(parent?.soilSource, data, e, parent?.soilServices)
      const fields = [
        'alteration_fractures_width',
        'alteration_fractures_angles',
        'alteration_fractures_compos'
      ]
      if (!appendText && fields?.includes(e.id) && value) {
        appendText = true
        return `трещины ${value}`
      } else {
        return value
      }
    })

    const filteredItems2 = items2.filter((e) => e)

    if (filteredItems2?.length) {
      filteredItems2.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  return soilDescription
}

const attrs1 = [{ id: 'cement', service: 'cements' }, { id: 'cement_comments' }]

const parentAttrs1 = ['color', 'colorComment']

const attrs2 = [
  { id: 'struct_thaw_grains', service: 'structs_thaw_grains' },
  { id: 'text_thaw', service: 'texts_thaw' },
  { id: 'struct_text_comments' },
  { id: 'alteration_weath', service: 'alterations_weath' },
  { id: 'strength', service: 'strengths' },
  { id: 'density', service: 'densities' },
  { id: 'strength_comments' },
  {
    id: 'alteration_fractures_integrity',
    service: 'alterations_fractures_integrity'
  },
  { id: 'alteration_fractures_width', service: 'alterations_fractures_width' },
  {
    id: 'alteration_fractures_angles',
    service: 'alterations_fractures_angles'
  },
  {
    id: 'alteration_fractures_compos',
    service: 'alterations_fractures_compos'
  },
  { id: 'alteration_fractures_comments' },
  { id: 'composition_chem', service: 'compositions_chem' }
]
