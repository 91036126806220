<script setup>
import { ref, computed, inject, onMounted, onUnmounted, watch } from 'vue'
import { useMq } from 'vue3-mq'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

import { useMainStore, useMapStore, useReconStore } from '@/stores'
import { DataController } from './controllers/data.js'
import { editRecon, reloadWindow } from '@/utils'
import { basicFields, secondaryFields, galleryFields } from './configs/data-card.js'
import { hasDataPermissions } from '@/utils'

import CardLayout from '@/layouts/card-layout.vue'
import DataStatusSwitcher from '@/components/data-status-switcher.vue'
import DataMap from '@/modules/app/data/maps/data-map/data-map.vue'
import DataDesktopLayout from '@/layouts/desktop/data-desktop-layout.vue'
import ReconEditor from './components/recon-editor/recon-editor.vue'
import DataCard from '@/components/data-card.vue'
import ReconStart from './components/recon-start/recon-start.vue'
import DataSyncNotify from '../components/data-sync-notify.vue'

const mq = useMq()
const route = useRoute()
const router = useRouter()
const $notify = inject('$notify')

const mainStore = useMainStore()
const reconStore = useReconStore()
const mapStore = useMapStore()

onBeforeRouteLeave((_, from, next) => {
  mainStore.setLastRoutePath(['SET_LAST_OBJECT_PATH', from.fullPath])
  next()
})

const ready = ref(false)
const reconUpdating = ref(false)
const dataController = ref(null)

const recon = computed(() => {
  return reconStore.active
})
const reconTitle = computed(() => {
  return recon.value?.title || '-'
})

const reconId = computed(() => {
  return route.query.id
})

const objectId = computed(() => {
  return recon.value?.object_id
})

const isIdb = computed(() => {
  return String(reconId.value).includes('idb_')
})

const parsedId = computed(() => {
  const id = !isIdb.value ? reconId.value : reconId.value.replace('idb_', '')
  return id ? Number(id) : null
})

watch(
  () => reconStore.updateReconCommonImages,
  async (newValue) => {
    if (newValue) {
      dataController.value.updateHandler('commonImages')
      reconStore.setField('updateReconCommonImages', false)
    }
  }
)

watch(
  () => reconStore.updateReconJournalImages,
  async (newValue) => {
    if (newValue) {
      dataController.value.updateHandler('journalImages')
      reconStore.setField('updateReconJournalImages', false)
    }
  }
)

watch(
  () => parsedId.value,
  async () => {
    reconUpdating.value = true
    await dataController.value.loadAllAssets('update')
    reconUpdating.value = false
  }
)

const init = async () => {
  try {
    mainStore.setLoading(true)

    await dataController.value.loadAllAssets()
    ready.value = true
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при загрузке точки наблюдения. ${e}`,
      type: 'error'
    })
  } finally {
    mainStore.setLoading(false)
  }
}

const flyTo = () => {
  mapStore.setCenterMapOnActive(true)
}

const goBack = () => {
  if (objectId.value) {
    router.push(`/app/data/objects/${objectId.value}`)
  } else {
    router.push('/app/data/objects/list')
  }
}

onMounted(() => {
  if (!parsedId.value) {
    const title = 'Ошибка'
    const message = `Некорректный идентификатор проекта. Обратитесь к администратору.`

    $notify({
      title,
      message,
      type: 'error'
    })

    router.push('/app/not-found')
  } else {
    dataController.value = new DataController()
    init()
  }
})

const updateHandler = () => {
  dataController.value.updateHandler()
}

const selectHandler = async (e, fieldName) => {
  const data = recon.value
  data[fieldName] = e
  const filter = { field: 'object_id', value: recon.value.object_id }

  await editRecon(data, false, false, filter)

  updateHandler()
}

const panelWidth = computed(() => {
  return mq.current === 'lg' ? '720px' : '100%'
})

const isShowEditModal = ref(false)

const editModal = () => {
  isShowEditModal.value = !isShowEditModal.value
}

const startModal = ref(false)

onUnmounted(() => {
  reconStore.setField('active', null)
  reconStore.setField('reconCommonImages', [])
  reconStore.setField('reconJournalImages', [])
})

const openModal = () => {
  if (recon.value.status === 4) {
    isShowEditModal.value = !isShowEditModal.value
  } else {
    startModal.value = !startModal.value
  }
}

const disabled = computed(() => {
  const geologist = recon.value?.geologist

  return !hasDataPermissions(geologist)
})

const isImageLoading = computed(() => {
  return !!reconStore.imageLoading.length || mainStore.isImagesLoading
})
</script>

<template>
  <data-desktop-layout :left-panel-width="panelWidth">
    <card-layout
      v-loading="reconUpdating"
      v-if="recon"
      :title="reconTitle"
      back-button
      @back="goBack()"
    >
      <template #header-actions>
        <div v-if="mq.current === 'lg'" v-tooltip="'Показать на карте'" class="action__crosshair">
          <s-button icon="location-crosshairs" @click="flyTo" />
        </div>
        <data-status-switcher :type="201" :item="recon" @change="selectHandler" />
        <div v-tooltip="'Обновить'">
          <s-button :disabled="isImageLoading" icon="arrows-rotate" @click="reloadWindow" />
        </div>
      </template>
      <div class="recon-page__content">
        <data-sync-notify :item="recon" />
        <data-card
          :isShowTitle="true"
          v-if="dataController"
          :item="recon"
          :object="dataController.object"
          :basic-fields="basicFields"
          :galleryFields="galleryFields"
          @edit-modal="editModal"
        />

        <div class="recon-page__subinfo">
          <s-button v-if="!disabled" type="success-plain" @click="openModal">{{
            recon.status === 4 ? 'Дополнить описание' : 'Начать описание'
          }}</s-button>
          <data-card
            single-column
            v-if="dataController && recon.status === 4"
            :item="recon"
            :object="dataController.object"
            :basic-fields="secondaryFields"
            @edit-modal="editModal"
          />
        </div>
      </div>
    </card-layout>
    <template #map>
      <data-map v-if="mq.current === 'lg'" type="data-points" />
    </template>

    <recon-start
      v-if="startModal"
      :is-visible="startModal"
      :recon="recon"
      @toggle="startModal = !startModal"
      @update-handler="updateHandler"
    />

    <recon-editor
      v-if="isShowEditModal"
      title="Редактирование"
      :is-visible="isShowEditModal"
      :recon="recon"
      @toggle="isShowEditModal = !isShowEditModal"
      @update-handler="updateHandler"
    />
  </data-desktop-layout>
</template>

<style lang="scss">
.recon-page {
  display: grid;
  height: 100%;
  grid-gap: 1.5rem;
  align-content: start;

  &__content {
    display: grid;
    height: 100%;
    grid-gap: 1.5rem;
    align-content: start;
  }

  &__subinfo {
    display: grid;
    height: 100%;
    grid-gap: 16px;
    align-content: start;
  }
}

.action__crosshair {
  & svg {
    font-size: 14px;
  }
}
</style>
