export const listTips = {
  all: {
    title: 'Список данных пуст',
    description: 'В данном проекте отсутствуют доступные вам скважины или точки наблюдения.',
    additional: 'Создайте новые данные или обратитесь к администратору'
  },
  default: {
    title: 'Список скважин пуст',
    description: 'В данном проекте отсутствуют доступные вам скважины.',
    additional: 'Создайте новые скважины или обратитесь к администратору'
  },
  recons: {
    title: 'Список точек наблюдения пуст',
    description: 'В данном проекте отсутствуют доступные вам точки наблюдения.',
    additional: 'Создайте новые точки наблюдения или обратитесь к администратору'
  },
  filtered: {
    title: 'Ничего не найдено',
    description: 'По вашему запросу не удалось найти данные',
    image: 'filter'
  }
}
