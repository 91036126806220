export const filtersConfig = {
  filters: [
    {
      id: 'status',
      filterTitle: 'Статус',
      filterValues: [
        {
          id: 1,
          title: 'Не начата',
          value: true
        },
        {
          id: 2,
          title: 'Вынесена',
          value: true
        },
        {
          id: 3,
          title: 'Бурение',
          value: true
        },
        {
          id: 4,
          title: 'Закрыта',
          value: true
        }
      ]
    },
    {
      id: 'geologist',
      filterTitle: 'Геолог',
      filterValues: []
    }
  ],
  sort: {
    value: 'title',
    order: 'asc',
    list: [
      {
        id: 'title',
        title: 'Номер'
      },
      {
        id: 'date',
        title: 'Дата бурения'
      }
    ]
  }
}

export const excavationsConfig = [
  {
    width: 36,
    type: 'checkbox'
  },
  {
    width: 72,
    type: 'title',
    titleType: 'excavation'
  },
  {
    width: 88,
    center: true,
    type: 'status'
  },
  {
    width: 80,
    center: true,
    type: 'h'
  },
  {
    width: 96,
    center: true,
    type: 'date'
  },
  {
    stretched: true,
    center: true,
    type: 'geologist'
  },
  {
    stretched: true,
    center: true,
    type: 'site'
  }
]

export const headerExcavationsConfig = [
  {
    id: 'title',
    width: 72,
    label: 'Номер'
  },
  {
    id: 'status',
    width: 88,
    label: 'Статус',
    center: true
  },
  {
    id: 'height',
    width: 80,
    label: 'Глубина',
    center: true
  },
  {
    id: 'date',
    width: 96,
    label: 'Дата',
    center: true
  },
  {
    id: 'geologist',
    label: 'Геолог',
    stretched: true,
    center: true
  },
  {
    id: 'site',
    width: 96,
    label: 'Участок',
    stretched: true,
    center: true
  }
]

export const reconsConfig = [
  {
    width: 36,
    type: 'checkbox'
  },
  {
    width: 72,
    type: 'title',
    titleType: 'recon',
    stretched: true,
    center: true
  },
  {
    width: 88,
    center: true,
    type: 'status'
  },
  {
    width: 96,
    center: true,
    type: 'date'
  },
  {
    stretched: true,
    center: true,
    type: 'geologist'
  },
  {
    stretched: true,
    center: true,
    type: 'site'
  }
]

export const headerReconsConfig = [
  {
    id: 'title',
    width: 72,
    label: 'Номер',
    stretched: true,
    center: true
  },
  {
    id: 'status',
    width: 88,
    label: 'Статус',
    center: true
  },
  {
    id: 'date',
    width: 96,
    label: 'Дата',
    center: true
  },
  {
    id: 'geologist',
    label: 'Геолог',
    stretched: true,
    center: true
  },
  {
    id: 'site',
    width: 96,
    label: 'Участок',
    stretched: true,
    center: true
  }
]
