<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores/index.js'

const props = defineProps({
  sample: {
    type: Object,
    required: true
  },
  onlyIcon: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'default'
  },
  showComment: {
    type: Boolean,
    default: false
  }
})

const servicesStore = useServicesStore()

const typeIconName = computed(() => {
  switch (props.sample.type) {
    case 1:
      return 'monolith'
    case 11:
      return 'water'
    default:
      return 'broken'
  }
})

const typeName = computed(() => {
  return servicesStore.sample?.types?.find((e) => e.id === props.sample.type)?.title || '-'
})

const depth = computed(() => {
  return props.sample.selection_from_d?.toFixed(1)
})
</script>

<template>
  <div class="samples-type">
    <div class="samples-type-row">
      <div :class="[`samples-type-icon ${typeIconName}`]" />
      <s-text v-if="type === 'simple' && !onlyIcon" type="info">
        {{ depth }}
      </s-text>
      <s-text v-else-if="type !== 'simple' && !onlyIcon" type="info">
        {{ typeName }}
      </s-text>
    </div>
    <div v-if="showComment && !onlyIcon" class="samples-type-row">
      <s-text v-if="sample.comments" type="secondary">
        {{ sample.comments }}
      </s-text>
    </div>
  </div>
</template>

<style lang="scss">
.samples-type {
  display: grid;
  grid-gap: 0.5rem;

  &-row {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    grid-gap: 0.5rem;
  }

  &-icon {
    position: relative;
    width: 16px;
    height: 16px;
    box-sizing: border-box;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      background-color: var(--info);
    }

    &.broken {
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

      &::after {
        clip-path: polygon(50% 15%, 5% 100%, 95% 100%);
      }
    }

    &.water {
      border: 1px solid var(--bg);
      border-radius: 50%;
      background-color: var(--primary);

      &::after {
        content: none;
      }
    }

    &.level {
      clip-path: polygon(90% 20%, 10% 20%, 50% 100%);
      background-color: var(--primary);

      &::after {
        content: none;
      }
    }
  }
}
</style>
