import cloneDeep from 'lodash.clonedeep'
import { getDataChanges, getUuid } from '@/utils'
import { useMainStore, useAuthStore } from '@/stores'
import { useRitmDate, useRequests } from '@/composables'
import db from '@/libs/db'
import appGlobalProperties from '@/main'

const parseDateFields = (data) => {
  const rDate = useRitmDate()

  data.date_front = rDate(new Date()).format('iso')

  if (data.date_from) {
    data.date_from = rDate(data.date_from).format('YYYY-MM-DD')
  }

  if (data.date_to) {
    data.date_to = rDate(data.date_to).format('YYYY-MM-DD')
  }
}

export const createRecon = async (objectId, recon, loading, callback, filter) => {
  const mainStore = useMainStore()
  const { postRequest } = useRequests()

  loading = true
  try {
    let data = cloneDeep(recon)
    data.uuid = getUuid()
    parseDateFields(data)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      const response = await postRequest(`objects/${objectId}/reconnaissance/`, data)
      if (!response) return
      data = response

      data.server_id = response?.id
      delete data.id
    }
    data.object_id = objectId

    const id = await db.addObject('recons', data, filter)

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      await db.created.add({
        table: 'recons',
        date: new Date(),
        item_id: id
      })
      getDataChanges()
    }

    const title = 'Создание'
    const message = `Точка наблюдения "${data.title}" успешно создана`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback()
    }
  } catch (error) {
    const title = 'Создание'
    const message = `Ошибка при создании точки наблюдения ${error}`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading = false
  }
}

export const editRecon = async (recon, loading, callback, filter) => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()
  const { putRequest } = useRequests()

  loading = true
  try {
    recon.date_front = new Date()

    if (!recon.geologist) {
      recon.geologist = authStore.userId
    }

    const toServer = Object.assign({}, recon)
    const toIdb = Object.assign({}, recon)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      toServer.id = toServer.server_id
      delete toServer.object_id
      toServer.type = null
      await putRequest(`reconnaissance/${toServer.id}/`, toServer)
    }

    await db.updateObject('recons', toIdb, filter)

    if ((!mainStore.isOnline || mainStore.noSyncMode) && recon.server_id) {
      const updatedItems = await db.updated.where('table').equals('recons').toArray()
      const isExist = !!updatedItems.find((e) => e.item_id === recon.server_id)

      if (!isExist) {
        await db.updated.put({
          table: 'recons',
          item_id: recon.server_id,
          date: new Date()
        })
        getDataChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Выработка "${toIdb.title}" успешно отредактирована`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback(true)
    }
  } catch (error) {
    const title = 'Редактирование'
    const message = `Ошибка при редактировании выработки ${error}`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading = false
  }
}
