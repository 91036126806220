<script setup>
import { computed } from 'vue'
import { useMq } from 'vue3-mq'

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  width: {
    type: Number,
    default: 800
  },
  simple: {
    type: Boolean,
    default: false
  }
})

const mq = useMq()

const className = computed(() => {
  return {
    'centered-container--simple': props.simple
  }
})

const styleSettings = computed(() => {
  if (mq.current === 'lg') {
    return {
      width: `${props.width}px`
    }
  }

  return {
    width: '100%',
    flex: 1
  }
})
</script>

<template>
  <div class="centered-container" :class="className" :style="styleSettings">
    <div v-if="title" class="centered-container__header">
      <s-title type="title">{{ title }}</s-title>
    </div>
    <div class="centered-container__container">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.dark-theme {
  --cc-border-color: var(--bg);
}

.light-theme {
  --cc-border-color: var(--main-bg);
}

.centered-container {
  padding: 1rem;
  background: var(--card-bg);
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 1.5rem;
  }

  &__container {
    flex: 1;
    min-height: 0;
  }
}
.centered-container {
  & .s-tabs {
    & .s-tab {
      overflow: hidden;
    }
  }
}
.lg {
  .centered-container {
    padding: 1.5rem;
    border: 1px solid var(--cc-border-color);
    margin: 2rem auto;
    border-radius: 8px;
    min-height: 0;
    flex: 1;

    &--simple {
      background: none;
      border: none;
      box-shadow: none !important;
      padding: 1rem 0 !important;
    }
  }
}

.md,
.sm {
  .centered-container {
    padding: 1rem;
  }
}
</style>
