<script setup>
import { computed } from 'vue'
import { useObjectsStore, useServicesStore } from '@/stores'
import { useRoute } from 'vue-router'
import { formatStringArray } from '@/utils'

defineProps({
  excavationTitle: {
    type: String,
    default: null
  }
})

const objectsStore = useObjectsStore()
const servicesStore = useServicesStore()
const route = useRoute()

const errorsExcavsTitles = computed(() => {
  if (!excavationsList.value.length) return []

  const objectUsersIds = new Set(servicesStore.object_users?.map((item) => item.id))
  const errorsExcavsArray = []

  excavationsList.value.forEach((item) => {
    if (item.geologist && !objectUsersIds.has(item.geologist)) {
      errorsExcavsArray.push(item.title)
    }
  })

  return [...new Set(errorsExcavsArray)]
})

const excavationsList = computed(() => {
  const excavList = objectsStore.excavationsList
  if (!excavList) return []
  return excavList
})

const errorExcavString = computed(() => {
  const excavList = objectsStore.excavationsList
  if (!excavList) return

  if (!errorsExcavsTitles.value.length) {
    return null
  }

  return formatStringArray(errorsExcavsTitles.value)
})

const isExcavPage = computed(() => {
  return route.name === 'excavation'
})
</script>

<template>
  <s-alert
    v-if="isExcavPage && errorsExcavsTitles.includes(excavationTitle)"
    class="unassigned-geologists-tip"
    icon="circle-info"
    type="primary"
  >
    <s-text> На текущую скважину назначен геолог, который не добавлен в проект. </s-text>
  </s-alert>
  <s-alert
    v-if="!isExcavPage && errorExcavString"
    class="unassigned-geologists-tip"
    icon="circle-info"
    type="primary"
  >
    <s-text>
      У вас имеются скважины с назначенными геологами, которые не добавлены в проект – скв.
      {{ errorExcavString }}. Проверьте эти скважины в
      <router-link class="unassigned-geologists-tip__link" :to="'./sheets'">
        модуле Таблицы.
      </router-link>
    </s-text>
  </s-alert>
</template>

<style lang="scss">
.unassigned-geologists-tip {
  max-width: 100%;

  &__link {
    color: var(--primary);
  }

  &__icon {
    margin-left: 4px;
  }
}
</style>
