<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import debounce from 'lodash.debounce'
import { defaultView } from '@/configs/map'
import {
  useMainStore,
  useMapStore,
  useObjectsStore,
  useExcavationStore,
  useReconStore
} from '@/stores'
import { getDataFromLS, saveDataToLS, loadImages } from '@/utils'
import { centerMapOnActiveFeatures } from './helpers'

import { ObjectsController, DataPointsController } from './controllers'

import SMap from '@/components/s-map/s-map.vue'
import CenterMap from '@/components/s-map/components/center-map.vue'
import MapPopup from '@/components/s-map/components/map-popup.vue'

const props = defineProps({
  type: {
    type: String,
    validator: (value) => ['objects', 'data-points'].includes(value),
    default: 'objects'
  }
})

const route = useRoute()
const mainStore = useMainStore()
const mapStore = useMapStore()
const objectsStore = useObjectsStore()
const excavationStore = useExcavationStore()
const reconStore = useReconStore()

const loading = ref(false)
const mapConfig = ref(null)
const mapgl = ref(null)

const objectsController = ref(null)
const dataController = ref(null)

const popupSettings = ref({
  x: 0,
  y: 0,
  show: false,
  title: '',
  description: []
})

const initHandler = async (mapglInstance) => {
  if (mainStore.isOnline) loading.value = true

  mapgl.value = mapglInstance

  objectsController.value = new ObjectsController(mapgl.value)
  dataController.value = new DataPointsController(mapgl.value, route, popupSettings.value)

  await loadImages(mapgl.value)
  renderData()

  mapgl.value.on('style.load', async () => {
    await loadImages(mapgl.value)
    renderData('without-fly-to')
  })
  mapgl.value.on('moveend', saveMapParams)

  setTimeout(() => {
    mapgl.value.resize()
    loading.value = false
  }, 300)
}

const renderData = (type) => {
  switch (props.type) {
    case 'data-points': {
      dataController.value.showDataPoints(type)
      return
    }
    default: {
      objectsController.value.showObjects(type)
    }
  }
}

const saveMapParams = () => {
  if (!mapgl.value) return

  const mapCenter = mapgl.value.getCenter()
  const config = {
    center: [mapCenter.lng, mapCenter.lat],
    zoom: mapgl.value.getZoom()
  }

  saveDataToLS('mapConfig', config)
}

const centerMapHandler = () => {
  mapStore.setCenterMapOnActive(true)
}

onMounted(() => {
  const config = getDataFromLS('mapConfig')

  mapConfig.value = config ? config : defaultView
})

// lists watchers
const debouncedUpdateDataPoints = debounce((type) => {
  if (props.type !== 'data-points') return
  if (!mapgl.value || !mapgl.value.loaded()) return
  if (!dataController.value) return

  dataController.value.showDataPoints(type)
}, 500)

watch(() => objectsStore.excavationsList, debouncedUpdateDataPoints)
watch(() => objectsStore.reconsList, debouncedUpdateDataPoints)

watch(() => excavationStore.active, debouncedUpdateDataPoints, { deep: true })
watch(() => reconStore.active, debouncedUpdateDataPoints, { deep: true })

// filters watchers
watch(
  () => route.query.type,
  () => debouncedUpdateDataPoints('without-fly-to')
)

// map settings watchers
watch(
  () => mapStore.settings.clusters,
  () => {
    if (!dataController.value) return

    dataController.value.showDataPoints('without-fly-to')
  }
)

watch(
  () => mapStore.settings.processes,
  (value) => {
    if (value) {
      dataController.value.addProcesses()
    } else {
      dataController.value.removeProcesses()
    }
  }
)

// toggle nav panel animation watchers
let interval = null

watch(
  () => objectsStore.isCollapsed,
  () => {
    interval = setInterval(() => {
      if (mapgl.value) mapgl.value.resize()
    }, 5)
  }
)

watch(
  () => objectsStore.isCollapseAnimationEnd,
  () => {
    clearInterval(interval)
    interval = null
  }
)

// map center watcher
watch(
  () => mapStore.centerMapOnActive,
  () => {
    centerMapOnActiveFeatures(props.type, mapgl.value, route.query.type)
  }
)
</script>

<template>
  <div class="data-map" v-loading="loading">
    <s-map
      v-if="mapConfig"
      module="data-map"
      :center="mapConfig.center"
      :zoom="mapConfig.zoom"
      :settings="type === 'data-points'"
      :legend="type === 'data-points'"
      @init="initHandler"
    />
    <center-map module="data" @click="centerMapHandler" />
    <map-popup v-if="popupSettings.show" :settings="popupSettings" />
  </div>
</template>

<style lang="scss">
.data-map {
  flex: 1;
  position: relative;
  border-left: 1px solid var(--section-border-color);
  background-color: var(--secondary-bg);
}

.sm,
.md {
  .data-map {
    border-left: none;
  }
}
</style>
