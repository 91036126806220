import { lineString } from '@turf/helpers'
import length from '@turf/length'

export const incrementTitleNumber = (title) => {
  switch (typeof title) {
    case 'number':
      return title + 1
    case 'string':
      return title.replace(/(\d+)(?!.*\d)/, function (match) {
        return parseInt(match) + 1
      })

    default:
      return title
  }
}

export const throttle = (mainFunction, delay) => {
  let timerFlag = null

  return (...args) => {
    if (timerFlag === null) {
      mainFunction(...args)
      timerFlag = setTimeout(() => {
        timerFlag = null
      }, delay)
    }
  }
}

export const calculatePosition = (mapgl, lng, lat) => {
  const canvas = mapgl.getCanvas()
  const canvasWidth = canvas.width
  const canvasHeight = canvas.height

  const point = mapgl.project([lng, lat])

  const distanceToTop = point.y
  const distanceToBottom = canvasHeight - point.y
  const distanceToLeft = point.x
  const distanceToRight = canvasWidth - point.x

  let anchor = 'left'

  if (distanceToTop < 171) {
    anchor = 'top'
  } else if (distanceToBottom < 171) {
    anchor = 'bottom'
  }

  if (distanceToLeft < 300) {
    anchor = 'left'
  } else if (distanceToRight < 300) {
    anchor = 'right'
  }

  return anchor
}

export const calculateDistance = (coords) => {
  const line = lineString(coords)

  return length(line, { units: 'meters' })
}
