<template>
  <s-alert type="primary" icon="info-circle">
    <div class="import-tip">
      <s-text> В импортируемом файле должны быть указаны следующие атрибуты: </s-text>
      <s-text>
        <ul class="import-tip__list">
          <li>– <b>num</b> – номер</li>
          <li>– <b>h</b> – проектная глубина, м (для скважин)</li>
          <li>– <b>site</b> – участок</li>
          <li>
            – <b>type</b> – тип точки (<strong>101</strong> для скважин, <strong>201</strong> для
            точек наблюдения)
          </li>
          <li>– <b>lat, lon</b> – проектные широта и долгота (WGS-84), десятичн. град.</li>
          <li>– <b>comments</b> – комментарий</li>
          <li>– <b>office_tasks</b> – задание (офис)</li>
          <li>– <b>office_notes</b> – примечание (офис)</li>
        </ul>
      </s-text>
    </div>
  </s-alert>
</template>

<style lang="scss" scoped>
.import-tip {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;

  &__list {
    margin-top: 0.25rem;

    b {
      color: var(--info);
    }
  }
}
</style>
