export const rockFields = {
  basic: [
    {
      id: 'cement',
      title: 'cement',
      label: 'Укажите состав цемента грунта',
      type: 'select',
      service: 'cements',
      commentField: 'cement_comments'
    },
    {
      id: 'structure_texture',
      title: 'Укажите информацию о структуре',
      type: 'group',
      groups: [
        {
          id: 'struct_thaw_grains',
          title: 'struct_thaw_grains',
          label: 'Зерна / содержание частиц',
          type: 'select',
          service: 'structs_thaw_grains'
        },
        {
          id: 'text_thaw',
          title: 'text_thaw',
          label: 'Укажите текстуру грунта',
          type: 'select',
          service: 'texts_thaw'
        }
      ],
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к структуре / текстуре'
    },
    {
      id: 'alteration_rock1',
      title: 'Укажите информацию о степени измененности и прочности',
      type: 'group',
      groups: [
        {
          id: 'alteration_weath',
          title: 'alteration_weath',
          label: 'Степень выветрелости',
          type: 'select',
          service: 'alterations_weath'
        },
        {
          id: 'strength',
          title: 'strength',
          label: 'Прочность',
          type: 'select',
          service: 'strengths'
        },
        {
          id: 'density',
          title: 'density',
          label: 'Плотность',
          type: 'select',
          service: 'densities'
        }
      ],
      commentField: 'strength_comments',
      commentTitle: 'Комментарий к измененности и прочности'
    },
    {
      id: 'alteration_rock2',
      title: 'Укажите информацию о трещиноватости',
      type: 'group',
      groups: [
        {
          id: 'alteration_fractures_integrity',
          title: 'alteration_fractures_integrity',
          label: 'Степень сплошности',
          type: 'select',
          service: 'alterations_fractures_integrity',
          wikiTipData: {
            color: 'placeholder',
            title: 'Степень сплошности',
            description:
              'Для подразделения массива скального грунта по степени сплошности следует руководствоваться отношением l/а, где l — средняя длина трещин, а — среднее расстояние между трещинами. Показателем КТП (коэффициент трещинной пустотности) следует пользоваться, если площадь естественного или искусственного обнажения (котлован, штольня и т. п.) не позволяет оценить реальные значения l и а.'
          }
        },
        {
          id: 'alteration_fractures_width',
          title: 'alteration_fractures_width',
          label: 'Ширина трещин',
          type: 'select',
          service: 'alterations_fractures_width'
        },
        {
          id: 'alteration_fractures_angles',
          title: 'alteration_fractures_angles',
          label: 'Ориентировка трещин',
          type: 'select',
          service: 'alterations_fractures_angles'
        },
        {
          id: 'alteration_fractures_compos',
          title: 'alteration_fractures_compos',
          label: 'Заполнитель трещин',
          type: 'select',
          service: 'alterations_fractures_compos'
        }
      ],
      commentField: 'alteration_fractures_comments',
      commentTitle: 'Комментарий к трещиноватости'
    },
    {
      id: 'composition_chem',
      title: 'composition_chem',
      label: 'Укажите карбонатность',
      type: 'select',
      service: 'compositions_chem'
    }
  ],
  additional: true,
  interlayers: true
  // additional: [
  //   {
  //     id: 'interlayers',
  //     value: 'interlayers',
  //     title: 'Прослои',
  //     type: 'interlayers'
  //   },
  //   {
  //     id: 'additional',
  //     title: 'Укажите дополнительную информацию',
  //     type: 'additional'
  //   },
  //   {
  //     id: 'sample_info',
  //     value: 'sample_info',
  //     title: 'Добавьте информацию о керне',
  //     type: 'sample-info',
  //     createTrigger: true
  //   }
  // ]
}
