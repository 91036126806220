export const dbConfig = {
  objects: {
    field: 'objectsList',
    store: 'objects'
  },
  excavations: {
    field: 'excavationsList',
    store: 'objects'
  },
  recons: {
    field: 'reconsList',
    store: 'objects'
  },
  soils: {
    field: 'soilsList',
    store: 'excavations'
  },
  inclusions: {
    field: 'inclusionsList',
    store: 'excavations'
  },
  interlayers: {
    field: 'interlayersList',
    store: 'excavations'
  },
  samples: {
    field: 'samplesList',
    store: 'excavations'
  },
  groundwater: {
    field: 'groundwaterList',
    store: 'excavations'
  },
  coreboxes: {
    field: 'coreboxesList',
    store: 'excavations'
  }
}
