<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: Number,
    validator: (value) => [101, 201].includes(value),
    default: 201
  },
  size: {
    type: String,
    validator: (value) => ['default', 'small'].includes(value),
    default: 'default'
  },
  transparent: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    validator: (value) => ['primary', 'text', 'caption'].includes(value),
    default: 'primary'
  }
})

const colorValue = computed(() => `var(--${props.color})`)
const styleValue = computed(() => {
  const sizeValue = props.size === 'default' ? 32 : 24

  return {
    width: `${sizeValue}px`,
    height: `${sizeValue}px`,
    opacity: props.transparent ? 0.6 : 1
  }
})
</script>

<template>
  <div class="data-item-icon" :style="styleValue">
    <template v-if="type === 201">
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :fill="colorValue" d="M5.00586 2.80902L25.3878 13L5.00586 23.191L5.00586 2.80902Z" />
        <rect :fill="colorValue" x="0.505859" width="4" height="32" />
      </svg>
    </template>
    <template v-else>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle class="outer-circle" cx="16" cy="16" r="15" stroke-width="2" :stroke="colorValue" />
        <circle class="inner-circle" cx="16.0001" cy="16.0001" r="6.4" :fill="colorValue" />
      </svg>
    </template>
  </div>
</template>

<style lang="scss"></style>
