export const claySteps = {
  main: [
    {
      id: 'color',
      value: 'color',
      title: 'Укажите цвет грунта',
      type: 'color-select',
      wikiTipData: {
        color: 'placeholder',
        title: 'Цвет грунта',
        description:
          'Цвет грунта описывают в естественном состоянии, при полном дневном освещении. Допускается описание цвета при искусственном освещении с использованием ламп дневного света или при любом освещении с применением сравнительного образца или эталона, цветовых шкал. Для объективного описания цветов рекомендуется пользоваться цветовыми шкалами (палетками), например выполненными по системе Манселла, или другими системами, специализированными для определения цвета грунтов (например, цветовая таблица грунтов S133N). Цвет скального грунта описывают во влажном (смоченном) состоянии. Если необходимо, цве­товые различия могут быть уточнены использованием таких терминов, как «пестрый», «пятнистый», «испещренный», «крапчатый», «полосчатый».',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'water_saturation',
      value: 'water_saturation',
      title: 'Укажите консистенцию грунта',
      type: 'soil-water',
      service: 'water_saturations_clay',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к консистенции'
    },
    {
      id: 'structure_texture',
      title: 'Укажите информацию о структуре',
      type: 'group-select',
      groups: [
        {
          id: 'struct_thaw_weight',
          value: 'struct_thaw_weight',
          title: 'Зерна / содержание частиц',
          type: 'select',
          service: 'structs_thaw_weight'
        },
        {
          id: 'struct_thaw_grains',
          value: 'struct_thaw_grains',
          titleValue: 'Зерна / содержание частиц',
          titleDep: 'structs_thaw_weight',
          type: 'select',
          service: 'structs_thaw_grains'
        },
        {
          id: 'struct_thaw_joint',
          value: 'struct_thaw_joint',
          title: 'Отдельность',
          type: 'select',
          service: 'structs_thaw_joint'
        },
        {
          id: 'struct_thaw_porosity',
          value: 'struct_thaw_porosity',
          title: 'Пористость',
          type: 'select',
          service: 'structs_thaw_porosity'
        },
        {
          id: 'text_thaw',
          value: 'text_thaw',
          title: 'Текстура',
          type: 'select',
          service: 'texts_thaw'
        }
      ],
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к структуре / текстуре',
      additionalTrigger: true,
      createTrigger: true
    }
  ],
  additional: [
    {
      id: 'inclusions',
      value: 'inclusions',
      title: 'Добавьте  информацию о включениях',
      type: 'inclusions'
    },
    {
      id: 'interlayers',
      value: 'interlayers',
      title: 'Добавьте  информацию о прослоях',
      type: 'interlayers'
    },
    {
      id: 'additional',
      title: 'Укажите дополнительную информацию',
      type: 'additional',
      createTrigger: true
    }
  ]
}
