export * from './clay'
export * from './frozen'
export * from './frozen-large-scale'
export * from './frozen-rock'
export * from './frozen-sand'
export * from './ice'
export * from './large-scale'
export * from './prs'
export * from './rock'
export * from './sand'
export * from './techno'
export * from './inclusion'
export * from './interlayer'

export const baseSteps = [
  {
    id: 'depth',
    value: 'depth',
    title: 'Укажите информацию о глубине',
    subtitle: 'Глубина кровли посчитается автоматически',
    type: 'depth'
  },
  {
    id: 'soil_type',
    value: 'soil_type',
    title: 'Укажите вид грунта',
    type: 'soil-type',
    service: 'soil_types',
    wikiTipData: {
      color: 'placeholder',
      title: 'Вид грунта',
      description:
        'Описание грунтов выполняют на очищенных поверхностях, сколах, срезах керна, образца и грунтах естественного залегания',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.4. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  }
]
