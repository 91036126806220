export const updates = [
  {
    title: 'Soilbox 1.3.0',
    list: [
      'Добавлен модуль планирования работ (расстановка скважин на карте c подсказками-измерителями) <span class="su-beta">BETA</span>',
      'Добавлен экспорт суточно-месячного графика по проекту <span class="su-beta">BETA</span>',
      'Исправлено отображение всплывающего окна с фильтрами скважин / точек наблюдений',
      'Исправлено сохранение атрибута <span class="su-code">Комментарий к составу обломков</span> при создании и редактировании слоев',
      'Исправлено отображение <span class="su-code">Текстуры</span> и <span class="su-code">Криогенной текстуры</span> у мерзлых грунтов при создании и редактировании слоев',
      'Добавлено подсвечивание незаполненных атрибутов для закрытых скважин в модуле Таблицы',
      'Добавлена настройка отображения столбцов в разделе Ведомость проб в модуле Таблицы',
      'Добавлен атрибут <span class="su-code">Глубина обсадки</span> в скважину',
      'Исправлено некорректное отображение последних строк в модуле Таблицы при горизонтальном пролистывании',
      'В модуль Таблицы добавлены Условные обозначения',
      'Исправлены мелкие неточности и внесены улучшения по всей системе'
    ],
    version: '1.3.0',
    date: '04.12.2024',
    lastUpdate: true
  },
  {
    title: 'Soilbox 1.2.0',
    list: [
      'Улучшен и оптимизирован модуль экспорта данных',
      'Добавлен статус процесса синхронизации данных (отображается в карточке скважины, если синхронизация не завершена)',
      'Добавлены новые атрибуты в импорт данных: <span class="su-code">Задание-офис</span>, <span class="su-code">Примечание-офис</span>',
      'Добавлена полноценная работа с удаленными из системы пользователями',
      'Добавлены атрибуты грунтов в ведомость проб в онлайн-таблицах: <span class="su-code">Полное описание</span>, <span class="su-code">Сокращенное описание</span>, <span class="su-code">Состояние</span>, <span class="su-code">Состояние мерзлого грунта</span>, <span class="su-code">Льдистость</span>, <span class="su-code">Плотность</span>, <span class="su-code">Содержание ОВ</span>, <span class="su-code">Карбонатность</span>, <span class="su-code">Ожелезненность</span>, <span class="su-code">Загрязненность</span>',
      'Добавлены новые атрибуты в ведомость проб Excel: <span class="su-code">Дата начала бурения</span>, <span class="su-code">Дата окончания бурения</span>',
      'Добавлен раздел "Последние обновления системы"',
      'Добавлен отдельный атрибут <span class="su-code">Cтепень водонасыщения</span> для крупнообломочных грунтов',
      'Исправлен баг при создании скважины пользователем с ролью Геолог',
      'Исправлены мелкие неточности и внесены улучшения по всей системе'
    ],
    version: '1.2.0',
    date: '08.11.2024'
  }
]
