<script setup>
import { computed, onMounted, ref } from 'vue'
import { useMq } from 'vue3-mq'
import cloneDeep from 'lodash.clonedeep'
import { useServicesStore } from '@/stores'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    default: () => {}
  }
})
const emits = defineEmits(['trigger-changed'])

const mq = useMq()
const servicesStore = useServicesStore()

const initSource = ref(props.source)

const colors_main = computed(() => {
  const colors = cloneDeep(servicesStore?.soil?.colors_main || [])

  return (
    colors
      ?.filter((c) => {
        if (c.soil_id) {
          return c.soil_id?.includes(props.source.soil_type)
        } else {
          return true
        }
      })
      ?.map((e) => {
        e.title = e.title + e.end1
        return e
      }) || []
  )
})

const colors_optional = computed(() => {
  const colors = servicesStore?.soil?.colors_optional || []

  return colors?.filter((c) => {
    if (c.soil_id) {
      return c.soil_id?.includes(props.source.soil_type)
    } else {
      return true
    }
  })
})

const hasChanges = computed(() => {
  return !!initSource.value.color_main
})

const hndlSelect = (val) => {
  emits('trigger-changed', hasChanges.value)
  initSource.value.color_main = val

  if (!val) {
    initSource.value.color_optional = null
  }
}

const extraHndlSelect = (val) => {
  emits('trigger-changed', hasChanges.value)
  initSource.value.color_optional = val
}

onMounted(() => {
  if (initSource.value.color_main) {
    emits('trigger-changed', hasChanges.value)
  }
})
</script>

<template>
  <div class="create-guide-color-select">
    <div class="create-guide-color-select-selection">
      <s-select
        v-if="initSource.color_main"
        :value="initSource.color_optional"
        placeholder="Дополнение"
        clearable
        :filterable="mq.current === 'lg'"
        :searchable="mq.current === 'lg'"
        :options="colors_optional"
        @change="extraHndlSelect"
      >
      </s-select>
      <s-select
        :value="initSource.color_main"
        clearable
        :filterable="mq.current === 'lg'"
        :searchable="mq.current === 'lg'"
        placeholder="Выберите основной цвет"
        :options="colors_main"
        @change="hndlSelect"
      >
      </s-select>
    </div>
    <s-input
      v-if="initSource.color_main"
      v-model="initSource.color_comments"
      label="Комментарий к цвету"
      type="textarea"
    />
  </div>
</template>

<style lang="scss">
.create-guide-color-select {
  display: grid;
  grid-gap: 2rem;

  &-selection {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
  }
}
</style>
