<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useVirtualList } from '@vueuse/core'

import { listTips } from '../config'
import ListTipComp from '@/components/list-tip.vue'
import DataItem from '@/components/s-data-item/s-data-item.vue'

const props = defineProps({
  excavations: {
    type: Array,
    default: () => []
  },
  hasObjName: {
    type: Boolean
  },
  filtered: {
    type: Boolean,
    default: false
  }
})

watch(
  () => props.excavations,
  (newValue) => {
    if (newValue) {
      listItems.value = newValue
    }
  }
)

const route = useRoute()

const listItems = ref(props.excavations)

const listTip = computed(() => {
  if (!props.filtered) {
    switch (route.query.type) {
      case 'recon':
        return listTips.recons
      case 'excavations':
        return listTips.default
      case 'all':
        return listTips.all
      default:
        return listTips.all
    }
  } else {
    return listTips.filtered
  }
})

const { list, containerProps, wrapperProps } = useVirtualList(listItems, {
  itemHeight: 70
})
</script>

<template>
  <template v-if="excavations.length">
    <div class="excavations-virtual-list" v-bind="containerProps" style="height: 750px">
      <ul v-bind="wrapperProps">
        <data-item
          v-for="{ data } in list"
          :source="data"
          :key="data.uuid"
          :has-obj-name="hasObjName"
        />
      </ul>
    </div>
  </template>
  <list-tip-comp v-else :tip="listTip" />
</template>

<style lang="scss">
.excavations-virtual-list {
  display: grid;
  max-height: 100%;
  overflow: auto;
  padding-right: 0.5rem;

  > * {
    > * {
      position: relative;
      margin-top: 1rem;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: var(--bg);
        left: 0;
        bottom: -0.5rem;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

.light-theme {
  .excavations-virtual-list {
    > * {
      > * {
        &::after {
          background-color: var(--main-bg);
        }
      }
    }
  }
}
</style>
