export const startSteps = {
  1: {
    id: 1,
    comp: 'location',
    title: 'Укажите фактическое местоположение'
  },
  2: {
    id: 2,
    comp: 'inputs',
    title: 'Укажите базовую информацию'
  },
  3: {
    id: 3,
    comp: 'inputs',
    title: 'Укажите инженерно-геологические процессы',
    wikiTipData: {
      color: 'placeholder',
      title: 'Укажите иг процессы',
      description:
        'Проводится описание и фотофиксация внешних проявлений опасных геологических и инженерно-геологических процессов',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  4: {
    id: 4,
    comp: 'images',
    title: 'Укажите дополнительную информацию'
  },
  5: {
    id: 5,
    comp: 'images',
    title: 'Добавьте фотографии'
  }
}

export const basicFields = [
  {
    id: 'date',
    title: 'date_end',
    format: 'dd.MM.yyyy',
    label: 'Дата',
    type: 'date',
    setCurrentDate: true
  },
  {
    id: 1,
    title: 'site',
    label: 'Участок работ',
    type: 'string'
  },
  {
    id: 2,
    title: 'location',
    label: 'Местоположение',
    type: 'string'
  },
  {
    id: 3,
    title: 'geomorph_id',
    label: 'Геоморфология',
    type: 'select',
    service: 'geomorphies',
    wikiTipData: {
      color: 'placeholder',
      title: 'Геоморфология',
      description: 'Проводится визуальная оценка рельефа',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 4,
    title: 'geomorph_comments',
    label: 'Комментарий к геоморфологии',
    type: 'string'
  }
]

export const secondaryFields = [
  {
    id: 13,
    title: 'exposures',
    label: 'Обнажения',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Обнажения',
      description:
        'Проводится описание и фотофиксация имеющихся обнажений, в том числе карьеров, строительных выработок и др.',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 14,
    title: 'waterfronts',
    label: 'Водопроявления',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Водопроявления',
      description: 'Проводится описание и фотофиксация водопроявлений, водных объектов',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 15,
    title: 'geobotany',
    label: 'Геоботанические индикаторы',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Геоботанические индикаторы',
      description:
        'Проводится описание и фотофиксация геоботанических индикаторов гидрогеологических условий',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 16,
    title: 'polls',
    label: 'Опрос местного населения',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Опрос населения',
      description:
        'Проводится опрос местного населения (с записью на диктофон или в полевой журнал) о проявлении опасных геологических и инженерно-геологических процессов, об имевших место чрезвычайных ситуациях, связанных с природными явлениями (приих наличии)',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 17,
    title: 'inspections',
    label: 'Обследование объектов',
    type: 'textarea',
    wikiTipData: {
      color: 'placeholder',
      title: 'Обследование объектов',
      description:
        'Проводится обследование объектов, подвергшихся разрушению в результате воздействия природных (землетрясений, лавин, оползней и т. д.) и техногенных факторов; фиксация деформаций зданий, сооружений, опор линий электропередачи и связи, транспортных магистралей',
      source:
        'п. 5.5.1, СП 446.1325800. Инженерно-геологические изыскания для строительства. Общие правила производства работ. 2019'
    }
  },
  {
    id: 18,
    title: 'comments',
    label: 'Комментарий к точке',
    type: 'textarea'
  }
]
