<script setup>
import { computed } from 'vue'
import { useMapStore } from '@/stores'
import { roundValue } from '@/utils'

const props = defineProps({
  type: {
    type: String,
    default: 'excavations'
  },
  stats: {
    type: Object,
    required: true
  },
  statuses: {
    type: Array,
    required: true
  }
})

const mapStore = useMapStore()

const inverseDefaultColor = computed(() => {
  return mapStore.activeBaselayers['data-map'] !== 'map'
})

const getClass = (flag) => {
  return `widget-list__item ${flag} ${
    inverseDefaultColor.value && flag === 'default' ? 'inverse' : ''
  }`
}

const getCount = (id) => {
  if (!props.stats || !props.stats[id]) return 0

  return roundValue(props.stats[id].count, 0)
}

const getMeters = (id) => {
  if (!props.stats || !props.stats[id]) return 0

  const fieldName = id === 'не начата' || id === 'вынесена' ? 'h_plan' : 'h'

  return roundValue(props.stats[id][fieldName], 0)
}
</script>

<template>
  <ul class="widget-list">
    <li v-for="item in statuses" :key="item.id" :class="getClass(item.flag)">
      <div class="widget-list__item-label">
        <s-text semibold>
          {{ item.label }}
        </s-text>
      </div>
      <div class="widget-list__item-stats">
        <s-title type="small">
          {{ getCount(item.id) }}
        </s-title>
        <s-text v-if="type === 'excavations'">({{ getMeters(item.id) }} п.м.)</s-text>
      </div>
    </li>
  </ul>
</template>

<style lang="scss">
.widget-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: var(--bg);
    border: 1px solid var(--main-bg);
    border-radius: 8px;

    &-stats {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .s-text {
        margin-left: 8px;
      }
    }

    &.default {
      .s-text,
      .s-title {
        color: var(--info) !important;
      }

      &.inverse {
        .s-text,
        .s-title {
          color: var(--caption) !important;
        }
      }
    }

    &.orange {
      .s-text,
      .s-title {
        color: var(--orange) !important;
      }
    }

    &.blue {
      .s-text,
      .s-title {
        color: var(--primary) !important;
      }
    }

    &.green {
      .s-text,
      .s-title {
        color: var(--success) !important;
      }
    }
  }
}
</style>
