export const defaultView = {
  center: [37.6165, 55.752],
  zoom: 13
}

export const mapboxToken =
  'pk.eyJ1IjoibmF1dGlsdXNhcHAiLCJhIjoiY2t3dXdlcW8wMXVsYjJ3cW9jZjdlNGY5NyJ9.wDKNvl1RcvgbWhWuBLZkrg'

export const mapStyles = {
  'dark-theme': 'mapbox://styles/nautilusapp/ckwuwjaq8498g15nv2ywu7mck',
  'light-theme': 'mapbox://styles/nautilusapp/cky039zqw05e315mtpo9v5mey',
  satelite: 'mapbox://styles/nautilusapp/ckz824zed000g15qtp3g7naln',
  outdoors: 'mapbox://styles/nautilusapp/cl48gdx9z000u14mougvk5j9a'
}

export const baselayers = [
  { id: 'map', name: 'Схема' },
  { id: 'outdoors', name: 'Рельеф' },
  { id: 'satelite', name: 'Спутник' }
]
