<script setup>
import { ref } from 'vue'
import { useMq } from 'vue3-mq'

import InfoEditor from './info-editor.vue'
import PasswordEditor from './password-editor.vue'

defineProps({
  editing: {
    type: Boolean,
    required: true
  }
})

const mq = useMq()

const loading = ref(false)
const toggleLoader = (value) => {
  loading.value = value
}
</script>

<template>
  <s-modal
    title="Редактирование"
    :show="editing"
    :max-width="480"
    :fullscreen="mq.current === 'sm'"
    @close="$emit('close')"
  >
    <form v-loading="loading" class="profile-editor">
      <info-editor @toggle-loader="toggleLoader" />
      <password-editor @toggle-loader="toggleLoader" />
    </form>
  </s-modal>
</template>

<style lang="scss">
.profile-editor {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &__block {
    &-title {
      margin-bottom: 0.5rem;
    }

    &-button {
      margin-top: 1.5rem;

      .s-button {
        width: 160px;
      }
    }
  }

  &__row {
    display: flex;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__input {
    flex: 1;

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>
