import { useMainStore, useAuthStore } from '@/stores'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import appGlobalProperties from '@/main'
import { getAllChanges } from '@/utils'
export * from './recon-geo-types.js'

export const editRecon = async (recon, loading, callback, filter) => {
  const { putRequest } = useRequests()
  const authStore = useAuthStore()
  const mainStore = useMainStore()

  loading = true

  try {
    recon.date_front = new Date()

    if (!recon.geologist) {
      recon.geologist = authStore.userId
    }

    const toServer = Object.assign({}, recon)
    const toIdb = Object.assign({}, recon)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      toServer.id = toServer.server_id
      delete toServer.object_id
      toServer.type = null
      await putRequest(`reconnaissance/${toServer.id}/`, toServer)
    }

    await db.updateObject('recons', toIdb, filter)

    if ((!mainStore.isOnline || mainStore.noSyncMode) && recon.server_id) {
      const updatedItems = await db.updated.where('table').equals('recons').toArray()
      const isExist = !!updatedItems.find((e) => e.item_id === recon.server_id)

      if (!isExist) {
        await db.updated.put({
          table: 'recons',
          item_id: recon.server_id,
          date: new Date()
        })
        getAllChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Точка наблюдения "${toIdb.title}" успешно отредактирована`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback(true)
    }
  } catch (error) {
    const title = 'Редактирование'
    const message = `Ошибка при редактирование точки наблюдения ${error}`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading = false
  }
}
