import contextMenu from '../context-menu/context-menu'

export const excavationStaticColumns = [
  {
    id: 'general',
    label: 'Общее',
    attributes: [
      {
        type: 'text',
        data: 'title',
        title: 'Номер',
        key: 'title',
        readOnly: true,
        disabled: true
      },
      {
        type: 'numeric',
        data: 'h_plan',
        title: 'Глубина (план.), м',
        units: 'м',
        key: 'h_plan',
        readOnly: true,
        disabled: true
      },
      {
        type: 'numeric',
        title: 'Глубина (факт.), м',
        key: 'h',
        data: 'h',
        units: 'м',
        readOnly: true,
        disabled: true
      },
      {
        strict: true,
        type: 'autocomplete',
        filter: false,
        data: 'status',
        title: 'Статус',
        source: 'status',
        key: 'status'
      },
      {
        type: 'text',
        data: 'site',
        title: 'Участок',
        key: 'site'
      },
      {
        strict: true,
        type: 'autocomplete',
        filter: false,
        data: 'sync_status',
        title: 'Статус синхронизации',
        source: 'sync_tatus',
        key: 'sync_status'
      },
      {
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        datePickerConfig: {
          i18n: {
            previousMonth: 'Прошлый месяц',
            nextMonth: 'След месяц',
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь'
            ],
            weekdays: [
              'Воскресенье',
              'Понедельник',
              'Вторник',
              'Среда',
              'Четверг',
              'Пятница',
              'Суббота'
            ],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
          }
        },
        correctFormat: true,
        title: 'Дата начала',
        key: 'date_from',
        data: 'date_from'
      },
      {
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        correctFormat: true,
        datePickerConfig: {
          i18n: {
            previousMonth: 'Прошлый месяц',
            nextMonth: 'След месяц',
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь'
            ],
            weekdays: [
              'Воскресенье',
              'Понедельник',
              'Вторник',
              'Среда',
              'Четверг',
              'Пятница',
              'Суббота'
            ],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
          }
        },
        title: 'Дата окончания',
        key: 'date_to',
        data: 'date_to'
      }
    ]
  },
  {
    id: 'location',
    label: 'Местоположение',
    attributes: [
      {
        type: 'text',
        title: 'Широта (план), град.',
        key: 'lat_plan',
        units: 'град.',
        data: 'lat_plan',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Широта (план), ГМС',
        editor: 'degreesEditor',
        key: 'lat_plan_dms',
        units: 'ГМС',
        data: 'lat_plan_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'Долгота (план), град.',
        key: 'lon_plan',
        units: 'град.',
        data: 'lon_plan',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Долгота (план), ГМС',
        editor: 'degreesEditor',
        units: 'ГМС',
        key: 'lon_plan_dms',
        data: 'lon_plan_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'Широта (факт), град.',
        key: 'lat',
        units: 'град.',
        data: 'lat',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Широта (факт), ГМС',
        editor: 'degreesEditor',
        units: 'ГМС',
        key: 'lat_dms',
        data: 'lat_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'Долгота (факт), град.',
        key: 'lon',
        units: 'град.',
        data: 'lon',
        coordType: 'decimals'
      },
      {
        type: 'text',
        title: 'Долгота (факт), ГМС',
        editor: 'degreesEditor',
        units: 'ГМС',
        key: 'lon_dms',
        data: 'lon_dms',
        notShow: true,
        coordType: 'dms'
      },
      {
        type: 'text',
        title: 'X MSK (план.), м',
        key: 'msk_x_plan',
        units: 'м',
        data: 'msk_x_plan'
      },
      {
        type: 'text',
        title: 'Y MSK (план.), м',
        key: 'msk_y_plan',
        units: 'м',
        data: 'msk_y_plan'
      },
      { type: 'text', title: 'X MSK (факт.), м', key: 'msk_x', units: 'м', data: 'msk_x' },
      { type: 'text', title: 'Y MSK (факт.), м', key: 'msk_y', units: 'м', data: 'msk_y' },
      // Абс отметка
      { type: 'text', title: 'Абс. отм., м', key: 'abs', units: 'м', data: 'abs' },
      { type: 'text', title: 'Абс. отм. (MSK), м', key: 'msk_abs', units: 'м', data: 'msk_abs' },
      {
        type: 'numeric',
        title: 'Смещение, м',
        key: 'coords_offset',
        units: 'м',
        data: 'coords_offset',
        readOnly: true
      }
    ]
  },
  {
    id: 'employees',
    label: 'Сотрудники и оборудование',
    attributes: [
      {
        type: 'autocomplete',
        filter: false,
        title: 'Геолог',
        source: '',
        key: 'geologist',
        data: 'geologist'
      },
      {
        type: 'autocomplete',
        filter: false,
        title: 'Бур. мастер',
        source: '',
        key: 'bore_master',
        data: 'bore_master'
      },
      {
        type: 'autocomplete',
        filter: false,
        title: 'Бур. установка',
        source: '',
        key: 'bore_machine',
        data: 'bore_machine'
      },
      {
        type: 'numeric',
        title: 'Нач. диаметр',
        units: 'мм',
        key: 'diam1',
        data: 'diam1'
      },
      {
        type: 'numeric',
        title: 'Кон. диаметр',
        units: 'мм',
        data: 'diam2',
        key: 'diam2'
      }
    ]
  },
  {
    id: 'natural',
    label: 'Природные условия',
    attributes: [
      {
        strict: true,
        type: 'autocomplete',
        filter: false,
        title: 'Геоморфология',
        data: 'geomorph_id',
        key: 'geomorph_id',
        source: ''
      },
      {
        type: 'text',
        title: 'Комментарий к геом-ии',
        data: 'geomorph_comments',
        key: 'geomorph_comments'
      },
      {
        type: 'text',
        title: 'Природные условия',
        data: 'natural_f',
        key: 'natural_f'
      },
      {
        type: 'text',
        title: 'Техногенные условия',
        data: 'artificial_f',
        key: 'artificial_f'
      }
    ]
  },
  {
    id: 'stats',
    label: 'Статистика',
    attributes: [
      {
        type: 'text',
        title: 'Фото проб, кол-во',
        key: 'total_sample_images',
        units: 'кол-во',
        data: 'total_sample_images',
        readOnly: true
      },
      {
        type: 'text',
        title: 'Фото общего вида, кол-во',
        key: 'total_images',
        units: 'кол-во',
        data: 'total_images',
        readOnly: true
      },
      {
        type: 'text',
        title: 'Фото керна, кол-во',
        key: 'total_corebox_images',
        units: 'кол-во',
        data: 'total_corebox_images',
        readOnly: true
      },
      {
        type: 'text',
        title: 'Пробы нар. стр-ры.',
        key: 'total_broken_structure',
        units: 'кол-во',
        data: 'total_broken_structure',
        readOnly: true
      },
      {
        type: 'text',
        title: 'Монолиты',
        key: 'total_monolyth',
        units: 'кол-во',
        data: 'total_monolyth',
        readOnly: true
      },
      {
        type: 'text',
        title: 'Пробы воды',
        key: 'total_water',
        units: 'кол-во',
        data: 'total_water',
        readOnly: true
      }
    ]
  },
  {
    id: 'other',
    label: 'Другое',
    attributes: [
      {
        type: 'text',
        title: 'Комментарий',
        data: 'comments',
        key: 'comments',
        width: 350
      },
      {
        type: 'text',
        title: 'Задание (офис)',
        data: 'office_tasks',
        key: 'office_tasks'
      },
      {
        type: 'text',
        title: 'Примечание (офис)',
        data: 'office_notes',
        key: 'office_notes'
      }
    ]
  }
]

export const excavInitialColumns = [
  'title',
  'h_plan',
  'h',
  'status',
  'site',
  'lat_plan',
  'lon_plan',
  'lat',
  'lon',
  'geologist',
  'comments'
]

export const soilsListTips = {
  title: 'Список слоев пуст',
  description: 'В данном проекте отсутствуют добавленные слои'
}

export const sheetsSettings = {
  filters: true,
  columnSorting: true,
  fixedColumnsStart: 1,
  selectionMode: 'multiple',
  rowHeaders: true,
  height: 'calc(100vh - 360px)',
  outsideClickDeselects: false,
  autoRowSize: true,
  contextMenu: contextMenu,
  copyPaste: true,
  search: {
    searchResultClass: 'searched-values'
  },
  dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar']
}
