export const cardsConfig = [
  {
    header: [
      {
        label: 'Короткое название',
        labelMobile: 'Кор. назв-ие',
        field: 'title_short'
      }
    ],
    body: [
      {
        label: 'Название',
        field: 'title'
      },
      {
        label: 'Шифр',
        field: 'num'
      },
      {
        label: 'Статус',
        field: 'status'
      },
      {
        label: 'Архивный',
        field: 'archived'
      },
      {
        label: 'Начало',
        field: 'date_start'
      },
      {
        label: 'Окончание',
        field: 'date_end'
      },
      {
        label: 'Заказчик',
        field: 'client_title'
      },
      {
        label: 'Исполнитель',
        field: 'contractor_title',
        divider: true
      },
      {
        label: 'Геологи',
        field: 'geologistList'
      },
      {
        label: 'Буровые мастера',
        labelMobile: 'Бур. мастера',
        field: 'boreMasters'
      },
      {
        label: 'Буровые установки',
        labelMobile: 'Бур. уст-ки',
        field: 'boreMachines',
        divider: true
      }
    ],
    footer: [
      {
        label: 'Комментарий',
        field: 'comments'
      }
    ]
  }
]
